import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
/** Importacion de estilos */
import useStyles from './styles';
import { RootState } from '../../../store';
/** Importacion de los componentes de las tabs */
import DatosPersonales from './DatosPersonales';
import DatosDeUbicacion from './DatosDeUbicacion';
import Discapacidades from './Discapacidades';
import InfoEmergencia from './InfoEmergencia';
import SegurosPlanes from './SegurosPlanes';
import { IPerfilPacienteProps } from './types';

function PerfilPaciente(props: IPerfilPacienteProps) {
  const {
    setActiveStep,
    idPaciente,
    datosPersonales,
    setDatosPersonales,
    setEnGestacion,
    setInmunizaciones,
    tipoPaciente,
    datosUbicacion,
    setDatosUbicacion,
    discapacidades,
    setDiscapacidades,
    infoEmergencia,
    setInfoEmergencia,
    segurosPlanes,
    setSegurosPlanes,
    cambiosDatosPersonales,
    cambiosDatosUbicacion,
    cambiosDiscapacidades,
    cambiosInfoEmergencia,
    cambiosSegurosPlanes,
    datosPersonalesUsuario,
    setDatosPersonalesUsuario,
    datosUbicacionUsuario,
    setDatosUbicacionUsuario,
    discapacidadesUsuario,
    setDiscapacidadesUsuario,
    infoEmergenciaUsuario,
    setInfoEmergenciaUsuario,
    segurosPlanesUsuario,
    setSegurosPlanesUsuario,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const [activeSubStep, setActiveSubStep] = useState<
  'datos-personales' | 'datos-ubicacion' | 'discapacidades' | 'info-emergencia' | 'seguros-planes'
  >('datos-personales');

  return (
    <>
      <div className="px-2 pb-0 bg-white container mx-auto rounded-t-md border-solid border border-gray-100 shadow">
        <h1 className="text-blue-800 font-normal m-6 text-center">{t('perfil_del_paciente')}</h1>
        <div>
          <Button
            className={
              activeSubStep === 'datos-personales' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => setActiveSubStep('datos-personales')}
            startIcon={
              !esPaciente
              && idUsuario > 0
              && cambiosDatosPersonales.filter((c: string) => c.length > 0).length > 0 ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('datos_personales')}
          </Button>
          <Button
            disabled={idPaciente <= 0}
            className={
              activeSubStep === 'datos-ubicacion' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => setActiveSubStep('datos-ubicacion')}
            startIcon={
              !esPaciente
              && idUsuario > 0
              && cambiosDatosUbicacion.filter((c: string) => c.length > 0).length > 0 ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('datos_de_ubicacion')}
          </Button>
          <Button
            disabled={idPaciente <= 0}
            className={
              activeSubStep === 'discapacidades' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => setActiveSubStep('discapacidades')}
            startIcon={
              !esPaciente
              && idUsuario > 0
              && cambiosDiscapacidades.filter((c: string) => c.length > 0).length > 0 ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('discapacidades')}
          </Button>
          <Button
            disabled={idPaciente <= 0}
            className={
              activeSubStep === 'info-emergencia' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => setActiveSubStep('info-emergencia')}
            startIcon={
              !esPaciente
              && idUsuario > 0
              && cambiosInfoEmergencia.filter((c: string) => c.length > 0).length > 0 ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('info_de_emergencia')}
          </Button>
          <Button
            disabled={idPaciente <= 0}
            className={
              activeSubStep === 'seguros-planes' ? classes.buttonSelected : classes.buttonRoot
            }
            onClick={() => setActiveSubStep('seguros-planes')}
            startIcon={
              !esPaciente
              && idUsuario > 0
              && cambiosSegurosPlanes.filter((c: string) => c.length > 0).length > 0 ? (
                <Warning color="secondary" fontSize="small" />
                ) : null
            }
          >
            {t('seguros_y_planes')}
          </Button>
        </div>
      </div>
      {activeSubStep === 'datos-personales' && (
        <DatosPersonales
          setActiveSubStep={setActiveSubStep}
          datosPersonales={datosPersonales}
          setDatosPersonales={setDatosPersonales}
          cambiosUsuario={cambiosDatosPersonales}
          datosPersonalesUsuario={datosPersonalesUsuario}
          setDatosPersonalesUsuario={setDatosPersonalesUsuario}
          setEnGestacion={setEnGestacion}
          setInmunizaciones={setInmunizaciones}
          tipoPaciente={tipoPaciente}
        />
      )}
      {idPaciente > 0 && activeSubStep === 'datos-ubicacion' && (
        <DatosDeUbicacion
          setActiveSubStep={setActiveSubStep}
          datosUbicacion={datosUbicacion}
          setDatosUbicacion={setDatosUbicacion}
          cambiosUsuario={cambiosDatosUbicacion}
          datosUbicacionUsuario={datosUbicacionUsuario}
          setDatosUbicacionUsuario={setDatosUbicacionUsuario}
        />
      )}
      {idPaciente > 0 && activeSubStep === 'discapacidades' && (
        <Discapacidades
          setActiveSubStep={setActiveSubStep}
          discapacidades={discapacidades}
          setDiscapacidades={setDiscapacidades}
          cambiosUsuario={cambiosDiscapacidades}
          discapacidadesUsuario={discapacidadesUsuario}
          setDiscapacidadesUsuario={setDiscapacidadesUsuario}
        />
      )}
      {idPaciente > 0 && activeSubStep === 'info-emergencia' && (
        <InfoEmergencia
          setActiveSubStep={setActiveSubStep}
          infoEmergencia={infoEmergencia}
          setInfoEmergencia={setInfoEmergencia}
          cambiosUsuario={cambiosInfoEmergencia}
          infoEmergenciaUsuario={infoEmergenciaUsuario}
          setInfoEmergenciaUsuario={setInfoEmergenciaUsuario}
        />
      )}
      {idPaciente > 0 && activeSubStep === 'seguros-planes' && (
        <SegurosPlanes
          setActiveStep={setActiveStep}
          segurosPlanes={segurosPlanes}
          setSegurosPlanes={setSegurosPlanes}
          cambiosUsuario={cambiosSegurosPlanes}
          segurosPlanesUsuario={segurosPlanesUsuario}
          setSegurosPlanesUsuario={setSegurosPlanesUsuario}
        />
      )}
    </>
  );
}

export default PerfilPaciente;
