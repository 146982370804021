export function getUser() {
  const userStr = sessionStorage.getItem('user');
  if (userStr) {
    return userStr;
  }
  return null;
}

export function getToken() {
  return sessionStorage.getItem('token') || '';
}

export function getIdConsulta() {
  const ss = sessionStorage.getItem('idConsulta');
  if (ss) {
    return parseInt(ss, 10);
  }
  return 0;
}

export function getEmailMe() {
  return sessionStorage.getItem('emailMe') || '';
}

export function getRol() {
  return sessionStorage.getItem('rol') || '';
}

export function removeUserSession() {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('rol');
  sessionStorage.removeItem('emailMe');
  sessionStorage.removeItem('idConsulta');
  sessionStorage.removeItem('nombre');
  sessionStorage.removeItem('sexo');
  sessionStorage.removeItem('dia');
  sessionStorage.removeItem('mes');
  sessionStorage.removeItem('anio');
  sessionStorage.removeItem('id');
}

export function setUser(user: string) {
  sessionStorage.setItem('user', user);
}

export function setToken(token: string) {
  sessionStorage.setItem('token', token);
}

export function setEmailMe(email: string) {
  sessionStorage.setItem('emailMe', email);
}

export function setIdConsulta(idConsulta: number) {
  sessionStorage.setItem('idConsulta', idConsulta.toString());
}

export function removeIdConsulta() {
  sessionStorage.removeItem('idConsulta');
}

export function setRol(rol: string) {
  sessionStorage.setItem('rol', rol);
}

export function setUserSession(user: string, token: string, rol: string) {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', user);
  sessionStorage.setItem('rol', rol);
}

export function setPaciente(id: number) {
  sessionStorage.setItem('id', id.toString());
}

export function getPaciente() {
  return parseInt(sessionStorage.getItem('id') || '0', 10);
}

export function removePaciente() {
  sessionStorage.removeItem('nombre');
  sessionStorage.removeItem('sexo');
  sessionStorage.removeItem('dia');
  sessionStorage.removeItem('mes');
  sessionStorage.removeItem('anio');
  sessionStorage.removeItem('id');
}

const APIDIR = process.env.REACT_APP_APIDIR;

export const LoginURL = () => `${APIDIR}/api/login`;
export const SecureLoginURL = () => `${APIDIR}/api/llaves`;
export const SecureSignUpURL = () => `${APIDIR}/api/llaves/signup`;
export const SecureUpdatePassURL = () => `${APIDIR}/api/llaves/update`;
export const SecureLogoutURL = () => `${APIDIR}/api/llaves/logout`;
export const NacionalidadURL = () => `${APIDIR}/api/catalogos/nacionalidad`;
export const GrupoEtnicoURL = () => `${APIDIR}/api/catalogos/grupo_etnico`;
export const TipoIdentificacionURL = () => `${APIDIR}/api/catalogos/tipo_identificacion`;
export const EscolaridadURL = () => `${APIDIR}/api/catalogos/escolaridad`;
export const TipoOcupacionURL = () => `${APIDIR}/api/catalogos/tipo_ocupacion`;
export const TiempoOcupacionURL = () => `${APIDIR}/api/catalogos/tiempo_ocupacion`;
export const ReligionURL = () => `${APIDIR}/api/catalogos/religion`;
export const EstadoCivilURL = () => `${APIDIR}/api/catalogos/estado_civil`;
export const getCatAgee = (idMedico: number) => `${APIDIR}/api/catalogos/agee/${idMedico}`;
export const getCatAgem = (agee: string) => `${APIDIR}/api/catalogos/agem/${agee}`;
export const getCatAgel = (agee: string, agem: string) => `${APIDIR}/api/catalogos/agel/${agee}${agem}`;
export const getCatAgea = (agee: string, agem: string, agel: string) => `${APIDIR}/api/catalogos/agea/${agee}${agem}${agel}`;
export const Parentesco = () => `${APIDIR}/api/catalogos/parentesco`;
export const AseguradorasURL = () => `${APIDIR}/api/catalogos/aseguradoras`;
export const DiscapacidadTipoURL = () => `${APIDIR}/api/catalogos/discapacidad-tipo`;
export const DiscapacidadOrigenURL = () => `${APIDIR}/api/catalogos/discapacidad-origen`;
export const DiscapacidadGradoURL = () => `${APIDIR}/api/catalogos/discapacidad-grado`;
export const UnidadesURL = () => `${APIDIR}/api/catalogos/unidades`;
export const EscuelasMedicinaURL = (idMedico: number) => `${APIDIR}/api/catalogos/escuela-medicina/${idMedico}`;
export const EspecialidadMedicinaURL = () => `${APIDIR}/api/catalogos/especialidad-medicina`;
export const AreaMedicinaURL = () => `${APIDIR}/api/catalogos/area-competencia`;
export const IdiomasMedicinaURL = () => `${APIDIR}/api/catalogos/idiomas`;
export const LadasURL = () => `${APIDIR}/api/catalogos/ladas`;
export const MCCategoriasURL = () => `${APIDIR}/api/catalogos/categorias-mc`;
export const MCRangoEdadURL = () => `${APIDIR}/api/catalogos/rango-edad-mc`;
export const AnimalesURL = () => `${APIDIR}/api/catalogos/animales`;
export const EjerciciosURL = () => `${APIDIR}/api/catalogos/ejercicios`;
export const SitioMedicionURL = () => `${APIDIR}/api/catalogos/sitio-medicion`;
export const HospDuracionURL = () => `${APIDIR}/api/catalogos/hospitalizacion-duracion`;
export const VacunaNombreURL = () => `${APIDIR}/api/catalogos/vacuna/nombre`;
export const VacunaDosisURL = () => `${APIDIR}/api/catalogos/vacuna/dosis`;
export const LaboratoriosURL = () => `${APIDIR}/api/catalogos/laboratorios`;
export const LaboratoriosResultadoURL = () => `${APIDIR}/api/catalogos/laboratorios/resultado`;
export const LaboratoriosUnidadesURL = () => `${APIDIR}/api/catalogos/laboratorios/unidades`;
export const SecuelasURL = () => `${APIDIR}/api/catalogos/secuelas`;
export const Cie10SexoURL = (sexo: string) => `${APIDIR}/api/catalogos/cie-10/${sexo}`;
export const Cie10SexoPalabraURL = (sexo: string, palabra: string) => `${APIDIR}/api/catalogos/cie-10/sp/${sexo}/${palabra}`;
export const Cie10RangoURL = (sexo: string, selector: string, numero: number) => `${APIDIR}/api/catalogos/cie-10/rango/${sexo}/${selector}/${numero}`;
export const Cie10MaxURL = (sexo: string, selector: string, numero: number) => `${APIDIR}/api/catalogos/cie-10/max/${sexo}/${selector}/${numero}`;
export const AntecedentesParentescoURL = () => `${APIDIR}/api/catalogos/antecedentes_parentesco`;
export const getCatalogoCie10Palabra = (palabra: string) => `${APIDIR}/api/catalogos/cie-10/palabra/${palabra}`;
export const getCatalogoCie10Query = (
  query: string,
  sexo: string,
  periodoEdad: string,
  edad: number,
) => `${APIDIR}/api/catalogos/cie-10/query/${query}/${sexo}/${periodoEdad}/${edad}`;
export const getCatalogoCie10SexoQuery = (query: string, sexo: string) => `${APIDIR}/api/catalogos/cie-10/query-sexo/${query}/${sexo}`;
export const getCatalogoCie10QueryCategoria = (
  query: string,
  sexo: string,
  periodoEdad: string,
  edad: number,
) => `${APIDIR}/api/catalogos/cie-10/query-cat/${query}/${sexo}/${periodoEdad}/${edad}`;
export const getCatalogoCie10QueryCategoriaSexo = (query: string, sexo: string) => `${APIDIR}/api/catalogos/cie-10/query-cat-sexo/${query}/${sexo}`;
export const AlergiasURL = () => `${APIDIR}/api/catalogos/antecedentes-alergias`;
export const AlergiasURLAlergia = () => `${APIDIR}/api/alergias/alergia`;
export const AlergiasURLSinAntecedentes = () => `${APIDIR}/api/alergias/sin-antecedentes`;
export const TransfusionesPacienteURL = (idMedico: number, idPaciente: number) => `${APIDIR}/api/transfusion/obtener-datos/${idMedico}/${idPaciente}`;
export const TransfusionesURLTransfusion = () => `${APIDIR}/api/transfusion/transfusion`;
export const TransfusionesURLSinAntecedentes = () => `${APIDIR}/api/transfusion/sin-antecedentes`;
export const HospitalizacionesPacienteURL = (idMedico: number, idPaciente: number) => `${APIDIR}/api/hospitalizaciones/hospitalizaciones/${idPaciente}/${idMedico}`;
export const HospitalizacionesURLHospitalizacion = () => `${APIDIR}/api/hospitalizaciones/hospitalizacion`;
export const HospitalizacionesURLSinAntecedentes = () => `${APIDIR}/api/hospitalizaciones/sin-antecedentes`;
export const InmunizacionesURL = (id: string, edad: string, idMedico: string, idConsulta: string) => `${APIDIR}/api/inmunizaciones/obtener-vacunas/${id}/${edad}/${idMedico}/${idConsulta}`;
export const PublicKeyURL = () => `${APIDIR}/api/llaves`;
export const PacientesTablaURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tablaInicio: number,
) => `${APIDIR}/api/paciente-doctor/detalle/${idMedico}/${idOrganizacion}/${idConsultorio}/${tablaInicio}`;
export const PacientesTablaBusquedaURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  nombreString: string,
) => `${APIDIR}/api/paciente-doctor/detalle-busqueda-nombre/${idMedico}/${idOrganizacion}/${idConsultorio}/${nombreString}`;
export const PacientesAgendaBusquedaURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  nombreString: string,
) => `${APIDIR}/api/paciente-doctor/busqueda-nombre-agenda/${idMedico}/${idOrganizacion}/${idConsultorio}/${nombreString}`;
export const CrearTokenPacienteURL = () => `${APIDIR}/api/paciente-doctor/id-acceso`;
export const VerificarTokenPacienteURL = (
  idMedico: number,
  docId: string,
  idConsultorio: string,
  acceso: string,
) => `${APIDIR}/api/paciente-doctor/validar-acceso?idMedico=${idMedico}&docId=${docId}&idConsultorio=${idConsultorio}&acceso=${acceso}`;
export const GenericoURL = (searchTerm: string) => `${APIDIR}/api/farmacologico/med-generico?q=${searchTerm}`;
export const GenericoIDURL = (id: number) => `${APIDIR}/api/farmacologico/med-generico/${id}`;
export const PatenteURL = (searchTerm: string) => `${APIDIR}/api/farmacologico/med-comercial?q=${searchTerm}`;
export const CatFarmaPeriodoURL = () => `${APIDIR}/api/farmacologico/cat/periodo`;
export const CatFarmaFrecuenciaURL = () => `${APIDIR}/api/farmacologico/cat/frecuencia`;
export const ObtenerViasGenerico = (id: number) => `${APIDIR}/api/farmacologico/med-generico/vias/${id}`;
export const ObtenerViasPatente = (id: number) => `${APIDIR}/api/farmacologico/med-comercial/vias/${id}`;
export const ObtenerUnidadFormaGenerico = (id: number) => `${APIDIR}/api/farmacologico/med-generico/unidad-forma/${id}`;
export const ObtenerIndicationGenerico = (id: number) => `${APIDIR}/api/farmacologico/generico/indication/${id}`;
export const CatFarmaUnidad = () => `${APIDIR}/api/farmacologico/cat/unidad`;
export const CatFarmaVia = () => `${APIDIR}/api/farmacologico/cat/via`;
// PARA GUARDAR Y ACTUALIZAR
export const perfilPacienteDatosGenerales = () => `${APIDIR}/api/perfil-paciente/datos-generales`;
export const perfilPacienteDatosUbicacion = () => `${APIDIR}/api/perfil-paciente/datos-ubicacion`;
export const perfilPacienteInfoEmergencia = () => `${APIDIR}/api/perfil-paciente/info-emergencia`;
export const perfilPacienteSegurosPlanes = () => `${APIDIR}/api/perfil-paciente/seguros-planes`;
export const perfilPacienteDatosFacturacion = () => `${APIDIR}/api/perfil-paciente/datos-ubicacion`;
export const perfilPacienteDiscapacidades = () => `${APIDIR}/api/perfil-paciente/discapacidades`;
export const perfilPacienteNotasURL = () => `${APIDIR}/api/perfil-paciente/notas`;
export const perfilMedicoDatosURL = () => `${APIDIR}/api/perfil-medico/perfil`;
export const perfilMedicoCarreraURL = () => `${APIDIR}/api/perfil-medico/carrera`;
export const perfilMedicoEspecialidadURL = () => `${APIDIR}/api/perfil-medico/especialidad`;
export const perfilMedicoMaestriaURL = () => `${APIDIR}/api/perfil-medico/maestria`;
export const perfilMedicoDoctoradoURL = () => `${APIDIR}/api/perfil-medico/doctorado`;
export const perfilMedicoExperienciaURL = () => `${APIDIR}/api/perfil-medico/experiencia`;
export const saveAlergias = () => `${APIDIR}/api/alergias/guardar-datos`;
export const saveInmunizaciones = () => `${APIDIR}/api/inmunizaciones/guardar-vacunas`;
export const antecedentesNoPatologicos = () => `${APIDIR}/api/antecedentes/no-patologicos`;
export const antecedentesPatologicos = () => `${APIDIR}/api/antecedentes/patologicos`;
export const saveProcedimientos = () => `${APIDIR}/api/antecedentes/procedimientos`;
export const diagnosticoPlan = () => `${APIDIR}/api/diagnostico-y-plan/plan`;
export const diagnosticPlanSaveLaboratory = () => `${APIDIR}/api/diagnostico-y-plan/guardar_laboratorio`;
export const saveLaboratory = () => `${APIDIR}/api/laboratorio/modificar-laboratorio`;
export const saveAgendaConfigurar = () => `${APIDIR}/api/agenda/configuracion`;
export const saveAgenda = () => `${APIDIR}/api/agenda/guarda-agenda`;
export const saveAgendaDelete = () => `${APIDIR}/api/agenda/deleteAgenda`;
export const deleteLaboratory = () => `${APIDIR}/api/laboratorio/eliminar`;
export const enfermedadesListasEnfermedads = () => `${APIDIR}/api/enfermedades/listas-enfermedades`;
export const enfermedadesListaEnfermedad = () => `${APIDIR}/api/enfermedades/lista-enfermedad`;
export const datosCheckout = () => `${APIDIR}/api/payment-method/payment`;
export const subscripcionPaymentMedico = (email: string) => `${APIDIR}/api/payment-method/subscripcion?email=${email}`;
export const transfusionGuardar = () => `${APIDIR}/api/transfusion/guardar-datos`;
export const pronosticoGuardar = () => `${APIDIR}/api/diagnostico-y-plan/guardar-pronostico`;
// PARA OBTENER
export const getMcpa = (idMedico: number, idPaciente: number, idConsulta: number) => `${APIDIR}/api/mcpa/consultar-mcpa/${idMedico}/${idPaciente}/${idConsulta}`;
export const getPerfilPacienteDatosGenerales = (idMedico: number, idPaciente: number) => `${APIDIR}/api/perfil-paciente/datos-generales/${idMedico}/${idPaciente}`;
export const getPerfilPacienteDatosUbicacion = (idMedico: number, idPaciente: number) => `${APIDIR}/api/perfil-paciente/datos-ubicacion/${idMedico}/${idPaciente}`;
export const getPerfilPacienteDatosEmergencia = (idMedico: number, idPaciente: number) => `${APIDIR}/api/perfil-paciente/info-emergencia/${idMedico}/${idPaciente}`;
export const getPerfilPacienteDatosSeguros = (idMedico: number, idPaciente: number) => `${APIDIR}/api/perfil-paciente/seguros-planes/${idMedico}/${idPaciente}`;
export const getPerfilPacienteDiscapacidades = (idMedico: number, idPaciente: number) => `${APIDIR}/api/perfil-paciente/discapacidades/${idMedico}/${idPaciente}`;
export const getPerfilPacienteNotasURL = (idMedico: number, idPaciente: number) => `${APIDIR}/api/perfil-paciente/notas/${idMedico}/${idPaciente}`;
export const getValueMedicinaPreventivaURL = (idPaciente: number, idConsulta: number) => `${APIDIR}/api/medicina-preventiva/cardiovascular/${idPaciente}/${idConsulta}`;
export const getTableGloboriskURL = (
  sexo: string,
  diabetico: string,
  fumador: string,
  edad: string,
) => `${APIDIR}/api/medicina-preventiva/tabla-cardiovascular/${sexo}/${diabetico}/${fumador}/${edad}`;
export const getRiesgoGloboriskURL = (
  sexo: string,
  diabetico: string,
  fumador: string,
  presion: string,
  colesterol: string,
  edad: string,
) => `${APIDIR}/api/medicina-preventiva/riesgo-cardiovascular/${sexo}/${diabetico}/${fumador}/${presion}/${colesterol}/${edad}`;
export const getPronosticosDiagnosticos = (
  idMedico: string,
  idPaciente: string,
  idDiagnostico: string,
) => `${APIDIR}/api/diagnostico-y-plan/pronostico/${idMedico}/${idPaciente}/${idDiagnostico}`;
export const getListaPronosticos = (idMedico: string, idPaciente: string, idConsulta: string) => `${APIDIR}/api/diagnostico-y-plan/pronostico-lista/${idMedico}/${idPaciente}/${idConsulta}`;
export const getPronosticosEnfermedad = (
  idMedico: string,
  idPaciente: string,
  idEnfermedad: string,
) => `${APIDIR}/api/diagnostico-y-plan/pronostico/${idMedico}/${idPaciente}/${idEnfermedad}`;
export const getPlanLaboratory = (idConsulta: string) => `${APIDIR}/api/diagnostico-y-plan/solicitud-laboratorio/${idConsulta}`;
export const getDatosGeneralesPaciente = (
  idPaciente: number,
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
) => `${APIDIR}/api/perfil-paciente/datos-generales/paciente/${idPaciente}/${idMedico}/${idOrganizacion}/${idConsultorio}`;
export const getDatosGeneralesUsuarioPaciente = (idPaciente: number) => `${APIDIR}/api/perfil-paciente/datos-generales-usuario/${idPaciente}`;
export const Cie9URL = (id: string, sexo: string) => `${APIDIR}/api/catalogos/cie-9/${id}/${sexo}`;
export const getLaboratoriosUsuario = (idPaciente: string) => `${APIDIR}/api/laboratorio/totales/${idPaciente}`;
export const getRecomendacionReferencia = (id: string) => `${APIDIR}/api/catalogos/recomendacion-referencia/${id}`;
export const verificarLaboratorios = `${APIDIR}/api/laboratorio/seleccionado`;
export const getExcelLaboratorio = (idPaciente: string, idLaboratorio: string, txt: string) => `${APIDIR}/api/laboratorio/valor/${idPaciente}/${idLaboratorio}/${txt}`;
export const getPDFConsulta = (idConsulta: number, tipoSolicitud: number) => `${APIDIR}/api/consulta-medica-pdf/${idConsulta}/${tipoSolicitud}`;
export const getEditLaboratorio = (idPaciente: string, idLaboratorio: string) => `${APIDIR}/api/laboratorio/editar/${idPaciente}/${idLaboratorio}`;
export const getProcedimientosData = (
  idMedico: string,
  idPaciente: string,
  idConsulta: string,
  idProcedimiento: string,
) => `${APIDIR}/api/diagnostico-y-plan/consultar-plan/${idMedico}/${idPaciente}/${idConsulta}/${idProcedimiento}`;
export const getEstablecimientosSalud = (agee: string, agem: string) => `${APIDIR}/api/catalogos/establecimientos-salud/${agee}/${agem}`;
export const getAgendaConfiguracionURL = (idMedico: string) => `${APIDIR}/api/agenda/configuracion/${idMedico}`;
export const getAgendaURL = (idMedico: string, mes: string, anio: string) => `${APIDIR}/api/agenda/agenda/${idMedico}/${mes}/${anio}`;
// Componentes
export const componentesGuardar = () => `${APIDIR}/api/components/guardar-componente`;
// BUILDER
export const builderURLBuilder = () => `${APIDIR}/api/builder`;
export const builderURLBuilderVariable = () => `${APIDIR}/api/builder/variable`;
export const builderURLBuilderVariablesTop = (idMedico: number) => `${APIDIR}/api/builder/variables/${idMedico}`;
export const builderURLBuilderGetVariableMedico = (idMedico: number, idVariable: number) => `${APIDIR}/api/builder/obtener-variable-medico/${idMedico}/${idVariable}`;
export const builderURLBuilderGetVariableMedipraxi = (idVariable: number) => `${APIDIR}/api/builder/obtener-variable-medipraxi/${idVariable}`;
export const builderURLFCValidacion = () => `${APIDIR}/api/builder/formato-consulta/validacion`;
export const builderURLFCActualizar = () => `${APIDIR}/api/builder/formato-consulta/actualizar`;
export const builderURLFCEliminar = () => `${APIDIR}/api/builder/formato-consulta/eliminar`;
export const builderURLCompartirFormatoConsulta = () => `${APIDIR}/api/builder/formato-consulta/compartir`;
export const builderURLBuscarVariables = (idMedico: number, strBusqueda: string) => `${builderURLBuilderVariable()}/${idMedico}/${strBusqueda}`;
export const builderURLObtenerFormatoConsultaPropio = (idFormato: number) => `${APIDIR}/api/builder/formato-consulta/obtener-propio?idFormato=${idFormato}`;
export const builderURLObtenerFormatoConsultaMedipraxi = (idFormato: number) => `${APIDIR}/api/builder/formato-consulta/obtener-medipraxi?idFormato=${idFormato}`;
export const builderURLObtenerFormatoMotivosTop = (idMedico: number) => `${APIDIR}/api/builder/formato-consulta-top/${idMedico}`;
export const builderURLBuscarFormatoMotivosPropios = (
  idMedico: number,
  motivoNombre: string,
  motivoCategoria: number,
  motivoRango: number,
  motivoSexo: number,
  motivoConsulta: number,
  pagina: number,
) => `${APIDIR}/api/builder/formato-consulta/propios?idMedico=${idMedico}&motivoNombre=${motivoNombre}&motivoCategoria=${
  motivoCategoria || ''
}&motivoRango=${motivoRango || ''}&motivoSexo=${motivoSexo || ''}&motivoConsulta=${
  motivoConsulta || ''
}&pagina=${pagina}`;
export const builderURLBuscarFormatoMotivosMedipraxi = (
  idMedico: number,
  motivoNombre: string,
  motivoCategoria: number,
  motivoRango: number,
  motivoSexo: number,
  motivoConsulta: number,
  pagina: number,
) => `${APIDIR}/api/builder/formato-consulta/medipraxi?idMedico=${idMedico}&motivoNombre=${motivoNombre}&motivoCategoria=${
  motivoCategoria || ''
}&motivoRango=${motivoRango || ''}&motivoSexo=${motivoSexo || ''}&motivoConsulta=${
  motivoConsulta || ''
}&pagina=${pagina}`;
export const builderURLHabilitarUsoMCMedipraxi = () => `${APIDIR}/api/builder/habilitar-uso`;
// MCPA
export const MCPAURLBuscar = (
  tipoConsulta: number,
  categoria: number,
  sexo: number,
  rangoEdad: number,
  idMedico: number,
  idOrganizacion: number,
) => `${APIDIR}/api/mcpa/buscar?tipoConsulta=${tipoConsulta}&categoria=${categoria}&sexo=${sexo}&rangoEdad=${rangoEdad}&idMedico=${idMedico}&idOrganizacion=${idOrganizacion}`;
export const MCPAURL = () => `${APIDIR}/api/mcpa`;
// obtener los componentes del medico
export const todosComponentesMedico = (idMedico: string) => `${APIDIR}/api/components/busqueda-componente/${idMedico}`;
export const componenteUnico = (idMedico: string, idComponente: string) => `${APIDIR}/api/components/busqueda-componente-unico/${idMedico}/${idComponente}`;
// formato de consulta
export const verificarFormatoURL = `${APIDIR}/api/formato-consulta/verificar-formato`;
export const actualizarFormatoURL = `${APIDIR}/api/formato-consulta/actualizar-formato`;
export const getFormatosMedico = (idMedico: string) => `${APIDIR}/api/formato-consulta/medico-formatos/${idMedico}`;
export const getFormatoSeleccionMedico = (idMedico: string, idFor: string) => `${APIDIR}/api/formato-consulta/medico-formatos/${idMedico}/${idFor}`;
// Aparatos y sistemas
export const aparatosYSistemas = `${APIDIR}/api/aparatos-y-sistemas`;
export const exploracionFisica = `${APIDIR}/api/exploracion-fisica/guardar`;
// terapia farmacologica
export const Farmacologico = `${APIDIR}/api/farmacologico`;
export const FarmacologicoGuardarMedicamento = () => `${Farmacologico}/medicamento`;
export const FarmacologicoGuardarReceta = () => `${Farmacologico}/receta`;
export const FarmacologicoMedicamentosActuales = (idPaciente: number) => `${Farmacologico}/medicamentos-actuales/${idPaciente}`;
export const FarmacologicoMedicamentosAnteriores = (idPaciente: number) => `${Farmacologico}/medicamentos-anteriores/${idPaciente}`;
export const FarmacologicoMedicamentosLista = (idPaciente: number) => `${Farmacologico}/medicamentos-lista/${idPaciente}`;
export const FarmacologicoRecetaMedicamento = (idReceta: number) => `${Farmacologico}/receta/${idReceta}`;
export const FarmacologicoRecetasMedicamentos = (idConsulta: number) => `${Farmacologico}/recetas/${idConsulta}`;
// Enfermedades
export const getEnfermedadesListasEnfermedades = (idPaciente: number) => `${APIDIR}/api/enfermedades/listas-enfermedades/${idPaciente}`;
export const getEnfermedadesArrEnfermedadesReferidas = (idPaciente: number) => `${APIDIR}/api/enfermedades/listas-enfermedades-referidas/${idPaciente}`;
// Perfil Médico
export const perfilMedicoConsultoriosURL = () => `${APIDIR}/api/perfil-medico/consultorios`;
export const perfilMedicoObtenerConsultorios = (idMedico: number, idOrganizacion: number) => `${APIDIR}/api/perfil-medico/consultorios/${idMedico}/${idOrganizacion}`;
export const perfilMedicoConsultoriosSimplificados = (idMedico: number, idOrganizacion: number) => `${APIDIR}/api/perfil-medico/consultorios-simple/${idMedico}/${idOrganizacion}`;
export const perfilMedicoObtenerConsultorio = (idConsultorio: number) => `${APIDIR}/api/perfil-medico/consultorio/${idConsultorio}`;
export const perfilMedicoObtenerMe = (email: string) => `${APIDIR}/api/perfil-medico/me/${email}`;
export const perfilMedicoDatosIdentificacion = (email: string) => `${APIDIR}/api/perfil-medico/datos-identificacion/${email}`;
export const perfilMedicoDatosCarrera = (id: string) => `${APIDIR}/api/perfil-medico/datos-carrera/${id}`;
export const perfilMedicoDatosEspecialidad = (id: string) => `${APIDIR}/api/perfil-medico/datos-especialidad/${id}`;
export const perfilMedicoDatosMaestria = (id: string) => `${APIDIR}/api/perfil-medico/datos-maestria/${id}`;
export const perfilMedicoDatosDoctorado = (id: string) => `${APIDIR}/api/perfil-medico/datos-doctorado/${id}`;
export const perfilMedicoDatosExperiencia = (id: string) => `${APIDIR}/api/perfil-medico/datos-experiencia/${id}`;
// Antecedentes Familiares
export const antecedentesFamiliaresGuardarFamiliar = () => `${APIDIR}/api/familiares/guardar-enfermedades-familiar`;
export const antecedentesFamiliarAgregarEnfermedad = () => `${APIDIR}/api/familiares/agregar-enfermedades-familiar`;
export const antecedentesFamiliaresObtenerListaFamiliares = (idPaciente: number) => `${APIDIR}/api/familiares/${idPaciente}`;
export const antecedentesFamiliaresEditarListaFamiliares = () => `${APIDIR}/api/familiares/editar-enfermedades-familiar`;
export const antecedentesFamiliarEnfermedad = () => `${APIDIR}/api/familiares/enfermedad`;
export const antecedentesFamiliarEnfermedadNegada = () => `${APIDIR}/api/familiares/enfermedad-negada`;
// Consultas Medicas
export const consultasMedicasCrearConsulta = () => `${APIDIR}/api/consultas/crear-consulta`;
export const consultasMedicasObtenerConsultaAbierta = (idMedico: number, idConsultorio: number) => `${APIDIR}/api/consultas/obtener-consulta-abierta/${idMedico}/${idConsultorio}`;
export const consultasMedicasObtenerConsulta = (idMedico: number, idPaciente: number) => `${APIDIR}/api/consultas/obtener-consulta/${idMedico}/${idPaciente}`;
export const consultasMedicasExistenciaPaciente = (
  idMedico: number,
  idConsultorio: number,
  docId: string,
) => `${APIDIR}/api/consultas/busca-paciente?idMedico=${idMedico}&idConsultorio=${idConsultorio}&docId=${docId}`;
export const consultasMedicasGuardarPacienteConsulta = () => `${APIDIR}/api/consultas/guardar-consulta`;
export const consultasMedicasFinalizarPacienteConsulta = () => `${APIDIR}/api/consultas/finalizar-consulta`;
// Costo planes de pagos
export function getCostoPlan(tipo: string, plan: string) {
  // Plan Premium
  if (tipo === 'P' && plan === 'M') {
    return '750';
  }
  if (tipo === 'P' && plan === 'A') {
    return '7,500';
  }
  // Plan Estandar
  if (tipo === 'E' && plan === 'M') {
    return '499';
  }
  if (tipo === 'E' && plan === 'A') {
    return '4,990';
  }
  // Plan Basico
  if (tipo === 'B' && plan === 'M') {
    return '199';
  }
  if (tipo === 'B' && plan === 'A') {
    return '1,990';
  }
  return '750';
}
export function getSuscripcion(t: string, p: string) {
  let tipo = '';
  let plan = '';
  if (t === 'P') {
    tipo = 'Premium';
  }
  if (t === 'E') {
    tipo = 'Estándar';
  }
  if (t === 'B') {
    tipo = 'Básico';
  }
  if (p === 'M') {
    plan = 'Mensual';
  }
  if (p === 'A') {
    plan = 'Anual';
  }
  return `Suscripción ${tipo} (${plan})`;
}
