import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, TextField, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import { IAlergiasFormProps } from './types';
import { ICatalogosEstandar } from '../../../../../../common/types';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import { alergiasGuardarAlergia } from '../../../../../../utils/sendInfo';
import { alergiasActualizarAlergia } from '../../../../../../utils/editInfo';
import { getPaciente } from '../../../../../../actions/basicosPaciente/actions';
import { RootState } from '../../../../../../store';
import { setRequest } from '../../../../../../actions/request/types';

function AlergiasForm(props: IAlergiasFormProps) {
  const {
    catAlergias,
    alergiaForm,
    setAlergiaForm,
    gradoForm,
    setGradoForm,
    alergias,
    setAlergias,
    idBD,
    setIDBD,
    idPaciente,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  /* funcion para cerrar el modal de confirmacion que se activa si ya existe una misma alergia referida */
  const handleConfirmClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenConfirmDialog(false);
  };
  const handleChangeAlergia = (event: any, newValue: ICatalogosEstandar | null) => {
    if (newValue) {
      setAlergiaForm(newValue);
    } else {
      setAlergiaForm({ id: 0, label: '' });
    }
  };

  const handleChangeGrado = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGradoForm((event.target as HTMLInputElement).value);
  };

  const handleClickGuardarAlergia = () => {
    // buscamos que el id de la alergia no exista en la lista de alergias
    const found = alergias.findIndex((alerg) => alerg.alergia.id === alergiaForm.id);
    if (found >= 0) {
      // si encontramos una alergia previamente guardada tenemos que mostar el modal de que ya existe esa alergia
      setOpenConfirmDialog(true);
      return;
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: alergiasGuardarAlergia({
          idMedico,
          idPaciente,
          idConsultorio,
          grado: gradoForm,
          idBD,
          consulta: idConsulta,
          alergia: alergiaForm,
        }),
        successFunction: (result: any) => {
          // recargamos los datos del paciente para que se actualizen las alergias en la barra de herramientas
          dispatch(getPaciente(idPaciente, idMedico, idOrganizacion, idConsultorio));
          setAlergias(
            alergias.concat({
              idBD: result.datos,
              alergia: alergiaForm,
              grado: gradoForm,
              borrado: false,
              actualizado: false,
            }),
          );
          setAlergiaForm({ id: 0, label: '' });
          setGradoForm('');
          setIDBD(-1);
        },
      }),
    );
  };

  const handleClickActualizarAlergia = () => {
    // buscamos que el id de la alergia no exista en la lista de alergias a exepcion que sea el mismo
    // id que estamos tratando de editar (en caso que sea la misma alergia pero diferente grado)
    const found = alergias.findIndex(
      (alerg) => alerg.alergia.id === alergiaForm.id && alerg.idBD !== idBD,
    );
    if (found >= 0) {
      // si encontramos una alergia previamente guardada con el nuevo id (en aso de cambiar el tipo de alergia)
      // tenemos que mostar el modal de que ya existe esa alergia
      setOpenConfirmDialog(true);
      return;
    }
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: alergiasActualizarAlergia({
          idPaciente,
          idMedico,
          idConsultorio,
          grado: gradoForm,
          idBD,
          consulta: idConsulta,
          alergia: alergiaForm,
        }),
        successFunction: () => {
          // recargamos los datos del paciente para que se actualizen las alergias en la barra de herramientas
          dispatch(getPaciente(idPaciente, idMedico, idOrganizacion, idConsultorio));
          // buscamos el indice a actualizar en la lista (en base al id)
          const idAActualizar = alergias.findIndex((aler) => aler.idBD === idBD);
          // si encontramos el indice, procedemos a reemplazar lo que existe en la lista por lo actualizado
          if (idAActualizar >= 0) {
            const nuevaLista = [...alergias];
            nuevaLista[idAActualizar] = {
              ...nuevaLista[idAActualizar],
              actualizado: true,
              alergia: alergiaForm,
              grado: gradoForm,
            };
            setAlergias(nuevaLista);
          }
          // limpiamos el formulario
          setAlergiaForm({ id: 0, label: '' });
          setGradoForm('');
          setIDBD(-1);
        },
      }),
    );
  };

  const disabledButton = () => {
    let valBool = true;
    if (alergiaForm.id > 0 && gradoForm.length > 0) {
      valBool = false;
    }
    return valBool;
  };

  return (
    <>
      <ConfirmDialog
        open={openConfirmDialog}
        titulo="¡Atención!"
        descripcion={t('esta_alergia_ya_fue_incluida_en_la_lista')}
        nombreCancelar={t('aceptar')}
        callBackClose={handleConfirmClose}
      />
      <div className="rounded-md border-solid border border-gray-300 p-4">
        <div className="mb-2">
          <h3 className="text-gray-600 m-0 font-medium">
            {t('tipo_de_alergia')}
            *
          </h3>
          <span className="text-gray-600">
            {t('_puedes_buscar_por_terminologia_de_clases_o_principios_activos_')}
          </span>
        </div>
        <div className="mb-4">
          <Autocomplete
            options={catAlergias}
            getOptionLabel={(option) => option.label}
            noOptionsText={t('sin_resultados')}
            value={alergiaForm}
            onChange={(e, nV) => handleChangeAlergia(e, nV)}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
          />
        </div>
        <RadioGroup value={gradoForm} onChange={handleChangeGrado}>
          <div className="rounded-md border-solid border border-gray-300 mb-4">
            <div className="grid grid-cols-4 bg-blue-700 py-2 rounded-t-md">
              <h4 className="m-0 text-center font-medium text-white">
                {t('grado')}
                *
              </h4>
              <h4 className="col-span-3 m-0 text-center font-medium text-white">
                {t('descripcion')}
              </h4>
            </div>
            <div className="grid grid-cols-4 p-2">
              <FormControlLabel
                className="pl-14"
                value="grado_1"
                control={<Radio color="primary" />}
                label={t('grado_1')}
              />
              <div className="col-span-3">
                <span>{t('sintomas_o_signos_de_solo_un_sistema_de_organos')}</span>
                <ul className="list-disc pl-0 list-inside">
                  <li className="text-gray-600">{t('app-alergias-grado1-1')}</li>
                  <li className="text-gray-600">{t('app-alergias-grado1-2')}</li>
                  <li className="text-gray-600">{t('app-alergias-grado1-3')}</li>
                  <li className="text-gray-600">{t('app-alergias-grado1-4')}</li>
                </ul>
              </div>
            </div>
            <div className="grid grid-cols-4 p-2 bg-blue-300 items-center">
              <FormControlLabel
                className="pl-14"
                value="grado_2"
                control={<Radio color="primary" />}
                label={t('grado_2')}
              />
              <div className="col-span-3">
                <span>{t('sintomas_o_signos_en_mas_igual_dos_organos')}</span>
              </div>
            </div>
            <div className="grid grid-cols-4 p-2">
              <FormControlLabel
                className="pl-14"
                value="grado_3"
                control={<Radio color="primary" />}
                label={t('grado_3')}
              />
              <div className="col-span-3">
                <ul className="list-disc pl-0 list-inside">
                  <li className="text-gray-600">{t('app-alergias-grado3-1')}</li>
                  <li className="text-gray-600">{t('app-alergias-grado3-2')}</li>
                  <li className="text-gray-600">{t('app-alergias-grado3-3')}</li>
                </ul>
              </div>
            </div>
            <div className="grid grid-cols-4 p-2 bg-blue-300">
              <FormControlLabel
                className="pl-14"
                value="grado_4"
                control={<Radio color="primary" />}
                label={t('grado_4_anafilaxia_')}
              />
              <div className="col-span-3">
                <ul className="list-disc pl-0 list-inside">
                  <li className="text-gray-600">{t('app-alergias-grado4-1')}</li>
                  <li className="text-gray-600">{t('app-alergias-grado4-2')}</li>
                  <li className="text-gray-600">{t('app-alergias-grado4-3')}</li>
                </ul>
              </div>
            </div>
            <div className="grid grid-cols-4 p-2">
              <FormControlLabel
                className="pl-14"
                value="grado_5"
                control={<Radio color="primary" />}
                label={t('grado_5')}
              />
              <div className="col-span-3">
                <ul className="list-disc pl-0 list-inside">
                  <li className="text-gray-600">{t('app-alergias-grado5-1')}</li>
                  <li className="text-gray-600">{t('app-alergias-grado5-2')}</li>
                  <li className="text-gray-600">{t('app-alergias-grado5-3')}</li>
                </ul>
              </div>
            </div>
          </div>
        </RadioGroup>
        <div className="flex justify-between mb-2">
          <div>
            <span className="text-gray-600">
              {t('app-alergias-nota-adapatado-1')}
              &nbsp;
            </span>
            <br />
            <a
              href="https://doi.org/10.1007/s40521-019-00229-8"
              target="_blank"
              rel="noreferrer"
              className="text-gray-600"
            >
              https://doi.org/10.1007/s40521-019-00229-8
            </a>
          </div>
          {idBD > 0 ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<SyncIcon />}
              onClick={handleClickActualizarAlergia}
            >
              {t('actualizar')}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleClickGuardarAlergia}
              disabled={disabledButton()}
            >
              {t('guardar')}
            </Button>
          )}
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600">
            *
            {t('campos_obligatorios')}
          </span>
          <span className="text-gray-600">
            {t('debes_dar_click_en_el_boton_agregar_alergia_para_incluirla')}
          </span>
        </div>
      </div>
    </>
  );
}

export default AlergiasForm;
