import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IconButton, TextField, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { getPublicKey } from '../../utils/getCatalogos';
import { sendLogin } from '../../utils/sendInfo';
import loading from '../../actions/loading/actions';
import useStyles from '../../common/styles';
import { validacionEmail, validacionIp } from '../../constants/validaciones';
import {
  removeUserSession, setEmailMe, setToken, setUser,
} from '../../utils/common';
import { setRequest } from '../../actions/request/types';
import { setSnackComplete } from '../../actions/snackbar/types';
import { setAuth } from '../../actions/auth/actions';
import { getMeMedico } from '../../actions/me/actions';
import { str2AB } from '../../common/base64Convert';
import { ILoginProps } from './types';

function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function Login({ esPaciente = false }: ILoginProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [ip, setIp] = useState<string>('');
  const [verPassword, setVerPassword] = useState(false);
  const [keyBack, setKeyBack] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [contrasenia, setContrasenia] = useState<string>('');
  const [errores, setErrores] = useState({
    email: { error: false, helperText: '' },
    password: { error: false, helperText: '' },
    general: { error: false, helperText: '' },
  });

  const fetchIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org');
      const data = await response.text();
      if (validacionIp(data)) {
        setIp(data);
      }
    } catch (e) { /* empty */ }
  };

  useEffect(() => {
    fetchIP();
    const abortController = new AbortController();
    const { signal } = abortController;
    getPublicKey(signal).then((result: string) => setKeyBack(result));
    return () => abortController.abort();
  }, []);

  const handleClickMostrarPassword = () => {
    setVerPassword((prevVal) => !prevVal);
  };

  const handleFocusEmailCorreo = () => {
    setErrores({
      email: { error: false, helperText: '' },
      password: { error: false, helperText: '' },
      general: { error: false, helperText: '' },
    });
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setEmail(val.trim().toLowerCase());
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setContrasenia(val);
    if (val.length) {
      setErrores((preV) => ({ ...preV, password: { helperText: '', error: false } }));
    } else {
      setErrores((preV) => ({
        ...preV,
        password: { helperText: t('error_campo_en_blanco'), error: true },
      }));
    }
  };

  const handleBlurContrasenia = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (val.length) {
      setErrores((preV) => ({ ...preV, password: { helperText: '', error: false } }));
    } else {
      setErrores((preV) => ({
        ...preV,
        password: { helperText: t('error_campo_en_blanco'), error: true },
      }));
    }
  };

  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setErrores((preV) => ({ ...preV, email: { helperText: '', error: false } }));
    } else {
      setErrores((preV) => ({
        ...preV,
        email: { helperText: t('email_incorrecto'), error: true },
      }));
    }
  };

  const handleClickIniciarSesion = async () => {
    // creadas el par de llaves del front (usuario)
    if (email === '' || contrasenia === '') {
      if (email === '') {
        setErrores((preV) => ({
          ...preV,
          email: { error: true, helperText: t('error_campo_en_blanco') },
        }));
      } else {
        setErrores((preV) => ({
          ...preV,
          password: { error: true, helperText: t('error_campo_en_blanco') },
        }));
      }
      return;
    }
    setErrores({
      email: { error: false, helperText: '' },
      password: { error: false, helperText: '' },
      general: { error: false, helperText: '' },
    });
    dispatch(loading(true));
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'decrypt'],
    );
    // exportadas a JWK (Json Web Key)
    let publicKeyJwk;
    if (keyPair.publicKey) {
      publicKeyJwk = await window.crypto.subtle.exportKey('jwk', keyPair.publicKey);
    } else {
      return;
    }
    // const privateKeyJwk = await window.crypto.subtle.exportKey('jwk', keyPair.privateKey); // a proposito
    // Headers de llaves PEM
    const pemHeader = '-----BEGIN PUBLIC KEY-----';
    const pemFooter = '-----END PUBLIC KEY-----';
    // Importada la key desde el back
    if (!keyBack) {
      dispatch(loading(false));
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: 'Atención, error en el navegador, refrescar la página e intentar nuevamente',
        }),
      );
      return;
    }
    const pemContents = keyBack.substring(pemHeader.length, keyBack.length - pemFooter.length);
    const binaryDerString = window.atob(pemContents);
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
    // email encriptado
    const emailC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(email),
    );
    // contraseña encriptada
    const contraseniaC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(contrasenia),
    );
    // Inicio IndexedDB
    // const request = window.indexedDB.open('Innovathink', 3); // a proposito
    // fin IndexedDB
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendLogin({
          password: arrayBufferToBase64(contraseniaC),
          email: arrayBufferToBase64(emailC),
          ip,
          llave: publicKeyJwk,
        }),
        successFunction: (result) => {
          setEmailMe(email);
          setToken(result.datos.Bearer);
          setUser(result.datos.userSend);
          dispatch(
            setAuth({
              id: result.datos.userSend,
              acreditado: true,
              suscrito: result.datos.suscrito,
            }),
          );
          dispatch(getMeMedico(email));
          if (result.datos.suscrito) {
            if (result.datos.tipoRol === 'superadmin') {
              history.push(
                result.datos.tipoRol === 'superadmin' && result.datos.numOrgs > 0
                  ? '/home'
                  : '/administracion',
              );
            } else {
              history.push(result.datos.tipoRol === 'admin' ? '/administracion' : '/home');
            }
          } else if (result.datos.tipoRol === 'paciente') {
            history.push('/tablero-paciente');
          } else {
            history.push('/subscripcion');
          }
        },
        errorFunction: (result) => {
          switch (result.code) {
            case 408:
              setErrores({
                email: { error: false, helperText: '' },
                password: { error: true, helperText: t('error_contrasenia_incorrecta') },
                general: { error: true, helperText: t('error_en_email_o_contrasenia') },
              });
              break;
            case 409:
              setErrores({
                email: { error: true, helperText: t('error_email_no_encontrado') },
                password: { error: false, helperText: '' },
                general: { error: true, helperText: t('error_en_email_o_contrasenia') },
              });
              break;
            case 500:
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: t('error_ocurrio_un_error_en_el_servidor'),
                }),
              );
              break;
            case 401:
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: t('error_logueado_anteriormente'),
                }),
              );
              break;
            default:
              dispatch(loading(false));
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: t('error_ocurrio_un_error_intentar_mas_tarde'),
                }),
              );
              break;
          }
          removeUserSession();
          setToken('');
        },
        showMsgLoading: false,
        showMsgSuccess: false,
      }),
    );
  };

  const handleKeyDownContrasenia = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !errores.email.error && !errores.password.error) {
      handleClickIniciarSesion();
    }
  };

  return (
    <div className="relative md:mx-auto w-full md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12">
      <div className="absolute top-0 right-0 mt-3 mr-3">
        <IconButton
          aria-label="delete"
          onClick={() => {
            history.push(esPaciente ? '/signup-patient' : '/signup');
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className="shadow-lg bg-white rounded-xl py-6 px-10 border border-solid border-gray-200">
        <div className="w-full text-center">
          <img src="/img/Logotipo_Color.png" alt="logotipo" className="w-5/12" />
        </div>

        <form className="pt-4 pb-2 my-2">
          <div className="mb-4">
            <TextField
              error={errores.email.error || errores.general.error}
              fullWidth
              variant="outlined"
              label={t('email')}
              value={email}
              onChange={handleChangeEmail}
              onBlur={handleBlurEmail}
              onFocus={handleFocusEmailCorreo}
              helperText={errores.general.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              InputProps={{
                endAdornment: (
                  <div className="text-gray-500">
                    <EmailIcon color="inherit" />
                  </div>
                ),
              }}
              type="email"
            />
          </div>
          <div className="mb-4 mt-6">
            <TextField
              error={errores.password.error || errores.general.error}
              label={t('password')}
              variant="outlined"
              type={verPassword ? 'text' : 'password'}
              fullWidth
              value={contrasenia}
              onChange={handleChangePassword}
              onBlur={handleBlurContrasenia}
              onFocus={handleFocusEmailCorreo}
              onKeyDown={handleKeyDownContrasenia}
              helperText={errores.general.helperText}
              FormHelperTextProps={{ className: classes.absoluteBottom }}
              InputProps={{
                inputProps: { autoComplete: 'off', 'aria-autocomplete': 'none', maxLength: '50' },
                endAdornment: (
                  <div className="text-gray-500">
                    <LockIcon color="inherit" />
                  </div>
                ),
              }}
            />
          </div>
          <div className="text-right mb-4">
            <Button
              color="primary"
              startIcon={verPassword ? <Visibility /> : <VisibilityOff />}
              onClick={handleClickMostrarPassword}
            >
              {verPassword ? t('ocultar_contrasenia') : t('mostrar_contrasenia')}
            </Button>
          </div>
          <div className="text-center">
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              onClick={handleClickIniciarSesion}
              disabled={errores.email.error || errores.password.error}
            >
              {t('iniciar_sesion')}
            </Button>
            <Button
              color="primary"
              size="large"
              onClick={() => {
                history.push('/password');
              }}
            >
              {t('_has_olvidado_tu_contrasenia_')}
            </Button>
          </div>
        </form>
        <hr />
        <div className="flex items-center">
          <span>{t('_no_tienes_una_cuenta_')}</span>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              history.push(esPaciente ? '/signup-patient' : '/signup');
            }}
          >
            {t('registrate')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Login;
