import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, FormControl, MenuItem, Select, ListSubheader,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSelector, useDispatch } from 'react-redux';
import BarChartIcon from '@material-ui/icons/BarChart';
import TableChartIcon from '@material-ui/icons/TableChart';
import { HtmlTooltip } from '../../../utils/ComponentesJSX';
import { RootState } from '../../../store';
import { setRequest } from '../../../actions/request/types';
import { getDataTablaGloborisk } from '../../../utils/getCatalogos';
import Tabla from '../Tabla';
import DoughnutChart from '../../../components/Graficos/Doughnut';
import DeteccionTempranaCancer from '../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/DeteccionTempranaCancer';
import AneurismaAortaAbdominal from '../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionCardiovascular/AneurismaAortaAbdominal';
import PrevencionCardiovascularDiv from '../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionCardiovascular/PrevencionCardiovascular';
import DeteccionCancerCervicalDiv from '../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/DeteccionTempranaCancer/DeteccionCancerCervical';
import PrevencionAuditiva from '../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionRiesgos/PrevencionAuditiva';
import ExamenMedicoPeriodico from '../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/ExamenMedicoPeriodico';
import { IObjTableProps, IObjGraphCardiovascularProps, IObjTableHabitosProps } from '../types';
import useStyles from '../styles';
import getMoneda from '../../../constants/monedas';
import { IOperacionesProps } from './types';

const Operaciones = (props: IOperacionesProps) => {
  const {
    seleccionOperacion,
    setSeleccionOperacion,
    selectorTabla,
    setSelectorTabla,
    graphType,
    setGraphType,
    verTablaDetalle,
    setVerTablaDetalle,
    plantilla,
    tablaCardiovascular,
    tablaAAA,
    tablaCancerPulmon,
    tablaCancerCervical,
    tablaHabitosRiesgo,
    tablaHacinamiento,
    tablaAudiometrias,
    tablaEMP,
    graficoCardiovascular,
    tablaCardiovascularDetalle,
    tablaAAADetalle,
    tablaCancerPulmonDetalle,
    tablaCancerCervicoDetalle,
    tablaAudiometriasDetalle,
    tablaEMPDetalle,
    tableGloborisk,
    setTableGloborisk,
    tablaTabaquismoDetalle,
    tablaCafeinaDetalle,
    tablaAlcoholTrastornoDetalle,
    tablaAlcoholIntensivoDetalle,
    tablaOtrasDrogasDetalle,
    tablaHacinamientoDetalle,
    tablaActividadFisicaDetalle,
    tablaRefrescosDetalle,
    // arrayCumple,
    // setArrayCumple,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const { idMedico, pais, rol } = useSelector((state: RootState) => state.Me);
  const [verDetalle, setVerDetalle] = useState<boolean>(false);
  const [inxSeleccionado, setInxSeleccionado] = useState<number>(0);
  const [selectorHabito, setSelectorHabito] = useState<string>('alarma_tabaquismo');
  // const [selectorOtroRiesgo, setSelectorOtroRiesgo] = useState<string>('tabla_hacinamiento');
  const tablaDetalleRef = useRef<HTMLDivElement>(null);

  const getTituloCantidad = (titulo: string) => (
    <div>
      {t(titulo)}
      <div className="m-0">
        <span className="font-bold">{t('cantidad')}</span>
        <span className="font-normal"> (%)</span>
      </div>
    </div>
  );
  const getTituloTooltip = (titulo: string) => (
    <HtmlTooltip title={t(titulo) || ''}>
      <h4 className="font-normal m-0">{t('dias_restantes')}</h4>
    </HtmlTooltip>
  );
  const columnasEstandar = [
    { Header: getTituloCantidad('mujer'), accessor: 'colA' },
    { Header: getTituloCantidad('hombre'), accessor: 'colB' },
    { Header: t('business_total_alarma'), accessor: 'totalAlarma' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
  ];
  const columnasEstandarExt = [
    { Header: getTituloCantidad('mujer'), accessor: 'colA' },
    { Header: getTituloCantidad('hombre'), accessor: 'colB' },
    { Header: t('business_total_alarma'), accessor: 'totalAlarma' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
    { Header: t('sin_datos'), accessor: 'sin_datos' },
  ];
  const columnasExamenAudiometria = [
    { Header: getTituloCantidad('estudio_expirado'), accessor: 'colA' },
    { Header: getTituloCantidad('estudio_por_expirar'), accessor: 'colB' },
    { Header: t('business_total_alarma'), accessor: 'totalAlarma' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
    // { Header: t('sin_datos'), accessor: 'sin_datos' },
  ];
  const columnasExamenEMP = [
    { Header: getTituloCantidad('estudio_expirado'), accessor: 'colA' },
    { Header: getTituloCantidad('estudio_por_expirar'), accessor: 'colB' },
    { Header: t('business_total_alarma'), accessor: 'totalAlarma' },
    { Header: t('sin_datos'), accessor: 'sin_datos' },
  ];
  const columnasHabitosRiesgo = [
    { Header: t('business_habitos_riesgo'), accessor: 'titulo' },
    { Header: getTituloCantidad('mujer'), accessor: 'colA' },
    { Header: getTituloCantidad('hombre'), accessor: 'colB' },
    { Header: t('business_total_habito_alarma'), accessor: 'totalAlarma' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
    { Header: t('ex_consumidor'), accessor: 'exConsumidor' },
    { Header: t('observaciones'), accessor: 'comentarios' },
    {
      Header: `${t('business_perdida_anual_habito')} (${getMoneda(pais).simbolo})`,
      accessor: 'perdida',
    },
    { Header: '', accessor: 'verDetalle' },
  ];
  const columnasRiesgoSalud = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('oportunidad_riesgo'), accessor: 'rangoClasificacion' },
    { Header: t('sugerencia'), accessor: 'sugerencia' },
    { Header: t('contacto'), accessor: 'contacto' },
  ];
  const columnasAudiometrias = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: getTituloTooltip('pre_rie_dias_restantes'), accessor: 'estudio' },
    { Header: t('sugerencia'), accessor: 'sugerencia' },
    { Header: t('contacto'), accessor: 'contacto' },
  ];
  const columnasEMP = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: getTituloTooltip('emp_dias_restantes'), accessor: 'estudio' },
    { Header: t('sugerencia'), accessor: 'sugerencia' },
    { Header: t('contacto'), accessor: 'contacto' },
  ];
  const columnasTabaquismo = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('riesgo'), accessor: 'rangoClasificacion' },
    { Header: t('cigarrillos_al_dia'), accessor: 'consumo' },
  ];
  const columnasCafeina = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('consumo_aproximado_de_cafeina_al_dia'), accessor: 'consumo' },
  ];
  const columnasAlcohol = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('frecuencia'), accessor: 'frecuencia' },
    { Header: t('cantidad_tragos_cuando_toma'), accessor: 'consumo' },
  ];
  const columnasOtrasDrogas = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('tipo'), accessor: 'tipo' },
    { Header: t('frecuencia'), accessor: 'frecuencia' },
    { Header: t('cantidad'), accessor: 'consumo' },
  ];
  const columnasActividadFisica = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('antecedentes-pnp-actividad-minimo-semanal'), accessor: 'rangoClasificacion' },
  ];
  const columnasRefrescos = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('tipo_consumo'), accessor: 'rangoClasificacion' },
    { Header: t('consumo_semanal'), accessor: 'consumo' },
  ];
  const columnasHacinamiento = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('antecedentes-pnp-vivienda-ihac'), accessor: 'consumo' },
  ];
  const handleChangeSelector = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as
      | 'riesgo_cardiovascular'
      | 'aaa'
      | 'cancer_pulmon'
      | 'cancer_cervical'
      | 'audiometria'
      | 'emp'
      | 'habitos'
      | 'otros_riesgos';
      // | 'nom_035_riesgo_general'
      // | 'nom_035_ambiente_trabajo'
      // | 'nom_035_factores_actividad'
      // | 'nom_035_tiempo_trabajo'
      // | 'nom_035_liderazgo_relaciones'
      // | 'nom_035_entorno_organizacional';
    if (val) {
      setVerDetalle(false);
      setSelectorTabla(val);
    }
  };
  const handleChangeHabito = (tipo: string) => {
    setVerTablaDetalle(true);
    setSelectorHabito(tipo);
    tablaDetalleRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // const handleChangeOtroRiesgo = (tipo: string) => {
  //   setSelectorOtroRiesgo(tipo);
  //   otrosRiesgosRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };
  const showDataGloborisk = (inx: number) => {
    dispatch(setRequest({
      type: 'get',
      requestFunction: getDataTablaGloborisk(
        tablaCardiovascularDetalle[inx].sexo,
        tablaCardiovascularDetalle[inx].esDiabetico,
        tablaCardiovascularDetalle[inx].consumoTabaco,
        tablaCardiovascularDetalle[inx].rangoEdad,
        true,
        idMedico,
        tablaCardiovascularDetalle[inx].idPaciente,
        idConsultorio,
      ),
      successFunction: (result: any) => {
        if (result.datos && result.datos.length > 0) {
          setInxSeleccionado(inx);
          setTableGloborisk(result.datos);
          setVerDetalle(true);
        }
      },
      errorFunction: () => setTableGloborisk([]),
    }));
  };
  const showDataTabla = (inx: number) => {
    setInxSeleccionado(inx);
    setVerDetalle(true);
  };

  useEffect(() => {
    if (verTablaDetalle) {
      if (selectorTabla === 'habitos') {
        tablaDetalleRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
      // else if (selectorTabla === 'otros_riesgos') {
      //   otrosRiesgosRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [verTablaDetalle]);

  const palette = {
    mujer: '#FF748A',
    hombre: '#4DA2EB',
    sin_datos: 'gray',
    nulo: 'lightgreen',
    bajo: 'yellow',
    intermedio: 'orange',
    alto: 'red',
    muyAlto: 'darkred',
    // nom_035_nulo: '#00B0F0',
    // nom_035_bajo: '#00B050',
    // nom_035_intermedio: '#FFFF00',
    // nom_035_alto: '#FFC000',
    // nom_035_muyAlto: '#FF0000',
  };

  const getGraficoExamen = (table: IObjTableProps) => (
    <DoughnutChart
      etiquetas={[t('estudio_expirado'), t('estudio_por_expirar'), t('sin_datos')]}
      colores={[palette.muyAlto, palette.bajo, palette.sin_datos]}
      datos={[[table.colA, table.colB, table.sin_datos]]}
      totales={[table.totalDatos]}
      valorCentro={[table.colA]}
      etiquetaCentro={t('estudio_expirado')}
    />
  );

  const construirGraficos = () => {
    switch (selectorTabla) {
      case 'riesgo_cardiovascular':
        return (
          <DoughnutChart
            tipos={['mujer', 'hombre', 'total']}
            etiquetas={[
              t('riesgo_muy_alto'),
              t('riesgo_alto'),
              t('riesgo_intermedio'),
              t('riesgo_bajo'),
              t('riesgo_nulo'),
              t('sin_datos'),
            ]}
            colores={[
              palette.muyAlto,
              palette.alto,
              palette.intermedio,
              palette.bajo,
              palette.nulo,
              palette.sin_datos,
            ]}
            datos={graficoCardiovascular.map((riesgo: IObjGraphCardiovascularProps) => [
              riesgo.riesgo_muy_alto,
              riesgo.riesgo_alto,
              riesgo.riesgo_intermedio,
              riesgo.riesgo_bajo,
              riesgo.riesgo_nulo,
              riesgo.sin_datos,
            ])}
            totales={graficoCardiovascular.map(
              (riesgo: IObjGraphCardiovascularProps) => riesgo.totalDatos,
            )}
          />
        );
      case 'audiometria':
        return getGraficoExamen(tablaAudiometrias[0]);
      case 'emp':
        return getGraficoExamen(tablaEMP[0]);
      case 'habitos':
        return (
          <DoughnutChart
            tipos={tablaHabitosRiesgo.map((habito: IObjTableHabitosProps) => habito.tipo)}
            etiquetas={[t('mujer'), t('hombre'), t('ex_consumidor')]}
            colores={[palette.mujer, palette.hombre, palette.nulo]}
            datos={tablaHabitosRiesgo.map((habito: IObjTableHabitosProps) => [
              habito.colA,
              habito.colB,
              habito.exConsumidor,
            ])}
            totales={tablaHabitosRiesgo.map((habito: IObjTableHabitosProps) => habito.totalDatos)}
            callbackDetalle={handleChangeHabito}
          />
        );
      case 'otros_riesgos':
        return (
          <DoughnutChart
            tipos={['alarma_hacinamiento']}
            etiquetas={[t('mujer'), t('hombre'), t('sin_datos')]}
            colores={[palette.mujer, palette.hombre, palette.sin_datos]}
            datos={[
              [
                tablaHacinamiento[0].colA,
                tablaHacinamiento[0].colB,
                tablaHacinamiento[0].sin_datos,
              ],
            ]}
            totales={[tablaHacinamiento[0].totalDatos]}
          />
        );
      // case 'nom_035_riesgo_general':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_riesgo_general']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[30, 15, 25, 20, 10]]}
      //       totales={[100]}
      //     />
      //   );
      // case 'nom_035_ambiente_trabajo':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_ambiente_trabajo', 'nom_035_condiciones_ambiente']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[15, 8, 40, 21, 16], [12, 18, 20, 35, 15]]}
      //       totales={[100, 100]}
      //     />
      //   );
      // case 'nom_035_factores_actividad':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_factores_actividad', 'nom_035_cargas_trabajo', 'nom_035_falta_de_control']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[6, 12, 18, 32, 32], [13, 5, 16, 46, 20], [8, 3, 17, 34, 38]]}
      //       totales={[100, 100, 100]}
      //     />
      //   );
      // case 'nom_035_tiempo_trabajo':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_organizacion_tiempo', 'nom_035_jornada_trabajo', 'nom_035_interferencia_trabajo']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[5, 15, 23, 28, 29], [18, 8, 3, 15, 56], [21, 11, 44, 4, 20]]}
      //       totales={[100, 100, 100]}
      //     />
      //   );
      // case 'nom_035_liderazgo_relaciones':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_liderazgo_relaciones_', 'nom_035_liderazgo', 'nom_035_relaciones', 'nom_035_violencia']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[16, 4, 13, 41, 26], [2, 21, 32, 14, 31], [19, 7, 35, 12, 27], [22, 3, 45, 18, 12]]}
      //       totales={[100, 100, 100, 100]}
      //     />
      //   );
      // case 'nom_035_entorno_organizacional':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_entorno_organizacional', 'nom_035_reconocimiento', 'nom_035_no_sentido_pertenencia']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[17, 8, 14, 31, 30], [2, 16, 18, 47, 17], [9, 1, 4, 28, 68]]}
      //       totales={[100, 100, 100]}
      //     />
      //   );
      default: {
        setGraphType('table');
        return <></>;
      }
    }
  };

  const formatTabla = (tabla: any, formatTotal: boolean = true) => tabla.map((row: any) => ({
    ...row,
    colA:
        row.colA > 0
          ? `${row.colA} (${parseFloat(((row.colA * 100) / row.totalDatos).toFixed(2))}%)`
          : '0',
    colB:
        row.colB > 0
          ? `${row.colB} (${parseFloat(((row.colB * 100) / row.totalDatos).toFixed(2))}%)`
          : '0',
    totalAlarma:
        row.totalAlarma > 0 && formatTotal
          ? `${row.totalAlarma} (${parseFloat(
            ((row.totalAlarma * 100) / row.totalDatos).toFixed(2),
          )}%)`
          : row.totalAlarma,
  }));

  const getTabla = () => {
    switch (selectorTabla) {
      case 'riesgo_cardiovascular':
        return (
          <Tabla columns={columnasEstandar} centered data={formatTabla(tablaCardiovascular)} sort={false} />
        );
      case 'aaa':
        return <Tabla columns={columnasEstandar} centered data={formatTabla(tablaAAA)} sort={false} />;
      case 'cancer_pulmon':
        return (
          <Tabla columns={columnasEstandar} centered data={formatTabla(tablaCancerPulmon)} sort={false} />
        );
      case 'cancer_cervical':
        return (
          <Tabla columns={columnasEstandar} centered data={formatTabla(tablaCancerCervical)} sort={false} />
        );
      case 'audiometria':
        return (
          <Tabla
            columns={columnasExamenAudiometria}
            centered
            data={formatTabla(tablaAudiometrias)}
            sort={false}
          />
        );
      case 'emp':
        return (
          <Tabla columns={columnasExamenEMP} centered data={formatTabla(tablaEMP, false)} sort={false} />
        );
      case 'habitos':
        return (
          <Tabla
            columns={columnasHabitosRiesgo}
            centered
            firstColumnIsTitle
            data={formatTabla(tablaHabitosRiesgo)}
            verDetalle={tablaHabitosRiesgo.map((habito) => (habito.totalAlarma > 0))}
            callBackVerDetalle={(tipo: string) => handleChangeHabito(tipo)}
          />
        );
      case 'otros_riesgos':
        return (
          <Tabla columns={columnasEstandarExt} centered data={formatTabla(tablaHacinamiento)} sort={false} />
        );
      default:
        return <></>;
    }
  };

  const construirTabla = (
    tipoOperacion:
    | 'medicina_preventiva'
    | 'riesgos_laborales'
    | 'habitos'
    | 'otros_riesgos'
    | 'emo',
    // | 'nom-035',
  ) => (tipoOperacion === 'habitos' ? (
    getTabla()
  ) : (
    <div className="grid grid-cols-8 pt-4 mb-2">
      <div className="col-span-2" />
      <div className="col-span-4">{getTabla()}</div>
    </div>
  ));

  const getTablaDetalle = () => {
    if (seleccionOperacion === 'habitos') {
      switch (selectorHabito) {
        case 'alarma_tabaquismo':
          return (
            <Tabla
              columns={columnasTabaquismo}
              data={tablaTabaquismoDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_cafeina_excesivo':
          return (
            <Tabla
              columns={columnasCafeina}
              data={tablaCafeinaDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_alcohol_trastorno':
          return (
            <Tabla
              columns={columnasAlcohol}
              data={tablaAlcoholTrastornoDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_alcohol_intensivo':
          return (
            <Tabla
              columns={columnasAlcohol}
              data={tablaAlcoholIntensivoDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_otras_drogas':
          return (
            <Tabla
              columns={columnasOtrasDrogas}
              data={tablaOtrasDrogasDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_actividad_fisica':
          return (
            <Tabla
              columns={columnasActividadFisica}
              data={tablaActividadFisicaDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_refrescos':
          return (
            <Tabla
              columns={columnasRefrescos}
              data={tablaRefrescosDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        default:
          return <></>;
      }
    } else if (seleccionOperacion === 'otros_riesgos') {
      return (
        <Tabla
          columns={columnasHacinamiento}
          data={tablaHacinamientoDetalle}
          callBackVerDetalle={(_d, index) => showDataTabla(index)}
        />
      );
    } else {
      switch (selectorTabla) {
        case 'riesgo_cardiovascular':
          return (
            <Tabla
              columns={columnasRiesgoSalud}
              data={tablaCardiovascularDetalle}
              callBackVerDetalle={(_d, index) => showDataGloborisk(index)}
            />
          );
        case 'aaa':
          return (
            <Tabla
              columns={columnasRiesgoSalud}
              data={tablaAAADetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'cancer_pulmon':
          return (
            <Tabla
              columns={columnasRiesgoSalud}
              data={tablaCancerPulmonDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'cancer_cervical':
          return (
            <Tabla
              columns={columnasRiesgoSalud}
              data={tablaCancerCervicoDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'audiometria':
          return (
            <Tabla
              columns={columnasAudiometrias}
              data={tablaAudiometriasDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'emp':
          return (
            <Tabla
              columns={columnasEMP}
              data={tablaEMPDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        default:
          return <></>;
      }
    }
  };

  const getDetalleTabla = () => {
    switch (selectorTabla) {
      case 'riesgo_cardiovascular':
        return (
          <PrevencionCardiovascularDiv
            presion={tablaCardiovascularDetalle[inxSeleccionado].rangoPa || 0}
            colesterol={tablaCardiovascularDetalle[inxSeleccionado].rangoColesterol || 0}
            valor={tablaCardiovascularDetalle[inxSeleccionado].valor || ''}
            sexo={tablaCardiovascularDetalle[inxSeleccionado].sexo ? 'M' : 'H'}
            toxi={tablaCardiovascularDetalle[inxSeleccionado].consumoTabaco ? 'activo' : 'falso'}
            esDiabetico={tablaCardiovascularDetalle[inxSeleccionado].esDiabetico || 0}
            colesterolResultado={tablaCardiovascularDetalle[inxSeleccionado].valorColesterol || 0}
            fechaColesterol={tablaCardiovascularDetalle[inxSeleccionado].fecha || ''}
            tableGloborisk={tableGloborisk}
            edad={tablaCardiovascularDetalle[inxSeleccionado].edad || 0}
          />
        );
      case 'aaa':
        return (
          <AneurismaAortaAbdominal txtToxicomania={tablaAAADetalle[inxSeleccionado].toxicomania} />
        );
      case 'cancer_pulmon':
        return (
          <DeteccionTempranaCancer
            selIdPaciente={tablaCancerPulmonDetalle[inxSeleccionado].idPaciente || 0}
            selEdadPaciente={tablaCancerPulmonDetalle[inxSeleccionado].edad || 0}
          />
        );
      case 'cancer_cervical':
        return (
          <DeteccionCancerCervicalDiv
            edad={tablaCancerCervicoDetalle[inxSeleccionado].edad}
            sendFecha={tablaCancerCervicoDetalle[inxSeleccionado].fecha}
            sendShowCervicoUterino
          />
        );
      case 'audiometria':
        return (
          <PrevencionAuditiva
            nivel={tablaAudiometriasDetalle[inxSeleccionado].nivel}
            fechaEstudio={tablaAudiometriasDetalle[inxSeleccionado].estudio.fechaEstudio}
          />
        );
      case 'emp':
        return (
          <ExamenMedicoPeriodico
            fechaEstudio={tablaEMPDetalle[inxSeleccionado].estudio.fechaEstudio}
          />
        );
      default:
        return <></>;
    }
  };

  const getDetalle = () => (
    <>
      <div className="py-4">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          onClick={() => setVerDetalle(false)}
          startIcon={<ArrowBackIcon />}
        >
          {t('regresar')}
        </Button>
      </div>
      {getDetalleTabla()}
    </>
  );

  const getChartTableButton = (tipo: 'chart' | 'table') => (
    <Button
      className={graphType === tipo ? classes.buttonGraphSelected : classes.buttonGraphRoot}
      startIcon={tipo === 'chart' ? <BarChartIcon /> : <TableChartIcon />}
      onClick={() => setGraphType(tipo)}
    />
  );

  const getListaCategorias = (categorias: { tipo: 'header' | 'item'; titulo: string }[]) => (
    <FormControl variant="outlined" fullWidth>
      <Select
        displayEmpty
        labelId="tipo"
        id="tipo"
        value={selectorTabla}
        onChange={handleChangeSelector}
      >
        <MenuItem value="" disabled>
          <span className="text-gray-300">{t('selecciona')}</span>
        </MenuItem>
        {categorias.map((categoria) => (categoria.tipo === 'header' ? (
          <ListSubheader>{t(categoria.titulo)}</ListSubheader>
        ) : (
          <MenuItem value={categoria.titulo}>{t(categoria.titulo)}</MenuItem>
        )))}
      </Select>
    </FormControl>
  );

  const conAlarmas = (selectorTabla === 'riesgo_cardiovascular' && tablaCardiovascularDetalle.length > 0)
    || (selectorTabla === 'aaa' && tablaAAADetalle.length > 0)
    || (selectorTabla === 'cancer_pulmon' && tablaCancerPulmonDetalle.length > 0)
    || (selectorTabla === 'cancer_cervical' && tablaCancerCervicoDetalle.length > 0)
    || (selectorTabla === 'habitos' && tablaHabitosRiesgo.length > 0)
    || (selectorTabla === 'otros_riesgos' && tablaHacinamientoDetalle.length > 0)
    || (selectorTabla === 'audiometria' && tablaAudiometriasDetalle.length > 0)
    || (selectorTabla === 'emp' && tablaEMPDetalle.length > 0);
    // || selectorTabla === 'nom_035_riesgo_general'
    // || selectorTabla === 'nom_035_ambiente_trabajo'
    // || selectorTabla === 'nom_035_factores_actividad'
    // || selectorTabla === 'nom_035_tiempo_trabajo'
    // || selectorTabla === 'nom_035_liderazgo_relaciones'
    // || selectorTabla === 'nom_035_entorno_organizacional';

  const operaciones: {
    tipo: 'medicina_preventiva' | 'riesgos_laborales' | 'habitos' | 'otros_riesgos' | 'emo'; // | 'nom-035';
    tabla:
    | 'riesgo_cardiovascular'
    | 'aaa'
    | 'cancer_pulmon'
    | 'cancer_cervical'
    | 'audiometria'
    | 'emp'
    | 'habitos'
    | 'otros_riesgos';
    // | 'nom_035_riesgo_general'
    // | 'nom_035_ambiente_trabajo'
    // | 'nom_035_factores_actividad'
    // | 'nom_035_tiempo_trabajo'
    // | 'nom_035_liderazgo_relaciones'
    // | 'nom_035_entorno_organizacional';
    titulo: string;
    categorias?: { tipo: 'header' | 'item'; titulo: string }[];
  }[] = [
    {
      tipo: 'medicina_preventiva',
      tabla: 'riesgo_cardiovascular',
      titulo: 'medicina_preventiva',
      categorias: [
        { tipo: 'header', titulo: 'cardiovascular' },
        { tipo: 'item', titulo: 'riesgo_cardiovascular' },
        { tipo: 'item', titulo: 'aaa' },
        { tipo: 'header', titulo: 'cancer' },
        { tipo: 'item', titulo: 'cancer_pulmon' },
        { tipo: 'item', titulo: 'cancer_cervical' },
      ],
    },
    { tipo: 'habitos', tabla: 'habitos', titulo: 'business_habitos_riesgo' },
    // { tipo: 'riegos_laborales', tabla: 'riegos_laborales', titulo: 'business_riesgos_laborales' },
    { tipo: 'otros_riesgos', tabla: 'otros_riesgos', titulo: 'business_otros_riesgos' },
    {
      tipo: 'emo',
      tabla: 'audiometria',
      titulo: 'business_emo',
      categorias: [
        { tipo: 'item', titulo: 'audiometria' },
        { tipo: 'item', titulo: 'emp' },
      ],
    },
    // {
    //   tipo: 'nom-035',
    //   tabla: 'nom_035_riesgo_general',
    //   titulo: 'nom_035',
    //   categorias: [
    //     { tipo: 'item', titulo: 'nom_035_riesgo_general' },
    //     { tipo: 'item', titulo: 'nom_035_ambiente_trabajo' },
    //     { tipo: 'item', titulo: 'nom_035_factores_actividad' },
    //     { tipo: 'item', titulo: 'nom_035_tiempo_trabajo' },
    //     { tipo: 'item', titulo: 'nom_035_liderazgo_relaciones' },
    //     { tipo: 'item', titulo: 'nom_035_entorno_organizacional' },
    //   ],
    // },
  ];

  return (
    <>
      <div className="mt-4 mb-4 bg-blue-200 container mx-auto flex content-center items-center p-4">
        <h3 className="text-blue-500 font-medium m-0 pr-4">{`${t('selecciona')}:`}</h3>
        {operaciones.map((operacion) => (
          <div className="pr-4">
            <Button
              variant="outlined"
              color="primary"
              className={
                seleccionOperacion === operacion.tipo
                  ? classes.operacionSelected
                  : classes.operacionButtonColor
              }
              onClick={() => {
                setSeleccionOperacion(operacion.tipo);
                setSelectorTabla(operacion.tabla);
                setVerTablaDetalle(operacion.tipo !== 'habitos');
              }}
            >
              {t(operacion.titulo)}
            </Button>
          </div>
        ))}
      </div>
      {operaciones.map((operacion) => (seleccionOperacion === operacion.tipo ? (
        <>
          <h1 className="text-blue-800 font-normal m-0 text-center">
            {['habitos', 'otros_riesgos'].includes(operacion.tipo)
              ? t(operacion.titulo)
              : t(selectorTabla)}
          </h1>
          <div className="flex flex-row justify-between items-center w-full py-4">
            <div className="flex flex-row justify-between items-center w-1/3">
              {!verDetalle && conAlarmas && (
              <div>
                <h3 className="m-0">{`${t('business_plantilla')}: ${plantilla}`}</h3>
                <div className="flex flex-row">
                  {getChartTableButton('chart')}
                  {getChartTableButton('table')}
                </div>
              </div>
              )}
            </div>
            {operacion.categorias && (
            <div className="flex flex-row justify-end items-center w-2/3">
              <div className="pr-2">
                <h3 className="text-gray-500 font-normal m-0 text-left pr-2">
                  {`${t('categoria')}:`}
                </h3>
              </div>
              <div className="pr-2 w-1/3">{getListaCategorias(operacion.categorias)}</div>
            </div>
            )}
          </div>
          {conAlarmas && verDetalle && getDetalle()}
          {conAlarmas && !verDetalle && (
          <>
            {graphType === 'chart' ? (
              <div className="pb-4 text-right">{construirGraficos()}</div>
            ) : (
              construirTabla(operacion.tipo)
            )}
            {selectorTabla === 'riesgo_cardiovascular' && (
            <div className="text-center">
              <span className="text-gray-600 font-normal text-lg">
                {`*${t('business_calculado_usando_globorisk')}`}
              </span>
            </div>
            )}
            {verTablaDetalle && (
            <div ref={tablaDetalleRef} className="pt-4">
              {operacion.tipo === 'habitos' && (
              <h2 className="text-blue-800 font-normal text-center my-2">
                {t(selectorHabito)}
              </h2>
              )}
              {operacion.tipo === 'otros_riesgos' && (
              <h2 className="text-blue-800 font-normal text-center my-2">
                {t('alarma_hacinamiento')}
              </h2>
              )}
              {rol.grupo === 'salud' ? getTablaDetalle() : null}
            </div>
            )}
          </>
          )}
          {!conAlarmas && (
          <div className="pt-4 text-center">
            <h3>{t('sin_alarmas')}</h3>
          </div>
          )}
        </>
      ) : null))}
      {/* {arrayCumple.length > 0 && (
        <div className="py-4">
          <div className="container mx-auto shadow-lg border border-solid border-gray-200 p-4">
            <h1 className="text-blue-800 font-normal m-0 text-center">{t('cumple')}</h1>
            <div className="flex flex-row items-center justify-center">
              <Box sx={{ flexGrow: 1, maxWidth: 900, bgcolor: 'background.paper' }}>
                <Tabs
                  variant="scrollable"
                  scrollButtons="on"
                  aria-label="icon label tabs example"
                >
                  {arrayCumple.map((valCumple) => (
                    <Tab
                      icon={(<Avatar style={{ backgroundColor: '#1D4ED8' }} alt={valCumple.iniciales}>{valCumple.iniciales}</Avatar>)}
                      label={(
                        <div className="m-0 p-0">
                          <p className="m-0 p-0">{valCumple.nombre}</p>
                          <div className="flex flex-row items-center justify-center">
                            <PhoneIcon style={{ color: '#1D4ED8' }} />
                            <p className="m-0 p-0">{valCumple.telefono}</p>
                          </div>
                          <p className="m-0 p-0">{`${valCumple.edad} ${t('anios_m')}`}</p>
                        </div>
                      )}
                    />
                  ))}
                </Tabs>
              </Box>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Operaciones;
