import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Warning } from '@material-ui/icons';
import dayjs from 'dayjs';
import { RootState } from '../../store';
import {
  getPacienteDatosUsuario,
  getDatosUsuarioPaciente,
  getDatosPaciente,
  getPacienteDatosPersonales,
} from '../../utils/getCatalogos';
import { edadCalculada } from '../../common/functions';
/** Importacion de estilos */
import useStyles from './styles';
/** Importaion de componentes de las tabs */
import PerfilPaciente from './PerfilPaciente';
import MCPA from './MCPA/index';
import Antecedentes from './Antecedentes/index';
import AparatosYSistemas from './AparatosYSistemas/index';
import ExploracionFisica from './ExploracionFisica/index';
import DiagnosticoPlan from './DiagnosticoPlan/index';
import Incapacidades from './Incapacidades';
import {
  getCambiosDatosPersonales,
  getCambiosDatosUbicacion,
  getCambiosDiscapacidades,
  getCambiosInfoEmergencia,
  getCambiosSegurosPlanes,
  getCambiosVivienda,
  getCambiosHigiene,
  getCambiosAlimentacion,
  getCambiosToxicomania,
  getCambiosGinecoObstetrico,
  getCambiosActividadFisica,
} from './functionsCambios';
import {
  StyledButton,
  StyledButtonGroup,
  StyledFirstButton,
  StyledLastButton,
} from './buttonsStyles';
import { setSnackComplete } from '../../actions/snackbar/types';
import { setLoading } from '../../actions/loading/actions';
import { ICatalogosEstandar, IEdad, edadInitial } from '../../common/types';
import {
  getPaciente,
  setPacienteID,
  setPacienteIdUsuario,
  setPacienteTipoEmpleado,
} from '../../actions/basicosPaciente/actions';
import { setRequest } from '../../actions/request/types';
import ValidarUsuarioCorreo from '../../components/ModalDialogs/ValidarUsuarioCorreo';
import { validarEmailUsuario, guardarTipoEmpleado } from '../../utils/sendInfo';
import { IDatosPersonales, datosPersonalesInitial } from './PerfilPaciente/DatosPersonales/types';
import {
  IDatosUbicacion,
  IDatosUbicacionNombres,
  datosUbicacionInitial,
  datosUbicacionNombresInitial,
} from './PerfilPaciente/DatosDeUbicacion/types';
import { IDiscapacidades, discapacidadesInitial } from './PerfilPaciente/Discapacidades/types';
import { IInfoEmergencia, infoEmergenciaInitial } from './PerfilPaciente/InfoEmergencia/types';
import { ISegurosPlanes, segurosPlanesInitial } from './PerfilPaciente/SegurosPlanes/types';
import { IMCPACompleto, MCPACompletoInitial } from './MCPA/types';
import { IInmunizacion } from './Antecedentes/PersonalesNoPatologicos/Inmunizaciones/types';
import {
  IEnfermedad,
  IEnfermedadNegada,
  IGrupoEnfermedadesNegadas,
  grupoEnfermedadesNegadasInitial,
} from './Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';
import {
  IDiagnosticoGeneral,
  IDiagnosticosGenerales,
  diagnosticosGeneralesInitial,
} from './Antecedentes/PersonalesPatologicos/DiagnosticosGenerales/types';
import { IListaRecetas, IMedicamento } from './DiagnosticoPlan/Plan/TerapiaFarmacologica/types';
import { IHospitalizacion } from './Antecedentes/PersonalesPatologicos/Hospitalizacion/types';
import { IAlergia } from './Antecedentes/PersonalesPatologicos/Alergias/types';
import { ITransfusion } from './Antecedentes/PersonalesPatologicos/Transfusiones/types';
import { IVivienda, viviendaInitial } from './Antecedentes/PersonalesNoPatologicos/Vivienda/types';
import { IHigiene, higieneInitial } from './Antecedentes/PersonalesNoPatologicos/Higiene/types';
import {
  IAlimentacion,
  alimentacionInitial,
} from './Antecedentes/PersonalesNoPatologicos/Alimentacion/types';
import {
  IActividadFisica,
  actividadFisicaInitial,
} from './Antecedentes/PersonalesNoPatologicos/ActividadFisica/types';
import {
  IToxicomania,
  toxicomaniaInitial,
} from './Antecedentes/PersonalesNoPatologicos/Toxicomania/types';
import {
  IEnGestacionUsuario,
  IGinecoObstetrico,
  ginecoObstetricoInitial,
} from './Antecedentes/PersonalesNoPatologicos/GinecoObstetricos/types';
import {
  IHistoriaSexual,
  historiaSexualInitial,
} from './Antecedentes/PersonalesNoPatologicos/HistoriaSexual/types';
import { IProcedimiento } from './Antecedentes/PersonalesPatologicos/Procedimiento/types';
import { ISolicitudProcedimientos } from './DiagnosticoPlan/Plan/Procedimientos/types';
import { IFamiliar } from './Antecedentes/Familiares/types';
import {
  IAntecedenteLaboral,
  IObjArrayAccidentesLaborales,
  IObjLaboral,
  laboralInitial,
} from './Antecedentes/Laborales/types';
import { IAparatosYSistemas, aparatosYSistemasInitial } from './AparatosYSistemas/types';
import { IExploracionFisica, exploracionFisicaInitial } from './ExploracionFisica/types';
import {
  ILaboratorioSolicitud,
  laboratorioSolicitudInitial,
} from './DiagnosticoPlan/Plan/Laboratorio/types';
import { IPronostico } from './DiagnosticoPlan/Plan/Pronostico/types';
import { IDatosIncapacidad } from './Incapacidades/types';
import SeleccionarTipoPaciente from '../../components/ModalDialogs/SelecionarTipoPaciente';

dayjs.locale('es');

function TabsPaciente() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { secciones, enOrganizacion } = useSelector((state: RootState) => state.Me);
  const {
    idPaciente, idUsuario, sexo, tipoEmpleado,
  } = useSelector(
    (state: RootState) => state.BasicosPaciente,
  );
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
    pais,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idConsulta, idPaciente: idPacienteConsulta } = useSelector(
    (state: RootState) => state.Consulta,
  );
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const [validacionPendiente, setValidacionPendiente] = useState<boolean>(true);
  const [showModalValidacionCorreo, setShowModalValidacionCorreo] = useState<boolean>(false);
  const [errorCorreoInvalido, setErrorCorreoInvalido] = useState<boolean>(false);
  const [showModalTipoPaciente, setShowModalTipoPaciente] = useState<boolean>(false);
  const [tipoPaciente, setTipoPaciente] = useState<
  null | 'directo' | 'candidato' | 'contratista' | 'otro'
  >(null);
  const [emailPaciente, setEmailPaciente] = useState<string>('');
  const [activeStep, setActiveStep] = useState<
  | 'perfil'
  | 'mcpa'
  | 'antecedentes'
  | 'aparatos-y-sistemas'
  | 'exploracion-fisica'
  | 'diagnostico-y-plan'
  | 'incapacidades'
  >('perfil');

  // Datos del expediente
  const [datosPersonales, setDatosPersonales] = useState<IDatosPersonales>(datosPersonalesInitial);
  const [edad, setEdad] = useState<IEdad>(edadInitial);
  const [datosUbicacion, setDatosUbicacion] = useState<IDatosUbicacion>(datosUbicacionInitial);
  const [discapacidades, setDiscapacidades] = useState<IDiscapacidades>(discapacidadesInitial);
  const [infoEmergencia, setInfoEmergencia] = useState<IInfoEmergencia>(infoEmergenciaInitial);
  const [segurosPlanes, setSegurosPlanes] = useState<ISegurosPlanes>(segurosPlanesInitial);
  const [MCPACompleto, setMCPACompleto] = useState<IMCPACompleto>(MCPACompletoInitial);
  const [enfReferidas, setEnfReferidas] = useState<IEnfermedad[]>([]);
  const [enfNegadas, setEnfNegadas] = useState<IEnfermedadNegada[]>([]);
  const [grupoEnfNegadas, setGrupoEnfNegadas] = useState<IGrupoEnfermedadesNegadas>(
    grupoEnfermedadesNegadasInitial,
  );
  const [catDiagnostico, setCatDiagnostico] = useState<ICatalogosEstandar[]>([]);
  const [diagnosticosGenerales, setDiagnosticosGenerales] = useState<IDiagnosticosGenerales>(
    diagnosticosGeneralesInitial,
  );
  const [tratamientos, setTratamientos] = useState<IMedicamento[]>([]);
  const [indiceReceta, setIndiceReceta] = useState<number>(0);
  const [recetas, setRecetas] = useState<IListaRecetas[]>([{ idBD: 0, actualizado: true }]);
  const [sinHospitalizaciones, setSinHospitalizaciones] = useState<boolean>(false);
  const [hospitalizaciones, setHospitalizaciones] = useState<IHospitalizacion[]>([]);
  const [sinAlergias, setSinAlergias] = useState<boolean>(false);
  const [alergias, setAlergias] = useState<IAlergia[]>([]);
  const [sinTransfusiones, setSinTransfusiones] = useState<boolean>(false);
  const [transfusiones, setTransfusiones] = useState<ITransfusion[]>([]);
  const [enGestacion, setEnGestacion] = useState<'' | 'si' | 'no'>('');
  const [vivienda, setVivienda] = useState<IVivienda>(viviendaInitial);
  const [higiene, setHigiene] = useState<IHigiene>(higieneInitial);
  const [alimentacion, setAlimentacion] = useState<IAlimentacion>(alimentacionInitial);
  const [actividadFisica, setActividadFisica] = useState<IActividadFisica>(actividadFisicaInitial);
  const [inmunizaciones, setInmunizaciones] = useState<IInmunizacion[]>([]);
  const [toxicomania, setToxicomania] = useState<IToxicomania>(toxicomaniaInitial);
  const [ginecoObstetrico, setGinecoObstetrico] = useState<IGinecoObstetrico>(ginecoObstetricoInitial);
  const [historiaSexual, setHistoriaSexual] = useState<IHistoriaSexual>(historiaSexualInitial);
  const [sinProcQuirurgicos, setSinProcQuirurgicos] = useState<boolean>(false);
  const [procDiagnosticos, setProcDiagnosticos] = useState<IProcedimiento[]>([]);
  const [procTerapeuticos, setProcTerapeuticos] = useState<IProcedimiento[]>([]);
  const [procQuirurgicos, setProcQuirurgicos] = useState<IProcedimiento[]>([]);
  const [solProcDiagnosticos, setSolProcDiagnosticos] = useState<ISolicitudProcedimientos[]>([]);
  const [solProcTerapeuticos, setSolProcTerapeuticos] = useState<ISolicitudProcedimientos[]>([]);
  const [solProcQuirurgicos, setSolProcQuirurgicos] = useState<ISolicitudProcedimientos[]>([]);
  const [familiares, setFamiliares] = useState<IFamiliar[]>([]);
  const [objLaboral, setObjLaboral] = useState<IObjLaboral>(laboralInitial);
  const [antecedentesLaborales, setAntecedentesLaborales] = useState<IAntecedenteLaboral[]>([]);
  const [accidentesLaborales, setAccidentesLaborales] = useState<ICatalogosEstandar[]>([]);
  const [aparatosYSistemas, setAparatosYSistemas] = useState<IAparatosYSistemas>(aparatosYSistemasInitial);
  const [exploracionFisica, setExploracionFisica] = useState<IExploracionFisica>(exploracionFisicaInitial);
  const [laboratorioSolicitud, setLaboratorioSolicitud] = useState<ILaboratorioSolicitud[]>([
    laboratorioSolicitudInitial,
  ]);
  const [pronosticos, setPronosticos] = useState<IPronostico[]>([]);
  const [incapacidades, setIncapacidades] = useState<IDatosIncapacidad[]>([]);

  // Datos del usuario del paciente
  const [datosPersonalesUsuario, setDatosPersonalesUsuario] = useState<IDatosPersonales>(datosPersonalesInitial);
  const [datosUbicacionUsuario, setDatosUbicacionUsuario] = useState<IDatosUbicacion>(datosUbicacionInitial);
  const [datosUbicacionNombresUsuario, setDatosUbicacionNombresUsuario] = useState<IDatosUbicacionNombres>(datosUbicacionNombresInitial);
  const [discapacidadesUsuario, setDiscapacidadesUsuario] = useState<IDiscapacidades>(discapacidadesInitial);
  const [infoEmergenciaUsuario, setInfoEmergenciaUsuario] = useState<IInfoEmergencia>(infoEmergenciaInitial);
  const [segurosPlanesUsuario, setSegurosPlanesUsuario] = useState<ISegurosPlanes>(segurosPlanesInitial);
  const [viviendaUsuario, setViviendaUsuario] = useState<IVivienda>(viviendaInitial);
  const [higieneUsuario, setHigieneUsuario] = useState<IHigiene>(higieneInitial);
  const [alimentacionUsuario, setAlimentacionUsuario] = useState<IAlimentacion>(alimentacionInitial);
  const [actividadFisicaUsuario, setActividadFisicaUsuario] = useState<IActividadFisica>(actividadFisicaInitial);
  const [toxicomaniaUsuario, setToxicomaniaUsuario] = useState<IToxicomania>(toxicomaniaInitial);
  const [ginecoObstetricoUsuario, setGinecoObstetricoUsuario] = useState<IGinecoObstetrico>(ginecoObstetricoInitial);
  const [enGestacionUsuario, setEnGestacionUsuario] = useState<IEnGestacionUsuario>({
    loaded: false,
    enGestacion: '',
  });

  // Cambios entre los datos del expediente y los datos del usuario del paciente
  const [cambiosDatosPersonales, setCambiosDatosPersonales] = useState<string[]>([]);
  const [cambiosDatosUbicacion, setCambiosDatosUbicacion] = useState<string[]>([]);
  const [cambiosDiscapacidades, setCambiosDiscapacidades] = useState<string[]>([]);
  const [cambiosInfoEmergencia, setCambiosInfoEmergencia] = useState<string[]>([]);
  const [cambiosSegurosPlanes, setCambiosSegurosPlanes] = useState<string[]>([]);
  const [cambiosVivienda, setCambiosVivienda] = useState<string[]>([]);
  const [cambiosHigiene, setCambiosHigiene] = useState<string[]>([]);
  const [cambiosAlimentacion, setCambiosAlimentacion] = useState<string[]>([]);
  const [cambiosActividadFisica, setCambiosActividadFisica] = useState<string[]>([]);
  const [cambiosToxicomania, setCambiosToxicomania] = useState<string[]>([]);
  const [cambiosGinecoObstetrico, setCambiosGinecoObstetrico] = useState<string[]>([]);

  const obtenerFecha = (dia: string, mes: string, anio: string) => {
    const formatString = dia.length === 0 ? 'MMM-YYYY' : 'DD-MMM-YYYY';
    return dayjs(`${anio}-${mes}-${dia.length === 0 ? '1' : dia}`).format(formatString);
  };

  const llenarDatosPaciente = (datos: any) => {
    let accidentesLab: ICatalogosEstandar[] = [];
    setDatosPersonales({ ...datos.perfil.datosPersonales, loaded: true });
    if (datos.perfil.datosUbicacion) {
      setDatosUbicacion({ ...datos.perfil.datosUbicacion, loaded: true });
    }
    if (datos.perfil.discapacidades) {
      setDiscapacidades({ ...datos.perfil.discapacidades, loaded: true });
    }
    if (datos.perfil.infoEmergencia) {
      setInfoEmergencia({ ...datos.perfil.infoEmergencia, loaded: true });
    }
    if (datos.perfil.segurosPlanes) {
      setSegurosPlanes({ ...datos.perfil.segurosPlanes, loaded: true });
    }
    if (datos.motivoConsulta) {
      setMCPACompleto(datos.motivoConsulta);
    }
    if (datos.antPatologicos) {
      if (datos.antPatologicos.enfReferidas && datos.antPatologicos.enfReferidas.length > 0) {
        setEnfReferidas(datos.antPatologicos.enfReferidas);
        setCatDiagnostico(
          datos.antPatologicos.enfReferidas.map((enf: IEnfermedad) => ({
            id: enf.idBD,
            label:
              enf.seleccionDiagnostico === 'cie10'
                ? enf.diagnosticoCie10.label
                : enf.diagnosticoMedico,
          })),
        );
      }
      if (datos.antPatologicos.enfNegadas && datos.antPatologicos.enfNegadas.length > 0) {
        setEnfNegadas(datos.antPatologicos.enfNegadas);
      }
      if (datos.antPatologicos.grupoEnfNegadas) {
        setGrupoEnfNegadas(datos.antPatologicos.grupoEnfNegadas);
      }
      if (datos.antPatologicos.diagnosticosGenerales) {
        setDiagnosticosGenerales({ ...datos.antPatologicos.diagnosticosGenerales, loaded: true });
      }
      if (datos.antPatologicos.tratamientos && datos.antPatologicos.tratamientos.length > 0) {
        setTratamientos(datos.antPatologicos.tratamientos);
        const idsRecetas: number[] = [];
        datos.antPatologicos.tratamientos.forEach((tratamiento: IMedicamento) => {
          if (tratamiento.actual && tratamiento.idConsulta === idConsulta && tratamiento.idReceta > 0 && !idsRecetas.includes(tratamiento.idReceta)) {
            idsRecetas.push(tratamiento.idReceta);
          }
        });
        if (idsRecetas.length) {
          setRecetas(idsRecetas.sort().map((r: number) => ({ idBD: r, actualizado: false })));
        }
      }
      if (datos.antPatologicos.sin_hospitalizaciones) {
        setSinHospitalizaciones(datos.antPatologicos.sin_hospitalizaciones);
      } else if (datos.antPatologicos.hospitalizaciones) {
        setHospitalizaciones(datos.antPatologicos.hospitalizaciones);
      }
      if (
        datos.antPatologicos.procDiagnosticos
        && datos.antPatologicos.procDiagnosticos.length > 0
      ) {
        setProcDiagnosticos(datos.antPatologicos.procDiagnosticos);
      }
      if (
        datos.antPatologicos.procTerapeuticos
        && datos.antPatologicos.procTerapeuticos.length > 0
      ) {
        setProcTerapeuticos(datos.antPatologicos.procTerapeuticos);
      }
      if (datos.antPatologicos.sinProcQuirurgicos) {
        setSinProcQuirurgicos(datos.antPatologicos.sinProcQuirurgicos);
      } else if (
        datos.antPatologicos.procQuirurgicos
        && datos.antPatologicos.procQuirurgicos.length > 0
      ) {
        setProcQuirurgicos(datos.antPatologicos.procQuirurgicos);
      }
      if (datos.antPatologicos.sinAlergias) {
        setSinAlergias(datos.antPatologicos.sinAlergias);
      } else if (datos.antPatologicos.alergias) {
        setAlergias(datos.antPatologicos.alergias);
      }
      if (datos.antPatologicos.sinTransfusiones) {
        setSinTransfusiones(datos.antPatologicos.sinTransfusiones);
      } else if (datos.antPatologicos.transfusiones) {
        setTransfusiones(datos.antPatologicos.transfusiones);
      }
    }
    if (datos.antNoPatologicos) {
      setEnGestacion(datos.antNoPatologicos.enGestacion || '');
      if (datos.antNoPatologicos.vivienda) {
        setVivienda({ ...datos.antNoPatologicos.vivienda, loaded: true });
      }
      if (datos.antNoPatologicos.higiene) {
        setHigiene({ ...datos.antNoPatologicos.higiene, loaded: true });
      }
      if (datos.antNoPatologicos.alimentacion) {
        setAlimentacion({ ...datos.antNoPatologicos.alimentacion, loaded: true });
      }
      if (datos.antNoPatologicos.actividadFisica) {
        setActividadFisica({ ...datos.antNoPatologicos.actividadFisica, loaded: true });
      }
      if (datos.antNoPatologicos.inmunizaciones) {
        setInmunizaciones(datos.antNoPatologicos.inmunizaciones);
      }
      if (datos.antNoPatologicos.toxicomania) {
        setToxicomania({ ...datos.antNoPatologicos.toxicomania, loaded: true });
      }
      if (datos.antNoPatologicos.ginecoObstetrico) {
        if (datos.datosUsuario && datos.datosUsuario.antNoPatologicos && datos.datosUsuario.antNoPatologicos.ginecoObstetrico) {
          setGinecoObstetrico({
            ...datos.antNoPatologicos.ginecoObstetrico,
            citologiaRadio: datos.datosUsuario.antNoPatologicos.ginecoObstetrico.citologiaRadio,
            mastografia: datos.datosUsuario.antNoPatologicos.ginecoObstetrico.mastografia,
            ultrasonido: datos.datosUsuario.antNoPatologicos.ginecoObstetrico.ultrasonido,
            loaded: true,
          });
        } else {
          setGinecoObstetrico({ ...datos.antNoPatologicos.ginecoObstetrico, loaded: true });
        }
      }
      if (datos.antNoPatologicos.historiaSexual) {
        setHistoriaSexual({ ...datos.antNoPatologicos.historiaSexual, loaded: true });
      }
    }
    if (datos.antecedentesFamiliares && datos.antecedentesFamiliares.length > 0) {
      setFamiliares(datos.antecedentesFamiliares);
    }
    if (datos.sinAntecedentesLaborales) {
      setObjLaboral({ ...objLaboral, ...datos.sinAntecedentesLaborales });
    }
    if (datos.antecedentesLaborales && datos.antecedentesLaborales.length > 0) {
      setAntecedentesLaborales(datos.antecedentesLaborales);
      accidentesLab = datos.antecedentesLaborales.reduce(
        (accidentes: ICatalogosEstandar[], antLab: IAntecedenteLaboral) => [
          ...accidentes,
          ...antLab.objLaboral.arrayAccidentesLaborales.map((accidente: IObjArrayAccidentesLaborales) => ({
            id: accidente.id,
            label: `${
              obtenerFecha(accidente.fechaDia, accidente.fechaMes, accidente.fechaAnio)
            } - ${
              accidente.radioCausaAccidente === 'catalogo'
                ? accidente.tipoCausaAccidente.label
                : accidente.txtCausaAccidente
            }`,
          })),
        ], [],
      );
      setAccidentesLaborales(accidentesLab);
    }
    if (datos.aparatosYSistemas) {
      setAparatosYSistemas(datos.aparatosYSistemas);
    }
    if (datos.exploracionFisica) {
      setExploracionFisica({ ...datos.exploracionFisica, loaded: true });
    }
    if (datos.plan) {
      if (datos.plan.solProcDiagnosticos && datos.plan.solProcDiagnosticos.length > 0) {
        setSolProcDiagnosticos(datos.plan.solProcDiagnosticos);
      }
      if (datos.plan.solProcTerapeuticos && datos.plan.solProcTerapeuticos.length > 0) {
        setSolProcTerapeuticos(datos.plan.solProcTerapeuticos);
      }
      if (datos.plan.solProcQuirurgicos && datos.plan.solProcQuirurgicos.length > 0) {
        setSolProcQuirurgicos(datos.plan.solProcQuirurgicos);
      }
      if (datos.plan.laboratorioSolicitud && datos.plan.laboratorioSolicitud.length > 0) {
        setLaboratorioSolicitud(datos.plan.laboratorioSolicitud);
      }
      if (datos.plan.pronosticos && datos.plan.pronosticos.length > 0) {
        setPronosticos(datos.plan.pronosticos);
      }
    }
    if (datos.incapacidades) {
      setIncapacidades(datos.incapacidades.map((incapacidad: IDatosIncapacidad) => ({
        ...incapacidad,
        accidenteLaboral: accidentesLab.find((accidente: ICatalogosEstandar) => accidente.id === incapacidad.accidenteLaboral.id),
      })));
    }
  };

  const llenarCambiosUsuarioPaciente = (datos: any) => {
    setDatosPersonalesUsuario({
      ...datos.perfil.datosPersonales,
      comoEntero: datos.perfil.datosPersonales.comoEntero,
      otroComoEntero: datos.perfil.datosPersonales.otroComoEntero,
      loaded: true,
    });
    setDatosUbicacionNombresUsuario(datos.perfil.datosUbicacionNombres);
    setDatosUbicacionUsuario({
      ...datosUbicacionUsuario,
      ...datos.perfil.datosUbicacion,
      loaded: true,
    });
    setDiscapacidadesUsuario({
      ...discapacidadesUsuario,
      ...datos.perfil.discapacidades,
      loaded: true,
    });
    setInfoEmergenciaUsuario({
      ...infoEmergenciaUsuario,
      ...datos.perfil.infoEmergencia,
      loaded: true,
    });
    setSegurosPlanesUsuario({
      ...segurosPlanesUsuario,
      ...datos.perfil.segurosPlanes,
      loaded: true,
    });
    setDiagnosticosGenerales({ ...datos.antPatologicos.diagnosticosGenerales, loaded: true });
    setViviendaUsuario({ ...viviendaUsuario, ...datos.antNoPatologicos.vivienda, loaded: true });
    setHigieneUsuario({ ...higieneUsuario, ...datos.antNoPatologicos.higiene, loaded: true });
    setAlimentacionUsuario({
      ...alimentacionUsuario,
      ...datos.antNoPatologicos.alimentacion,
      loaded: true,
    });
    setActividadFisicaUsuario({
      ...actividadFisicaUsuario,
      ...datos.antNoPatologicos.actividadFisica,
      loaded: true,
    });
    setToxicomaniaUsuario({
      ...toxicomaniaUsuario,
      ...datos.antNoPatologicos.toxicomania,
      loaded: true,
    });
    setEnGestacionUsuario({ loaded: true, enGestacion: datos.antNoPatologicos.enGestacion || '' });
    setGinecoObstetricoUsuario({
      ...ginecoObstetricoUsuario,
      ...datos.antNoPatologicos.ginecoObstetrico,
      loaded: true,
    });
  };

  const handleValidarUsuarioCorreo = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: validarEmailUsuario({
          idMedico,
          idPaciente,
          idConsultorio,
          emailPaciente,
        }),
        successFunction: (result: any) => {
          dispatch(setPacienteIdUsuario(result.data));
          setShowModalValidacionCorreo(false);
          getPacienteDatosUsuario(idMedico, idPaciente, result.data, idConsultorio)
            .then((response) => response.json())
            .then((result2) => {
              if (result2.code === 200) {
                llenarCambiosUsuarioPaciente(result2.data);
              }
            });
          setValidacionPendiente(false);
        },
        errorFunction: (result: any) => {
          if (result.code === 201) {
            setErrorCorreoInvalido(true);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('error')} ${result.msg}`,
              }),
            );
          }
        },
        showMsgError: false,
      }),
    );
  };

  const handleSeleccionarTipoPaciente = () => {
    if (idPaciente > 0) {
      if (tipoPaciente) {
        dispatch(
          setRequest({
            type: 'send',
            requestFunction: guardarTipoEmpleado({
              idMedico,
              idPaciente,
              idConsultorio,
              idOrganizacion,
              tipoEmpleado: tipoPaciente,
            }),
            successFunction: () => {
              setShowModalTipoPaciente(false);
              dispatch(setPacienteTipoEmpleado(tipoPaciente));
            },
          }),
        );
      }
    } else {
      setShowModalTipoPaciente(false);
    }
  };

  const obtenerDatosUsuarioPaciente = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getDatosUsuarioPaciente(idMiUsuario),
        successFunction: (result: any) => llenarDatosPaciente(result.data),
      }),
    );
  };

  const obtenerDatosExpedientePaciente = () => {
    dispatch(
      setRequest({
        type: 'get',
        requestFunction: getDatosPaciente(idMedico, idPaciente, idConsultorio, idConsulta),
        successFunction: (result: any) => {
          llenarDatosPaciente(result.data);
          if (result.data.datosUsuario) {
            llenarCambiosUsuarioPaciente(result.data.datosUsuario);
          }
          if (result.data.usuariosPorValidar) {
            setShowModalValidacionCorreo(true);
          } else {
            setValidacionPendiente(false);
          }
        },
        errorFunction: () => {
          // Error 300: Si el paciente no tiene snapshot (nunca ha tenido una consulta)
          // se consultan los datos del expediente directamente de las tablas
          // Por alguna razón no funciona con "setRequest" (al parecer no funcionan si están anidados)
          dispatch(setLoading(true));
          getPacienteDatosPersonales(idPaciente, idMedico, idConsultorio)
            .then((response) => response.json())
            .then((result) => {
              if (result.code === 200) {
                setDatosPersonales({ ...result.data.datosPersonales, loaded: true });
                setInmunizaciones(result.data.inmunizaciones);
                if (result.data.datosUsuario) {
                  llenarCambiosUsuarioPaciente(result.data.datosUsuario);
                }
                if (result.data.usuariosPorValidar) {
                  setShowModalValidacionCorreo(true);
                } else {
                  setValidacionPendiente(false);
                }
              }
              dispatch(setLoading(false));
            })
            .catch(() => {
              dispatch(setLoading(false));
            });
        },
        showMsgError: false,
      }),
    );
  };

  useEffect(() => {
    // Esta acción se ejecuta al hacer clic en el botón de volver a la consulta, pero se valida aquí por si se accede a través de la URL
    if (!esPaciente && idPacienteConsulta > 0 && idPaciente !== idPacienteConsulta) {
      dispatch(setPacienteID(idPacienteConsulta));
      dispatch(getPaciente(idPacienteConsulta, idMedico, idOrganizacion, idConsultorio));
    }
  }, [esPaciente, idPaciente, idPacienteConsulta]);

  useEffect(() => {
    if (!validacionPendiente && enOrganizacion && (!tipoEmpleado || tipoEmpleado === 'externo')) {
      setShowModalTipoPaciente(true);
    }
  }, [validacionPendiente]);

  useEffect(() => {
    if (tipoEmpleado.length) {
      setShowModalTipoPaciente(false);
    }
  }, [tipoEmpleado]);

  useEffect(() => {
    if (idMedico) {
      if (esPaciente) {
        // Si el usuario es el paciente se consultan sus datos de usuario
        obtenerDatosUsuarioPaciente();
      } else if (idPaciente > 0 && idConsultorio > 0 && idConsulta > 0 && !datosPersonales.loaded) {
        // Se consultan los datos del expediente y los datos del usuario del paciente
        obtenerDatosExpedientePaciente();
      } else {
        setValidacionPendiente(false);
      }
    }
  }, [idMedico, idPaciente, idConsultorio, idConsulta]);

  useEffect(() => {
    if (
      datosPersonales.fechaNac.dia
      && datosPersonales.fechaNac.mes
      && datosPersonales.fechaNac.anio
    ) {
      setEdad(
        edadCalculada(
          datosPersonales.fechaNac.anio,
          datosPersonales.fechaNac.mes,
          datosPersonales.fechaNac.dia,
        ),
      );
    }
  }, [datosPersonales.fechaNac.anio, datosPersonales.fechaNac.mes, datosPersonales.fechaNac.dia]);

  // Cambios en los datos del usuario del paciente
  useEffect(() => {
    if (idUsuario > 0 && datosPersonalesUsuario.loaded) {
      setCambiosDatosPersonales(
        getCambiosDatosPersonales(datosPersonales, datosPersonalesUsuario, t),
      );
    }
  }, [idUsuario, datosPersonales, datosPersonalesUsuario]);
  useEffect(() => {
    if (idUsuario > 0 && datosUbicacionUsuario.loaded) {
      setCambiosDatosUbicacion(
        getCambiosDatosUbicacion(
          datosUbicacion,
          datosUbicacionUsuario,
          datosUbicacionNombresUsuario,
          pais,
          t,
        ),
      );
    }
  }, [idUsuario, datosUbicacion, datosUbicacionUsuario, datosUbicacionNombresUsuario]);
  useEffect(() => {
    if (idUsuario > 0 && discapacidadesUsuario.loaded) {
      setCambiosDiscapacidades(getCambiosDiscapacidades(discapacidades, discapacidadesUsuario, t));
    }
  }, [idUsuario, discapacidades, discapacidadesUsuario]);
  useEffect(() => {
    if (idUsuario > 0 && infoEmergenciaUsuario.loaded) {
      setCambiosInfoEmergencia(getCambiosInfoEmergencia(infoEmergencia, infoEmergenciaUsuario, t));
    }
  }, [idUsuario, infoEmergencia, infoEmergenciaUsuario]);
  useEffect(() => {
    if (idUsuario > 0 && segurosPlanesUsuario.loaded) {
      setCambiosSegurosPlanes(getCambiosSegurosPlanes(segurosPlanes, segurosPlanesUsuario, t));
    }
  }, [idUsuario, segurosPlanes, segurosPlanesUsuario]);
  useEffect(() => {
    if (idUsuario > 0 && viviendaUsuario.loaded) {
      setCambiosVivienda(getCambiosVivienda(vivienda, viviendaUsuario, t));
    }
  }, [idUsuario, vivienda, viviendaUsuario]);
  useEffect(() => {
    if (idUsuario > 0 && higieneUsuario.loaded) {
      setCambiosHigiene(getCambiosHigiene(higiene, higieneUsuario, t));
    }
  }, [idUsuario, higiene, higieneUsuario]);
  useEffect(() => {
    if (idUsuario > 0 && alimentacionUsuario.loaded) {
      setCambiosAlimentacion(getCambiosAlimentacion(alimentacion, alimentacionUsuario, t));
    }
  }, [idUsuario, alimentacion, alimentacionUsuario]);
  useEffect(() => {
    if (idUsuario > 0 && actividadFisicaUsuario.loaded) {
      setCambiosActividadFisica(
        getCambiosActividadFisica(actividadFisica, actividadFisicaUsuario, t),
      );
    }
  }, [idUsuario, actividadFisica, actividadFisicaUsuario]);
  useEffect(() => {
    if (idUsuario > 0 && toxicomaniaUsuario.loaded) {
      setCambiosToxicomania(getCambiosToxicomania(toxicomania, toxicomaniaUsuario, t));
    }
  }, [idUsuario, toxicomania, toxicomaniaUsuario]);
  useEffect(() => {
    if (idUsuario > 0 && ginecoObstetricoUsuario.loaded && enGestacionUsuario.loaded) {
      setCambiosGinecoObstetrico(
        getCambiosGinecoObstetrico(
          ginecoObstetrico,
          ginecoObstetricoUsuario,
          enGestacion,
          enGestacionUsuario.enGestacion,
          t,
        ),
      );
    }
  }, [idUsuario, ginecoObstetrico, ginecoObstetricoUsuario, enGestacion, enGestacionUsuario]);

  return (
    <>
      <ValidarUsuarioCorreo
        open={showModalValidacionCorreo}
        email={emailPaciente}
        setEmail={setEmailPaciente}
        emailError={errorCorreoInvalido}
        setEmailError={setErrorCorreoInvalido}
        callBackAceptar={handleValidarUsuarioCorreo}
        callBackClose={() => {
          setShowModalValidacionCorreo(false);
          setValidacionPendiente(false);
        }}
      />
      <SeleccionarTipoPaciente
        open={showModalTipoPaciente}
        esExterno={tipoEmpleado === 'externo'}
        tipoPaciente={tipoPaciente}
        setTipoPaciente={setTipoPaciente}
        callBackAceptar={handleSeleccionarTipoPaciente}
        callBackClose={() => setShowModalTipoPaciente(false)}
      />
      <div className="w-full rounded-lg my-4">
        <StyledButtonGroup className="mb-4" variant="contained" color="primary" disableElevation>
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion >= 7 && valor.idSeccion <= 11) && (
              <StyledFirstButton
                classes={activeStep === 'perfil' ? { root: classes.buttonSelected } : {}}
                onClick={() => setActiveStep('perfil')}
                startIcon={
                  !esPaciente
                  && idUsuario > 0
                  && (cambiosDatosPersonales.filter((c: string) => c.length > 0).length > 0
                    || cambiosDatosUbicacion.filter((c: string) => c.length > 0).length > 0
                    || cambiosDiscapacidades.filter((c: string) => c.length > 0).length > 0
                    || cambiosInfoEmergencia.filter((c: string) => c.length > 0).length > 0
                    || cambiosSegurosPlanes.filter((c: string) => c.length > 0).length > 0) ? (
                      <Warning color="secondary" fontSize="small" />
                    ) : null
                }
              >
                {t('perfil')}
              </StyledFirstButton>
          )}
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion >= 12 && valor.idSeccion <= 13) && (
              <StyledButton
                disabled={idPaciente <= 0}
                classes={activeStep === 'mcpa' ? { root: classes.buttonSelected } : {}}
                onClick={() => setActiveStep('mcpa')}
              >
                {t('mc/pa')}
              </StyledButton>
          )}
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion >= 14 && valor.idSeccion <= 32) && (
              <StyledButton
                disabled={idPaciente <= 0}
                classes={activeStep === 'antecedentes' ? { root: classes.buttonSelected } : {}}
                onClick={() => setActiveStep('antecedentes')}
                startIcon={
                  !esPaciente
                  && idUsuario > 0
                  && (diagnosticosGenerales.contenidoTabla?.some(
                    (diagnostico: IDiagnosticoGeneral) => diagnostico.posee === 'si' && !diagnostico.validado,
                  )
                    || cambiosVivienda.filter((c: string) => c.length > 0).length > 0
                    || cambiosHigiene.filter((c: string) => c.length > 0).length > 0
                    || cambiosAlimentacion.filter((c: string) => c.length > 0).length > 0
                    || cambiosActividadFisica.filter((c: string) => c.length > 0).length > 0
                    || cambiosToxicomania.filter((c: string) => c.length > 0).length > 0
                    || cambiosGinecoObstetrico.filter((c: string) => c.length > 0).length > 0
                    || (sexo === 'M'
                      && enGestacionUsuario.loaded
                      && enGestacion !== enGestacionUsuario.enGestacion)) ? (
                        <Warning color="secondary" fontSize="small" />
                    ) : null
                }
              >
                {t('antecedentes')}
              </StyledButton>
          )}
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion >= 33 && valor.idSeccion <= 45) && (
              <StyledButton
                disabled={idPaciente <= 0}
                classes={
                  activeStep === 'aparatos-y-sistemas' ? { root: classes.buttonSelected } : {}
                }
                onClick={() => setActiveStep('aparatos-y-sistemas')}
              >
                {t('aparatos_y_sistemas')}
              </StyledButton>
          )}
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 46) && (
            <StyledButton
              disabled={idPaciente <= 0}
              classes={activeStep === 'exploracion-fisica' ? { root: classes.buttonSelected } : {}}
              onClick={() => setActiveStep('exploracion-fisica')}
            >
              {t(esPaciente ? 'mediciones' : 'exploracion_fisica')}
            </StyledButton>
          )}
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion >= 47 && valor.idSeccion <= 58) && (
              <StyledButton
                disabled={idPaciente <= 0}
                classes={
                  activeStep === 'diagnostico-y-plan' ? { root: classes.buttonSelected } : {}
                }
                onClick={() => setActiveStep('diagnostico-y-plan')}
              >
                {t(esPaciente ? 'medicina_preventiva' : 'diagnostico_y_plan')}
              </StyledButton>
          )}
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 80) && (
              <StyledLastButton
                disabled={idPaciente <= 0}
                classes={
                  activeStep === 'incapacidades' ? { root: classes.buttonSelected } : {}
                }
                onClick={() => setActiveStep('incapacidades')}
              >
                Incapacidades
              </StyledLastButton>
          )}
        </StyledButtonGroup>
        {activeStep === 'perfil' && (
          <PerfilPaciente
            setActiveStep={setActiveStep}
            idPaciente={idPaciente}
            datosPersonales={datosPersonales}
            setDatosPersonales={setDatosPersonales}
            setEnGestacion={setEnGestacion}
            setInmunizaciones={setInmunizaciones}
            tipoPaciente={tipoPaciente}
            datosUbicacion={datosUbicacion}
            setDatosUbicacion={setDatosUbicacion}
            discapacidades={discapacidades}
            setDiscapacidades={setDiscapacidades}
            infoEmergencia={infoEmergencia}
            setInfoEmergencia={setInfoEmergencia}
            segurosPlanes={segurosPlanes}
            setSegurosPlanes={setSegurosPlanes}
            cambiosDatosPersonales={cambiosDatosPersonales}
            cambiosDatosUbicacion={cambiosDatosUbicacion}
            cambiosDiscapacidades={cambiosDiscapacidades}
            cambiosInfoEmergencia={cambiosInfoEmergencia}
            cambiosSegurosPlanes={cambiosSegurosPlanes}
            datosPersonalesUsuario={datosPersonalesUsuario}
            setDatosPersonalesUsuario={setDatosPersonalesUsuario}
            datosUbicacionUsuario={datosUbicacionUsuario}
            setDatosUbicacionUsuario={setDatosUbicacionUsuario}
            discapacidadesUsuario={discapacidadesUsuario}
            setDiscapacidadesUsuario={setDiscapacidadesUsuario}
            infoEmergenciaUsuario={infoEmergenciaUsuario}
            setInfoEmergenciaUsuario={setInfoEmergenciaUsuario}
            segurosPlanesUsuario={segurosPlanesUsuario}
            setSegurosPlanesUsuario={setSegurosPlanesUsuario}
          />
        )}
        {idPaciente > 0 && (
          <>
            {activeStep === 'mcpa' && (
              <MCPA MCPACompleto={MCPACompleto} setMCPACompleto={setMCPACompleto} />
            )}
            {activeStep === 'antecedentes' && (
              <Antecedentes
                setActiveStep={setActiveStep}
                enfReferidas={enfReferidas}
                setEnfReferidas={setEnfReferidas}
                catDiagnostico={catDiagnostico}
                setCatDiagnostico={setCatDiagnostico}
                enfNegadas={enfNegadas}
                setEnfNegadas={setEnfNegadas}
                grupoEnfNegadas={grupoEnfNegadas}
                setGrupoEnfNegadas={setGrupoEnfNegadas}
                diagnosticosGenerales={diagnosticosGenerales}
                setDiagnosticosGenerales={setDiagnosticosGenerales}
                tratamientos={tratamientos}
                setTratamientos={setTratamientos}
                sinHospitalizaciones={sinHospitalizaciones}
                setSinHospitalizaciones={setSinHospitalizaciones}
                hospitalizaciones={hospitalizaciones}
                setHospitalizaciones={setHospitalizaciones}
                procDiagnosticos={procDiagnosticos}
                setProcDiagnosticos={setProcDiagnosticos}
                procTerapeuticos={procTerapeuticos}
                setProcTerapeuticos={setProcTerapeuticos}
                sinProcQuirurgicos={sinProcQuirurgicos}
                setSinProcQuirurgicos={setSinProcQuirurgicos}
                procQuirurgicos={procQuirurgicos}
                setProcQuirurgicos={setProcQuirurgicos}
                sinAlergias={sinAlergias}
                setSinAlergias={setSinAlergias}
                alergias={alergias}
                setAlergias={setAlergias}
                sinTransfusiones={sinTransfusiones}
                setSinTransfusiones={setSinTransfusiones}
                transfusiones={transfusiones}
                setTransfusiones={setTransfusiones}
                enGestacion={enGestacion}
                setEnGestacion={setEnGestacion}
                vivienda={vivienda}
                setVivienda={setVivienda}
                higiene={higiene}
                setHigiene={setHigiene}
                alimentacion={alimentacion}
                setAlimentacion={setAlimentacion}
                actividadFisica={actividadFisica}
                setActividadFisica={setActividadFisica}
                inmunizaciones={inmunizaciones}
                setInmunizaciones={setInmunizaciones}
                toxicomania={toxicomania}
                setToxicomania={setToxicomania}
                ginecoObstetrico={ginecoObstetrico}
                setGinecoObstetrico={setGinecoObstetrico}
                historiaSexual={historiaSexual}
                setHistoriaSexual={setHistoriaSexual}
                familiares={familiares}
                setFamiliares={setFamiliares}
                objLaboral={objLaboral}
                setObjLaboral={setObjLaboral}
                antecedentesLaborales={antecedentesLaborales}
                setAntecedentesLaborales={setAntecedentesLaborales}
                cambiosVivienda={cambiosVivienda}
                cambiosHigiene={cambiosHigiene}
                cambiosAlimentacion={cambiosAlimentacion}
                cambiosActividadFisica={cambiosActividadFisica}
                cambiosToxicomania={cambiosToxicomania}
                cambiosGinecoObstetrico={cambiosGinecoObstetrico}
                viviendaUsuario={viviendaUsuario}
                setViviendaUsuario={setViviendaUsuario}
                higieneUsuario={higieneUsuario}
                setHigieneUsuario={setHigieneUsuario}
                alimentacionUsuario={alimentacionUsuario}
                setAlimentacionUsuario={setAlimentacionUsuario}
                actividadFisicaUsuario={actividadFisicaUsuario}
                setActividadFisicaUsuario={setActividadFisicaUsuario}
                toxicomaniaUsuario={toxicomaniaUsuario}
                setToxicomaniaUsuario={setToxicomaniaUsuario}
                enGestacionUsuario={enGestacionUsuario}
                setEnGestacionUsuario={setEnGestacionUsuario}
                ginecoObstetricoUsuario={ginecoObstetricoUsuario}
                setGinecoObstetricoUsuario={setGinecoObstetricoUsuario}
              />
            )}
            {activeStep === 'aparatos-y-sistemas' && (
              <AparatosYSistemas
                aparatosYSistemas={aparatosYSistemas}
                setAparatosYSistemas={setAparatosYSistemas}
              />
            )}
            {activeStep === 'exploracion-fisica' && (
              <ExploracionFisica
                setActiveStep={setActiveStep}
                exploracionFisica={exploracionFisica}
                setExploracionFisica={setExploracionFisica}
                edad={edad}
              />
            )}
            {activeStep === 'diagnostico-y-plan' && (
              <DiagnosticoPlan
                setActiveStep={setActiveStep}
                laboratorioSolicitud={laboratorioSolicitud}
                setLaboratorioSolicitud={setLaboratorioSolicitud}
                enfReferidas={enfReferidas}
                setEnfReferidas={setEnfReferidas}
                enfNegadas={enfNegadas}
                setEnfNegadas={setEnfNegadas}
                grupoEnfNegadas={grupoEnfNegadas}
                setGrupoEnfNegadas={setGrupoEnfNegadas}
                solProcDiagnosticos={solProcDiagnosticos}
                setSolProcDiagnosticos={setSolProcDiagnosticos}
                solProcTerapeuticos={solProcTerapeuticos}
                setSolProcTerapeuticos={setSolProcTerapeuticos}
                solProcQuirurgicos={solProcQuirurgicos}
                setSolProcQuirurgicos={setSolProcQuirurgicos}
                procDiagnosticos={procDiagnosticos}
                setProcDiagnosticos={setProcDiagnosticos}
                procTerapeuticos={procTerapeuticos}
                setProcTerapeuticos={setProcTerapeuticos}
                sinProcQuirurgicos={sinProcQuirurgicos}
                setSinProcQuirurgicos={setSinProcQuirurgicos}
                procQuirurgicos={procQuirurgicos}
                setProcQuirurgicos={setProcQuirurgicos}
                catDiagnostico={catDiagnostico}
                tratamientosActualesConsulta={tratamientos.filter((m) => m.actual === true && m.idConsulta === idConsulta)}
                setTratamientos={setTratamientos}
                indiceReceta={indiceReceta}
                setIndiceReceta={setIndiceReceta}
                recetas={recetas}
                setRecetas={setRecetas}
                exploracionFisica={exploracionFisica}
                enGestacion={enGestacion}
                setEnGestacion={setEnGestacion}
                inmunizaciones={inmunizaciones}
                setInmunizaciones={setInmunizaciones}
                pronosticos={pronosticos}
                setPronosticos={setPronosticos}
              />
            )}
            {activeStep === 'incapacidades' && (
              <Incapacidades
                listaIncapacidades={incapacidades}
                setListaIncapacidades={setIncapacidades}
                accidentesLaborales={accidentesLaborales}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default TabsPaciente;
