import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import { IRecetaProps, IRenderMedicamentoProps } from './types';
import { RootState } from '../../../../../../store';

const RenderMedicamento = ({ medicamento }: IRenderMedicamentoProps) => {
  const { t } = useTranslation();
  const {
    tipo,
    nombreDistintivo,
    nombreGenerico,
    medTextoLibre,
    via,
    cantidad,
    unidad,
    frecuencia,
    otraFrecuencia,
    duracion,
    periodo,
    instruccionesAdicionales,
  } = medicamento;

  const titulo = () => {
    let jsx;
    switch (tipo) {
      case 'nombre_generico':
        jsx = <h3 className="font-normal m-0 text-gray-900">{nombreGenerico.label}</h3>;
        break;
      case 'denominacion_distintiva':
        jsx = (
          <>
            <h3 className="font-normal m-0 text-gray-900">{nombreDistintivo.label}</h3>
            {nombreGenerico.id > 0 && (
              <h4 className="font-normal m-0 text-gray-500">{nombreGenerico.label}</h4>
            )}
          </>
        );
        break;
      case 'texto_libre':
        jsx = <h3 className="font-normal m-0 text-gray-900">{medTextoLibre}</h3>;
        break;
      default:
        jsx = <h3 className="font-normal m-0 text-gray-900">Sin información</h3>;
        break;
    }
    return jsx;
  };

  const descripcion = () => (
    <p className="text-gray-900 m-0">
      <span className="text-gray-900">{via.impresion}</span>
      {` ${cantidad} ${unidad.label} ${frecuencia.label || otraFrecuencia} ${t(
        'farma_por',
      )} ${duracion} ${periodo.label}`}
    </p>
  );

  return (
    <div className="mb-4 inline-block w-full">
      <div className="pr-9">{titulo()}</div>
      {descripcion()}
      {instruccionesAdicionales && (
        <p className="text-gray-900 m-0">
          {t('instrucciones_adicionales')}
          :
          <span className="text-gray-900">{` ${instruccionesAdicionales}`}</span>
        </p>
      )}
    </div>
  );
};

const Receta = React.forwardRef(
  ({ listaMedicamentos, folio }: IRecetaProps, ref: React.Ref<HTMLDivElement>) => {
    const { nombre, edad } = useSelector((state: RootState) => state.BasicosPaciente);
    return (
      <div ref={ref}>
        <Header
          nombrePaciente={nombre}
          edadPaciente={edad}
          folio={folio.toString().padStart(12, '0')}
        />
        <div className="m-6">
          {listaMedicamentos.map((med) => (
            <RenderMedicamento medicamento={med} key={med.id} />
          ))}
        </div>
        <Footer consulta />
      </div>
    );
  },
);

export default Receta;
