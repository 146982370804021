import { getPerfilPacienteNotasURL } from './commonUrl';
import { getToken } from './commonStore';

export default async function getPerfilNotas(idMedico: number, idPaciente: number, idConsultorio: number) {
  const token = getToken();
  const response = await fetch(getPerfilPacienteNotasURL(idMedico, idPaciente, idConsultorio), {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.status === 200) {
    const data = await response.json();
    if (data.code === 200) {
      return data.datos;
    }
    return {};
  }
  return {};
}
