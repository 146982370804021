import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { RootState } from '../../../store';
import { ReactComponent as GeneralIcon } from '../../../assets/icons/general.svg';
import { ReactComponent as CabezaOidosBocaGargantaIcon } from '../../../assets/icons/cabeza_oidos_boca_garganta.svg';
import { ReactComponent as CardiovascularIcon } from '../../../assets/icons/cardiovascular.svg';
import { ReactComponent as RespiratorioIcon } from '../../../assets/icons/respiratorio.svg';
import { ReactComponent as GastrointestinalIcon } from '../../../assets/icons/gastrointestinal.svg';
import { ReactComponent as GenitourinarioRenalIcon } from '../../../assets/icons/genitourinario_renal.svg';
import { ReactComponent as HematicoLinfaticoIcon } from '../../../assets/icons/hematico_linfatico.svg';
import { ReactComponent as EndocrinoIcon } from '../../../assets/icons/endocrino.svg';
import { ReactComponent as NeurologicoIcon } from '../../../assets/icons/neurologico.svg';
import { ReactComponent as MusculoEsqueleticoIcon } from '../../../assets/icons/musculo_esqueletico.svg';
import { ReactComponent as PielYAnexosIcon } from '../../../assets/icons/piel_y_anexos.svg';
import { ReactComponent as ReproductivoMujerIcon } from '../../../assets/icons/reproductivo_mujer.svg';
import { ReactComponent as ReproductivoHombreIcon } from '../../../assets/icons/reproductivo_hombre.svg';
import { ReactComponent as EsferaPsiquica } from '../../../assets/icons/esfera_psiquica.svg';
import {
  arrGeneral,
  arrCabezaOidosBocaGarganta,
  arrCardiovascular,
  arrRespiratorio,
  arrGenitourinarioRenal,
  arrHematicoLinfatico,
  arrEndocrino,
  arrGastrointestinal,
  arrNeurologico,
  arrMusculoEsqueletico,
  arrPielYAnexos,
  arrReproductivoMujer,
  arrReproductivoHombre,
  arrEsferaPsiquica,
} from './arrVariables';
import useStyles from './styles';
import ConstructorTabla from './ConstructorTabla';
import { sendAparatosYSistemas } from '../../../utils/sendInfo';
import {
  aparatosYSistemasInitial, IAparatosYSistemasProps, IBotonAYS, ISintomaAYS,
} from './types';
import { setRequest } from '../../../actions/request/types';

function AparatosYSistemas(props: IAparatosYSistemasProps) {
  const { aparatosYSistemas, setAparatosYSistemas } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idPaciente, sexo } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { secciones } = useSelector((state: RootState) => state.Me);
  const classes = useStyles();
  const [arrSintomas, setArrSintomas] = useState<string[]>([]);
  const [titulo, setTitulo] = useState<string>('');
  const [arrResultados, setArrResultados] = useState<ISintomaAYS[]>([]);
  const [activeSubStep, setActiveSubStep] = useState<
  | 'general'
  | 'cabeza_oidos_boca_garganta'
  | 'cardiovascular'
  | 'respiratorio'
  | 'gastrointestinal'
  | 'genitourinario_renal'
  | 'hematico_linfatico'
  | 'endocrino'
  | 'neurologico'
  | 'musculo_esqueletico'
  | 'piel_y_anexos'
  | 'reproductivo_mujer'
  | 'reproductivo_hombre'
  | 'esfera_psiquica'
  >('general');

  useEffect(() => {
    setTitulo(activeSubStep);
    switch (activeSubStep) {
      case 'general':
        setArrSintomas(arrGeneral);
        setArrResultados(aparatosYSistemas.general || aparatosYSistemasInitial.general);
        break;
      case 'cabeza_oidos_boca_garganta':
        setArrSintomas(arrCabezaOidosBocaGarganta);
        setArrResultados(
          aparatosYSistemas.cabezaOidosBoca || aparatosYSistemasInitial.cabezaOidosBoca,
        );
        break;
      case 'cardiovascular':
        setArrSintomas(arrCardiovascular);
        setArrResultados(
          aparatosYSistemas.cardiovascular || aparatosYSistemasInitial.cardiovascular,
        );
        break;
      case 'respiratorio':
        setArrSintomas(arrRespiratorio);
        setArrResultados(aparatosYSistemas.respiratorio || aparatosYSistemasInitial.respiratorio);
        break;
      case 'gastrointestinal':
        setArrSintomas(arrGastrointestinal);
        setArrResultados(
          aparatosYSistemas.gastrointestinal || aparatosYSistemasInitial.gastrointestinal,
        );
        break;
      case 'genitourinario_renal':
        setArrSintomas(arrGenitourinarioRenal);
        setArrResultados(
          aparatosYSistemas.genitourinarioRenal || aparatosYSistemasInitial.genitourinarioRenal,
        );
        break;
      case 'hematico_linfatico':
        setArrSintomas(arrHematicoLinfatico);
        setArrResultados(
          aparatosYSistemas.hematicoLinfatico || aparatosYSistemasInitial.hematicoLinfatico,
        );
        break;
      case 'endocrino':
        setArrSintomas(arrEndocrino);
        setArrResultados(aparatosYSistemas.endocrino || aparatosYSistemasInitial.endocrino);
        break;
      case 'neurologico':
        setArrSintomas(arrNeurologico);
        setArrResultados(aparatosYSistemas.neurologico || aparatosYSistemasInitial.neurologico);
        break;
      case 'musculo_esqueletico':
        setArrSintomas(arrMusculoEsqueletico);
        setArrResultados(
          aparatosYSistemas.musculoEsqueletico || aparatosYSistemasInitial.musculoEsqueletico,
        );
        break;
      case 'piel_y_anexos':
        setArrSintomas(arrPielYAnexos);
        setArrResultados(aparatosYSistemas.pielAnexos || aparatosYSistemasInitial.pielAnexos);
        break;
      case 'reproductivo_mujer':
        setArrSintomas(arrReproductivoMujer);
        setArrResultados(
          aparatosYSistemas.reproductivoMujer || aparatosYSistemasInitial.reproductivoMujer,
        );
        break;
      case 'reproductivo_hombre':
        setArrSintomas(arrReproductivoHombre);
        setArrResultados(
          aparatosYSistemas.reproductivoHombre || aparatosYSistemasInitial.reproductivoHombre,
        );
        break;
      case 'esfera_psiquica':
        setArrSintomas(arrEsferaPsiquica);
        setArrResultados(
          aparatosYSistemas.esferaPsiquica || aparatosYSistemasInitial.esferaPsiquica,
        );
        break;
      default:
        break;
    }
  }, [activeSubStep]);

  const handleSubmitForm = () => {
    dispatch(
      setRequest({
        type: 'send',
        requestFunction: sendAparatosYSistemas({
          idConsulta,
          idPaciente,
          idMedico,
          idConsultorio,
          tabla: titulo,
          arrGuardar: arrResultados,
        }),
        successFunction: () => {
          switch (activeSubStep) {
            case 'general':
              setAparatosYSistemas({ ...aparatosYSistemas, general: arrResultados });
              break;
            case 'cabeza_oidos_boca_garganta':
              setAparatosYSistemas({ ...aparatosYSistemas, cabezaOidosBoca: arrResultados });
              break;
            case 'cardiovascular':
              setAparatosYSistemas({ ...aparatosYSistemas, cardiovascular: arrResultados });
              break;
            case 'respiratorio':
              setAparatosYSistemas({ ...aparatosYSistemas, respiratorio: arrResultados });
              break;
            case 'gastrointestinal':
              setAparatosYSistemas({ ...aparatosYSistemas, gastrointestinal: arrResultados });
              break;
            case 'genitourinario_renal':
              setAparatosYSistemas({ ...aparatosYSistemas, genitourinarioRenal: arrResultados });
              break;
            case 'hematico_linfatico':
              setAparatosYSistemas({ ...aparatosYSistemas, hematicoLinfatico: arrResultados });
              break;
            case 'endocrino':
              setAparatosYSistemas({ ...aparatosYSistemas, endocrino: arrResultados });
              break;
            case 'neurologico':
              setAparatosYSistemas({ ...aparatosYSistemas, neurologico: arrResultados });
              break;
            case 'musculo_esqueletico':
              setAparatosYSistemas({ ...aparatosYSistemas, musculoEsqueletico: arrResultados });
              break;
            case 'piel_y_anexos':
              setAparatosYSistemas({ ...aparatosYSistemas, pielAnexos: arrResultados });
              break;
            case 'reproductivo_mujer':
              setAparatosYSistemas({ ...aparatosYSistemas, reproductivoMujer: arrResultados });
              break;
            case 'reproductivo_hombre':
              setAparatosYSistemas({ ...aparatosYSistemas, reproductivoHombre: arrResultados });
              break;
            case 'esfera_psiquica':
              setAparatosYSistemas({ ...aparatosYSistemas, esferaPsiquica: arrResultados });
              break;
            default:
              break;
          }
        },
      }),
    );
  };

  const botones: IBotonAYS[] = [
    { seccion: 33, titulo: 'general', icono: <GeneralIcon /> },
    { seccion: 34, titulo: 'cabeza_oidos_boca_garganta', icono: <CabezaOidosBocaGargantaIcon /> },
    { seccion: 35, titulo: 'cardiovascular', icono: <CardiovascularIcon /> },
    { seccion: 36, titulo: 'respiratorio', icono: <RespiratorioIcon /> },
    { seccion: 37, titulo: 'gastrointestinal', icono: <GastrointestinalIcon /> },
    { seccion: 38, titulo: 'genitourinario_renal', icono: <GenitourinarioRenalIcon /> },
    { seccion: 39, titulo: 'hematico_linfatico', icono: <HematicoLinfaticoIcon /> },
    { seccion: 40, titulo: 'endocrino', icono: <EndocrinoIcon /> },
    { seccion: 41, titulo: 'neurologico', icono: <NeurologicoIcon /> },
    { seccion: 42, titulo: 'musculo_esqueletico', icono: <MusculoEsqueleticoIcon /> },
    { seccion: 43, titulo: 'piel_y_anexos', icono: <PielYAnexosIcon /> },
    {
      seccion: 44,
      titulo: 'reproductivo_mujer',
      icono: <ReproductivoMujerIcon />,
      sexo: 'M',
    },
    {
      seccion: 44,
      titulo: 'reproductivo_hombre',
      icono: <ReproductivoHombreIcon />,
      sexo: 'H',
    },
    { seccion: 45, titulo: 'esfera_psiquica', icono: <EsferaPsiquica /> },
  ];

  return (
    <>
      <div>
        <div className="p-4 bg-white container mx-auto shadow">
          <div className="relative">
            <div className="text-center">
              <h1 className="text-blue-800 font-normal m-2 text-center">
                {t('selecciona_por_aparatos_y_sistemas')}
              </h1>
            </div>
            {/* TODO: boton de atras dejado a proposito, creo que tiene funcion o no sé
            <div className="absolute top-0">
              <Button variant="contained" startIcon={<ArrowBackIcon />}>
                {t('atras')}
              </Button>
            </div> */}
          </div>
          <div className="mt-6 grid grid-cols-3 xl:grid-cols-4 gap-3 border-solid border-0 border-b border-gray-600 pb-6">
            {botones.map(
              (boton: IBotonAYS) => secciones.length > 0
                && secciones.find((valor) => valor.idSeccion === boton.seccion)
                && (!boton.sexo || boton.sexo === sexo) && (
                  <Button
                    className={
                      activeSubStep === boton.titulo ? classes.selectedButton : classes.button
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    startIcon={boton.icono}
                    fullWidth
                    onClick={() => setActiveSubStep(boton.titulo)}
                  >
                    {t(boton.titulo)}
                  </Button>
              ),
            )}
          </div>
          <div>
            {!!arrSintomas.length && <h2 className="text-blue-400 font-light">{t(titulo)}</h2>}
            {!!arrSintomas.length && (
              <ConstructorTabla
                arrSintomas={arrSintomas}
                arrResultados={arrResultados}
                setArrResultados={setArrResultados}
                titulo={titulo}
              />
            )}
          </div>
          {!!arrSintomas.length && (
            <div className="text-right mt-4">
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={() => {
                  handleSubmitForm();
                }}
              >
                {t('guardar')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AparatosYSistemas;
