/* eslint-disable max-len */
const APIDIR = process.env.REACT_APP_APIDIR;
export function getToken() {
  return sessionStorage.getItem('token') || '';
}
export const NotificacionesURL = () => `${APIDIR}/api/notificaciones`;
/* Catalogos */
export const AlergiasURL = () => `${APIDIR}/api/catalogos/antecedentes-alergias`;
/* Archivos */
export const UploadURL = () => `${APIDIR}/upload/`;
export const ArchivosURL = () => `${APIDIR}/archivos/`;
export const ArchivosURLGET = (
  idMedico: number | null,
  idPaciente: number | null,
  registro: string,
  idArchivo: number | null,
  nombreArchivo: string | null,
  pathArchivo: string,
) => `${APIDIR}/archivos?registro=${registro}&idArchivo=${idArchivo}&nombreArchivo=${nombreArchivo}&pathArchivo=${pathArchivo}&idMedico=${idMedico}&idPaciente=${idPaciente}`;
/* Alergias */
// export const AlergiasPacienteURL = (idMedico: number, idPaciente: number, idConsultorio: number) => `${APIDIR}/api/alergias/obtener-datos?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const AlergiasURLAlergia = () => `${APIDIR}/api/alergias/alergia`;
export const AlergiasURLSinAntecedentes = () => `${APIDIR}/api/alergias/sin-antecedentes`;
/* Aparatos y Sistemas */
export const aparatosYSistemas = `${APIDIR}/api/aparatos-y-sistemas`;
/* BUILDER */
export const builderURLBuilderVariable = () => `${APIDIR}/api/builder/variable`;
export const builderURLBuilderVariablesTop = (idMedico: number) => `${APIDIR}/api/builder/variables/${idMedico}`;
export const builderURLBuilderGetVariableMedico = (idMedico: number, idVariable: number) => `${APIDIR}/api/builder/obtener-variable-medico/${idMedico}/${idVariable}`;
export const builderURLBuilderGetVariableMedipraxi = (idMedico: number, idVariable: number) => `${APIDIR}/api/builder/obtener-variable-medipraxi?idMedico=${idMedico}&idVariable=${idVariable}`;
export const builderURLFCValidacion = () => `${APIDIR}/api/builder/formato-consulta/validacion`;
export const builderURLFCActualizar = () => `${APIDIR}/api/builder/formato-consulta/actualizar`;
export const builderURLFCEliminar = () => `${APIDIR}/api/builder/formato-consulta/eliminar`;
export const builderURLBuscarVariables = (idMedico: number, strBusqueda: string) => `${builderURLBuilderVariable()}/${idMedico}/${strBusqueda}`;
export const builderURLCompartirFormatoConsulta = () => `${APIDIR}/api/builder/formato-consulta/compartir`;
export const builderURLObtenerFormatoConsultaPropio = (idFormato: number, idMedico: number) => `${APIDIR}/api/builder/formato-consulta/obtener-propio?idMedico=${idMedico}&idFormato=${idFormato}`;
export const builderURLObtenerFormatoConsultaMedipraxi = (idFormato: number, idMedico: number) => `${APIDIR}/api/builder/formato-consulta/obtener-medipraxi?idMedico=${idMedico}&idFormato=${idFormato}`;
export const builderURLObtenerFormatoMotivosTop = (idMedico: number) => `${APIDIR}/api/builder/formato-consulta-top/${idMedico}`;
export const builderURLBuscarFormatoMotivosPropios = (
  idMedico: number,
  motivoNombre: string,
  motivoCategoria: number,
  motivoRango: number,
  motivoSexo: number,
  motivoConsulta: number,
  pagina: number,
) => `${APIDIR}/api/builder/formato-consulta/propios?idMedico=${idMedico}&motivoNombre=${motivoNombre}&motivoCategoria=${
  motivoCategoria || ''
}&motivoRango=${motivoRango || ''}&motivoSexo=${motivoSexo || ''}&motivoConsulta=${
  motivoConsulta || ''
}&pagina=${pagina}`;
export const builderURLBuscarFormatoMotivosCompartidos = (
  idMedico: number,
  idOrganizacion: number,
  motivoNombre: string,
  motivoCategoria: number,
  motivoRango: number,
  motivoSexo: number,
  motivoConsulta: number,
  pagina: number,
) => `${APIDIR}/api/builder/formato-consulta/compartidos?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}&motivoNombre=${motivoNombre}&motivoCategoria=${
  motivoCategoria || ''
}&motivoRango=${motivoRango || ''}&motivoSexo=${motivoSexo || ''}&motivoConsulta=${
  motivoConsulta || ''
}&pagina=${pagina}`;
export const builderURLBuscarFormatoMotivosMedipraxi = (
  idMedico: number,
  motivoNombre: string,
  motivoCategoria: number,
  motivoRango: number,
  motivoSexo: number,
  motivoConsulta: number,
  pagina: number,
) => `${APIDIR}/api/builder/formato-consulta/medipraxi?idMedico=${idMedico}&motivoNombre=${motivoNombre}&motivoCategoria=${
  motivoCategoria || ''
}&motivoRango=${motivoRango || ''}&motivoSexo=${motivoSexo || ''}&motivoConsulta=${
  motivoConsulta || ''
}&pagina=${pagina}`;
export const builderURLHabilitarUsoMCMedipraxi = () => `${APIDIR}/api/builder/habilitar-uso`;
/* Recetas y Consultas */
export const recetasTablaIni = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idOrganizacion: number,
) => `${APIDIR}/api/consultas/tabla-receta-ini?idMedico=${idMedico}&idConsultorio=${idConsultorio}&idOrganizacion=${idOrganizacion}&idPaciente=${idPaciente}`;
export const consultasMedicasTabla = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idOrganizacion: number,
  fechaIni: string,
  fechaFin: string,
) => `${APIDIR}/api/consultas/tabla-consulta?idMedico=${idMedico}&idConsultorio=${idConsultorio}&idOrganizacion=${idOrganizacion}&idPaciente=${idPaciente}&fechaIni=${fechaIni}&fechaFin=${fechaFin}`;
export const consultasMedicasTablaIni = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idOrganizacion: number,
) => `${APIDIR}/api/consultas/tabla-consulta-ini?idMedico=${idMedico}&idConsultorio=${idConsultorio}&idOrganizacion=${idOrganizacion}&idPaciente=${idPaciente}`;
export const consultasMedicasCrearConsulta = () => `${APIDIR}/api/consultas/crear-consulta`;
export const consultasMedicasGuardarPacienteConsulta = () => `${APIDIR}/api/consultas/guardar-consulta`;
export const consultasMedicasFinalizarPacienteConsulta = () => `${APIDIR}/api/consultas/finalizar-consulta`;
export const consultasMedicasObtenerConsultaAbierta = (idMedico: number, idConsultorio: number) => `${APIDIR}/api/consultas/obtener-consulta-abierta?idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const consultasMedicasObtenerConsulta = (
  idConsulta: number,
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/consultas/obtener-consulta?idConsulta=${idConsulta}&idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const consultasMedicasExistenciaPaciente = (
  idMedico: number,
  idConsultorio: number,
  idOrganizacion: number,
  docId: string,
) => `${APIDIR}/api/consultas/busca-paciente?idMedico=${idMedico}&idConsultorio=${idConsultorio}&idOrganizacion=${idOrganizacion}&docId=${docId}`;
/* Paciente Doctor */
export const PacientesTablaURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tablaInicio: number,
  ordenarPor: string,
) => `${APIDIR}/api/paciente-doctor/detalle/${idMedico}/${idOrganizacion}/${idConsultorio}/${tablaInicio}/${ordenarPor}`;
export const PacientesTablaBusquedaURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  nombreString: string,
  ordenarPor: string,
) => `${APIDIR}/api/paciente-doctor/detalle-busqueda-nombre/${idMedico}/${idOrganizacion}/${idConsultorio}/${nombreString}/${ordenarPor}`;
export const PacientesAgendaBusquedaURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  nombreString: string,
) => `${APIDIR}/api/paciente-doctor/busqueda-nombre-agenda/${idMedico}/${idOrganizacion}/${idConsultorio}/${nombreString}`;
export const CrearTokenPacienteURL = () => `${APIDIR}/api/paciente-doctor/id-acceso`;
export const VerificarTokenPacienteURL = (
  idMedico: number,
  docId: string,
  idConsultorio: string,
  idOrganizacion: string,
  acceso: string,
) => `${APIDIR}/api/paciente-doctor/validar-acceso?idMedico=${idMedico}&docId=${docId}&idConsultorio=${idConsultorio}&idOrganizacion=${idOrganizacion}&acceso=${acceso}`;
/* Enfermedades */
export const enfermedadesListasEnfermedads = () => `${APIDIR}/api/enfermedades/listas-enfermedades`;
export const enfermedadesListaEnfermedad = () => `${APIDIR}/api/enfermedades/lista-enfermedad`;
export const getEnfermedadesListasEnfermedades = (
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
) => `${APIDIR}/api/enfermedades/listas-enfermedades?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const getEnfermedadesArrEnfermedadesReferidas = (
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
) => `${APIDIR}/api/enfermedades/listas-enfermedades-referidas?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const enfermedadesEnfermedadReferidaURL = () => `${APIDIR}/api/enfermedades/enfermedad-referida`;
export const enfermedadesEnfermedadNegadaURL = () => `${APIDIR}/api/enfermedades/enfermedad-negada`;
export const enfermedadesGrupoEnfermedadesNegadasURL = () => `${APIDIR}/api/enfermedades/grupo-enfermedades-negadas`;
export const enfermedadesDiagnosticosUsuarioURL = () => `${APIDIR}/api/enfermedades/diagnosticos-usuario`;
/* FAMILIARES */
export const antecedentesFamiliaresObtenerListaFamiliares = (
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
) => `${APIDIR}/api/familiares?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const antecedentesFamiliaresGuardarFamiliar = () => `${APIDIR}/api/familiares/guardar-enfermedades-familiar`;
export const antecedentesFamiliarAgregarEnfermedad = () => `${APIDIR}/api/familiares/agregar-enfermedades-familiar`;
export const antecedentesFamiliaresEditarListaFamiliares = () => `${APIDIR}/api/familiares/editar-enfermedades-familiar`;
export const antecedentesFamiliarEnfermedad = () => `${APIDIR}/api/familiares/enfermedad`;
export const antecedentesFamiliarEnfermedadNegada = () => `${APIDIR}/api/familiares/enfermedad-negada`;
/* NO PATOLOGICOS */
export const antecedentesNoPatologicosVivienda = () => `${APIDIR}/api/antecedentes/no-patologicos/vivienda`;
export const antecedentesNoPatologicosObtenerVivienda = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/antecedentes/no-patologicos/vivienda?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const antecedentesNoPatologicosHigiene = () => `${APIDIR}/api/antecedentes/no-patologicos/higiene`;
export const antecedentesNoPatologicosObtenerHigiene = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/antecedentes/no-patologicos/higiene?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const antecedentesNoPatologicosAlimentacion = () => `${APIDIR}/api/antecedentes/no-patologicos/alimentacion`;
export const antecedentesNoPatologicosObtenerAlimentacion = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/antecedentes/no-patologicos/alimentacion?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const antecedentesNoPatologicosActividadFisica = () => `${APIDIR}/api/antecedentes/no-patologicos/actividad-fisica`;
export const antecedentesNoPatologicosObtenerActividadFisica = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/antecedentes/no-patologicos/actividad-fisica?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const antecedentesNoPatologicosToxicomania = () => `${APIDIR}/api/antecedentes/no-patologicos/toxicomania`;
export const antecedentesNoPatologicosObtenerToxicomania = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/antecedentes/no-patologicos/toxicomania?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const antecedentesNoPatologicosGinecoObstetricos = () => `${APIDIR}/api/antecedentes/no-patologicos/gineco-obstetricos`;
export const antecedentesNoPatologicosObtenerGinecoObstetricos = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idConsulta: number,
) => `${APIDIR}/api/antecedentes/no-patologicos/gineco-obstetricos?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}&idConsulta=${idConsulta}`;
export const antecedentesNoPatologicosHistoriaSexual = () => `${APIDIR}/api/antecedentes/no-patologicos/historia-sexual`;
export const antecedentesNoPatologicosObtenerHistoriaSexual = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idConsulta: number,
) => `${APIDIR}/api/antecedentes/no-patologicos/historia-sexual?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}&idConsulta=${idConsulta}`;
export const antecedentesNoPatologicosUsuarioPacienteVivienda = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario-paciente/vivienda`;
export const antecedentesNoPatologicosUsuarioPacienteHigiene = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario-paciente/higiene`;
export const antecedentesNoPatologicosUsuarioPacienteAlimentacion = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario-paciente/alimentacion`;
export const antecedentesNoPatologicosUsuarioPacienteActividadFisica = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario-paciente/actividad-fisica`;
export const antecedentesNoPatologicosUsuarioPacienteToxicomania = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario-paciente/toxicomania`;
export const antecedentesNoPatologicosUsuarioPacienteGinecoObstetricos = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario-paciente/gineco-obstetricos`;
export const antecedentesNoPatologicosUsuarioVivienda = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario/vivienda`;
export const antecedentesNoPatologicosUsuarioHigiene = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario/higiene`;
export const antecedentesNoPatologicosUsuarioAlimentacion = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario/alimentacion`;
export const antecedentesNoPatologicosUsuarioActividadFisica = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario/actividad-fisica`;
export const antecedentesNoPatologicosUsuarioToxicomania = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario/toxicomania`;
export const antecedentesNoPatologicosUsuarioGinecoObstetricos = () => `${APIDIR}/api/antecedentes/no-patologicos-usuario/gineco-obstetricos`;
export const guardarDictamenMedLab = () => `${APIDIR}/api/dictamenes-medicos-laborales/dictamenes`;
export const dictamenesMedLab = (
  idPaciente: number,
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  idConsulta: number,
) => `${APIDIR}/api/dictamenes-medicos-laborales/dictamenes?idPaciente=${idPaciente}&idMedico=${idMedico}&idOrganizacion=${idOrganizacion}&idConsultorio=${idConsultorio}&idConsulta=${idConsulta}`;
/* OTRAS */
export const LoginURL = () => `${APIDIR}/api/login`;
export const SecureLoginURL = () => `${APIDIR}/api/llaves`;
export const SecureSignUpURL = () => `${APIDIR}/api/llaves/signup`;
export const SecureUpdatePassURL = () => `${APIDIR}/api/llaves/update`;
export const SecureLogoutURL = () => `${APIDIR}/api/llaves/logout`;
export const SecureAuthorizationURL = () => `${APIDIR}/api/autorizaciones/autorizar-organizacion`;
export const CfdiURL = () => `${APIDIR}/api/catalogos/cfdi`;
export const NacionalidadURL = () => `${APIDIR}/api/catalogos/nacionalidad`;
export const GrupoEtnicoURL = () => `${APIDIR}/api/catalogos/grupo_etnico`;
export const TipoIdentificacionURL = () => `${APIDIR}/api/catalogos/tipo_identificacion`;
export const EscolaridadURL = () => `${APIDIR}/api/catalogos/escolaridad`;
export const TipoOcupacionURL = () => `${APIDIR}/api/catalogos/tipo_ocupacion`;
export const AccidentesCausaURL = () => `${APIDIR}/api/catalogos/accidentes-causa`;
export const AccidentesLugarURL = () => `${APIDIR}/api/catalogos/accidentes-lugar`;
export const AccidentesTurnoURL = () => `${APIDIR}/api/catalogos/accidentes-turno`;
export const AccidentesLesionNaturalezaURL = () => `${APIDIR}/api/catalogos/accidentes-lesion-naturaleza`;
export const AccidentesLesionSegmentoURL = () => `${APIDIR}/api/catalogos/accidentes-lesion-segmento`;
export const AccidentesLesionUbicacionURL = () => `${APIDIR}/api/catalogos/accidentes-lesion-ubicacion`;
export const AccidentesLesionMecanismoURL = () => `${APIDIR}/api/catalogos/accidentes-lesion-mecanismo`;
export const AccidentesLesionGravedadURL = () => `${APIDIR}/api/catalogos/accidentes-lesion-gravedad`;
export const AccidentesPorcentajeIncapacidadURL = () => `${APIDIR}/api/catalogos/accidentes-porcentaje-incapacidad`;
export const IncapacidadesTipoIncidenciaURL = () => `${APIDIR}/api/catalogos/incapacidades-tipo-incidencia`;
export const IncapacidadesRamoSeguroURL = () => `${APIDIR}/api/catalogos/incapacidades-ramo-seguro`;
export const IncapacidadesTipoRiesgoURL = () => `${APIDIR}/api/catalogos/incapacidades-tipo-riesgo`;
export const IncapacidadesSecuelaURL = () => `${APIDIR}/api/catalogos/incapacidades-secuela`;
export const IncapacidadesControlIncapacidadURL = () => `${APIDIR}/api/catalogos/incapacidades-control-incapacidad`;
export const QuimicosURL = () => `${APIDIR}/api/catalogos/quimicos`;
export const TipoEstablecimientoURL = () => `${APIDIR}/api/catalogos/tipo-establecimiento`;
export const TablaTipoRolesURL = () => `${APIDIR}/api/catalogos/tabla-cs-tipo-roles`;
export const TablaSeccionesURL = () => `${APIDIR}/api/catalogos/tabla-cs-secciones`;
export const TiempoOcupacionURL = () => `${APIDIR}/api/catalogos/tiempo_ocupacion`;
export const ReligionURL = () => `${APIDIR}/api/catalogos/religion`;
export const EstadoCivilURL = () => `${APIDIR}/api/catalogos/estado_civil`;
export const guardarTipoExamenURL = () => `${APIDIR}/api/catalogos/tipo-examen`;
export const guardarOcupacionURL = () => `${APIDIR}/api/catalogos/ocupacion`;
export const guardarRestriccionURL = () => `${APIDIR}/api/catalogos/restriccion`;
export const guardarCausaNoAptitudURL = () => `${APIDIR}/api/catalogos/causa-no-aptitud`;
export const CategoriasExamenURL = () => `${APIDIR}/api/catalogos/categorias-examen`;
export const TiposExamenURL = (idOrganizacion: number) => `${APIDIR}/api/catalogos/tipos-examen/${idOrganizacion}`;
export const OcupacionesURL = (idOrganizacion: number) => `${APIDIR}/api/catalogos/ocupaciones/${idOrganizacion}`;
export const DictamenesURL = () => `${APIDIR}/api/catalogos/dictamenes`;
export const RestriccionesURL = (idOrganizacion: number) => `${APIDIR}/api/catalogos/restricciones/${idOrganizacion}`;
export const CausasNoAptitudURL = (idOrganizacion: number) => `${APIDIR}/api/catalogos/causas-no-aptitud/${idOrganizacion}`;
export const getCatAgee = (idMedico: number) => `${APIDIR}/api/catalogos/agee/${idMedico}`;
export const getCatAgem = (agee: string) => `${APIDIR}/api/catalogos/agem/${agee}`;
export const getCatAgel = (agee: string, agem: string) => `${APIDIR}/api/catalogos/agel/${agee}${agem}`;
export const getCatAgea = (agee: string, agem: string, agel: string) => `${APIDIR}/api/catalogos/agea/${agee}${agem}${agel}`;
export const Parentesco = () => `${APIDIR}/api/catalogos/parentesco`;
export const AseguradorasURL = (agep: string) => `${APIDIR}/api/catalogos/aseguradoras/${agep}`;
export const DiscapacidadTipoURL = () => `${APIDIR}/api/catalogos/discapacidad-tipo`;
export const DiscapacidadOrigenURL = () => `${APIDIR}/api/catalogos/discapacidad-origen`;
export const DiscapacidadGradoURL = () => `${APIDIR}/api/catalogos/discapacidad-grado`;
export const UnidadesURL = () => `${APIDIR}/api/catalogos/unidades`;
export const EscuelasMedicinaURL = (idMedico: number) => `${APIDIR}/api/catalogos/escuela-medicina/${idMedico}`;
export const EspecialidadMedicinaURL = () => `${APIDIR}/api/catalogos/especialidad-medicina`;
export const AreaMedicinaURL = () => `${APIDIR}/api/catalogos/area-competencia`;
export const IdiomasMedicinaURL = () => `${APIDIR}/api/catalogos/idiomas`;
export const LadasURL = () => `${APIDIR}/api/catalogos/ladas`;
export const MCCategoriasURL = () => `${APIDIR}/api/catalogos/categorias-mc`;
export const MCRangoEdadURL = () => `${APIDIR}/api/catalogos/rango-edad-mc`;
export const AnimalesURL = () => `${APIDIR}/api/catalogos/animales`;
export const EjerciciosURL = () => `${APIDIR}/api/catalogos/ejercicios`;
export const SitioMedicionURL = () => `${APIDIR}/api/catalogos/sitio-medicion`;
export const HospDuracionURL = () => `${APIDIR}/api/catalogos/hospitalizacion-duracion`;
export const VacunaNombreURL = () => `${APIDIR}/api/catalogos/vacuna/nombre`;
export const VacunaDosisURL = () => `${APIDIR}/api/catalogos/vacuna/dosis`;
export const LaboratoriosURL = () => `${APIDIR}/api/catalogos/laboratorios`;
export const LaboratoriosResultadoURL = () => `${APIDIR}/api/catalogos/laboratorios/resultado`;
export const LaboratoriosUnidadesURL = () => `${APIDIR}/api/catalogos/laboratorios/unidades`;
export const SecuelasURL = () => `${APIDIR}/api/catalogos/secuelas`;
export const Cie10SexoURL = (sexo: string) => `${APIDIR}/api/catalogos/cie-10/${sexo}`;
export const Cie10SexoPalabraURL = (sexo: string, palabra: string) => `${APIDIR}/api/catalogos/cie-10/sp/${sexo}/${palabra}`;
export const Cie10RangoURL = (sexo: string, selector: string, numero: number) => `${APIDIR}/api/catalogos/cie-10/rango/${sexo}/${selector}/${numero}`;
export const Cie10MaxURL = (sexo: string, selector: string, numero: number) => `${APIDIR}/api/catalogos/cie-10/max/${sexo}/${selector}/${numero}`;
export const DiagnosticosGeneralesSexoURL = (sexo: string) => `${APIDIR}/api/catalogos/diagnosticos-generales/${sexo}`;
export const AntecedentesParentescoURL = () => `${APIDIR}/api/catalogos/antecedentes_parentesco`;
export const getCatalogoCie10Palabra = (palabra: string) => `${APIDIR}/api/catalogos/cie-10/palabra/${palabra}`;
export const getCatalogoCie10Query = (
  query: string,
  sexo: string,
  periodoEdad: string,
  edad: number,
) => `${APIDIR}/api/catalogos/cie-10/query/${query}/${sexo}/${periodoEdad}/${edad}`;
export const getCatalogoCie10SexoQuery = (query: string, sexo: string) => `${APIDIR}/api/catalogos/cie-10/query-sexo/${query}/${sexo}`;
export const getCatalogoCie10QueryCategoria = (
  idPaciente: number,
  query: string,
  sexo: string,
  periodoEdad: string,
  edad: number,
) => `${APIDIR}/api/catalogos/cie-10/query-cat?idPaciente=${idPaciente}&query=${query}&sexo=${sexo}&periodoEdad=${periodoEdad}&edad=${edad}`;
export const getCatalogoCie10QueryCategoriaSexo = (query: string, sexo: string) => `${APIDIR}/api/catalogos/cie-10/query-cat-sexo/${query}/${sexo}`;
export const TransfusionesPacienteURL = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/transfusion/obtener-datos?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const TransfusionesURLTransfusion = () => `${APIDIR}/api/transfusion/transfusion`;
export const TransfusionesURLSinAntecedentes = () => `${APIDIR}/api/transfusion/sin-antecedentes`;
export const TransfusionesURLUsuarioPacienteTransfusion = () => `${APIDIR}/api/transfusion/transfusion-usuario`;
export const TransfusionesURLUsuarioPacienteSinAntecedentes = () => `${APIDIR}/api/transfusion/sin-antecedentes-usuario`;
export const HospitalizacionesPacienteURL = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/hospitalizaciones/hospitalizaciones?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const HospitalizacionesURLHospitalizacion = () => `${APIDIR}/api/hospitalizaciones/hospitalizacion`;
export const HospitalizacionesURLSinAntecedentes = () => `${APIDIR}/api/hospitalizaciones/sin-antecedentes`;
export const InmunizacionesURL = (
  id: string,
  edad: string,
  idMedico: string,
  idConsulta: string,
  idConsultorio: number,
) => `${APIDIR}/api/inmunizaciones/obtener-vacunas?id=${id}&edad=${edad}&idMedico=${idMedico}&idConsulta=${idConsulta}&idConsultorio=${idConsultorio}`;
export const PublicKeyURL = () => `${APIDIR}/api/llaves`;
export const GenericoURL = (idMedico: number, searchTerm: string) => `${APIDIR}/api/farmacologico/med-generico?q=${searchTerm}&idMedico=${idMedico}`;
export const GenericoIDURL = (idMedico: number, id: number) => `${APIDIR}/api/farmacologico/med-generico-id?idMedico=${idMedico}&id=${id}`;
export const PatenteURL = (idMedico: number, searchTerm: string) => `${APIDIR}/api/farmacologico/med-comercial?idMedico=${idMedico}&q=${searchTerm}`;
export const CatFarmaPeriodoURL = () => `${APIDIR}/api/farmacologico/cat/periodo`;
export const CatFarmaFrecuenciaURL = () => `${APIDIR}/api/farmacologico/cat/frecuencia`;
export const ObtenerViasGenerico = (idMedico: number, id: number) => `${APIDIR}/api/farmacologico/med-generico/vias?id=${id}&idMedico=${idMedico}`;
export const ObtenerViasPatente = (idMedico: number, id: number) => `${APIDIR}/api/farmacologico/med-comercial/vias?id=${id}&idMedico=${idMedico}`;
export const ObtenerUnidadFormaGenerico = (idMedico: number, id: number) => `${APIDIR}/api/farmacologico/med-generico/unidad-forma?id=${id}&idMedico=${idMedico}`;
export const ObtenerIndicationGenerico = (id: number) => `${APIDIR}/api/farmacologico/generico/indication/${id}`;
export const CatFarmaUnidad = () => `${APIDIR}/api/farmacologico/cat/unidad`;
export const CatFarmaVia = () => `${APIDIR}/api/farmacologico/cat/via`;
// PARA GUARDAR Y ACTUALIZAR
export const perfilPacienteDatosGenerales = () => `${APIDIR}/api/perfil-paciente/datos-generales`;
export const perfilPacienteDatosUbicacion = () => `${APIDIR}/api/perfil-paciente/datos-ubicacion`;
export const perfilPacienteInfoEmergencia = () => `${APIDIR}/api/perfil-paciente/info-emergencia`;
export const perfilPacienteSegurosPlanes = () => `${APIDIR}/api/perfil-paciente/seguros-planes`;
export const perfilPacienteDatosFacturacion = () => `${APIDIR}/api/perfil-paciente/datos-ubicacion`;
export const perfilPacienteDiscapacidades = () => `${APIDIR}/api/perfil-paciente/discapacidades`;
export const perfilPacienteNotasURL = () => `${APIDIR}/api/perfil-paciente/notas`;
export const perfilUsuarioPacienteDatosGenerales = () => `${APIDIR}/api/perfil-paciente/datos-generales-usuario-paciente`;
export const perfilUsuarioPacienteDatosUbicacion = () => `${APIDIR}/api/perfil-paciente/datos-ubicacion-usuario-paciente`;
export const perfilUsuarioPacienteInfoEmergencia = () => `${APIDIR}/api/perfil-paciente/info-emergencia-usuario-paciente`;
export const perfilUsuarioPacienteSegurosPlanes = () => `${APIDIR}/api/perfil-paciente/seguros-planes-usuario-paciente`;
export const perfilUsuarioPacienteDiscapacidades = () => `${APIDIR}/api/perfil-paciente/discapacidades-usuario-paciente`;
export const perfilUsuarioDatosGenerales = () => `${APIDIR}/api/perfil-paciente/datos-generales-usuario`;
export const perfilUsuarioDatosUbicacion = () => `${APIDIR}/api/perfil-paciente/datos-ubicacion-usuario`;
export const perfilUsuarioInfoEmergencia = () => `${APIDIR}/api/perfil-paciente/info-emergencia-usuario`;
export const perfilUsuarioSegurosPlanes = () => `${APIDIR}/api/perfil-paciente/seguros-planes-usuario`;
export const perfilUsuarioDiscapacidades = () => `${APIDIR}/api/perfil-paciente/discapacidades-usuario`;
export const crearTokenUsuarioURL = () => `${APIDIR}/api/perfil-paciente/pin-usuario`;
export const validarTokenUsuarioURL = () => `${APIDIR}/api/perfil-paciente/validar-usuario-pin`;
export const validarEmailUsuarioURL = () => `${APIDIR}/api/perfil-paciente/validar-usuario-email`;
export const guardarTipoEmpleadoURL = () => `${APIDIR}/api/perfil-paciente/guardar-tipo-empleado`;
export const perfilMedicoDatosURL = () => `${APIDIR}/api/perfil-medico/perfil`;
export const perfilMedicoCarreraURL = () => `${APIDIR}/api/perfil-medico/carrera`;
export const perfilMedicoEspecialidadURL = () => `${APIDIR}/api/perfil-medico/especialidad`;
export const perfilMedicoMaestriaURL = () => `${APIDIR}/api/perfil-medico/maestria`;
export const perfilMedicoDoctoradoURL = () => `${APIDIR}/api/perfil-medico/doctorado`;
export const perfilMedicoExperienciaURL = () => `${APIDIR}/api/perfil-medico/experiencia`;
export const perfilSuperAdministradorDatosURL = () => `${APIDIR}/api/superadministrador/perfil`;
export const guardarSAMiOrganizacionOrganizacionURL = () => `${APIDIR}/api/superadministrador/mi-organizacion/organizacion`;
export const guardarSAMiOrganizacionOrganizacionesURL = () => `${APIDIR}/api/superadministrador/mi-organizacion/organizaciones`;
export const obtenerSAMiOrganizacionOrganizacionesURL = (
  idMedico: number,
  idOrganizacion: number,
) => `${APIDIR}/api/superadministrador/mi-organizacion/organizaciones?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}`;
export const obtenerSAMiOrganizacionLicenciasURL = (
  idMedico: number,
  emailSend: string,
  idOrganizacion: number,
) => `${APIDIR}/api/superadministrador/mi-organizacion/licencias?idMedico=${idMedico}&emailSend=${emailSend}&idOrganizacion=${idOrganizacion}`;
export const guardarSAMiOrganizacionAdministradorURL = () => `${APIDIR}/api/superadministrador/mi-organizacion/administrador`;
export const obtenerSAMiOrganizacionAdministradoresURL = (
  idMedico: number,
  idOrganizacion: number,
) => `${APIDIR}/api/superadministrador/mi-organizacion/administradores?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}`;
export const obtenerSAMiOrganizacionValidacionDocIdURL = (
  idMedico: number,
  idOrganizacion: number,
  docId: string,
) => `${APIDIR}/api/superadministrador/mi-organizacion/validacion-doc-id?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}&docId=${docId}`;
export const perfilAdministradorDatosURL = () => `${APIDIR}/api/administrador/perfil`;
export const guardarMiOrganizacionOrganizacionURL = () => `${APIDIR}/api/administrador/mi-organizacion/organizacion`;
export const guardarMiOrganizacionConsultoriosURL = () => `${APIDIR}/api/administrador/mi-organizacion/consultorios`;
export const guardarMiOrganizacionRolesURL = () => `${APIDIR}/api/administrador/mi-organizacion/roles`;
export const guardarMiOrganizacionAdministradorURL = () => `${APIDIR}/api/administrador/mi-organizacion/administrador`;
export const guardarMiOrganizacionEquipoMedicoCsvURL = () => `${APIDIR}/api/administrador/mi-organizacion/equipo-medico-csv`;
export const guardarMiOrganizacionPacientesCsvURL = () => `${APIDIR}/api/administrador/mi-organizacion/pacientes-csv`;
export const obtenerMiOrganizacionOrganizacionURL = (idMedico: number, idOrganizacion: number) => `${APIDIR}/api/administrador/mi-organizacion/organizacion?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}`;
export const obtenerMiOrganizacionConsultoriosURL = (idMedico: number, idOrganizacion: number) => `${APIDIR}/api/administrador/mi-organizacion/consultorios?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}`;
export const obtenerMiOrganizacionRolesURL = (idMedico: number, idOrganizacion: number) => `${APIDIR}/api/administrador/mi-organizacion/roles?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}`;
export const obtenerOrganizacionEquipoMedicoLicenciasURL = (
  idMedico: number,
  idOrganizacion: number,
  emailSend: string,
) => `${APIDIR}/api/administrador/mi-organizacion/equipo-medico-licencias?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}&emailSend=${emailSend}`;
export const obtenerMiOrganizacionAdministradorURL = (idMedico: number, idOrganizacion: number) => `${APIDIR}/api/administrador/mi-organizacion/administrador?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}`;
export const obtenerMiOrganizacionAltasBajasUsuarioURL = (
  idMedico: number,
  idOrganizacion: number,
) => `${APIDIR}/api/administrador/mi-organizacion/altas-bajas-usuario?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}`;
export const obtenerMiOrganizacionAltasBajasPacienteURL = (
  idMedico: number,
  idOrganizacion: number,
) => `${APIDIR}/api/administrador/mi-organizacion/altas-bajas-paciente?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}`;
export const guardarMiOrganizacionPermitirTokensPacientesURL = () => `${APIDIR}/api/administrador/mi-organizacion/permitir-tokens-pacientes`;
export const obtenerMiOrganizacionNuevoTokenPacientesURL = (
  idMedico: number,
  idOrganizacion: number,
  tipo: string,
) => `${APIDIR}/api/administrador/mi-organizacion/nuevo-token-pacientes?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}&tipo=${tipo}`;
export const saveInmunizaciones = () => `${APIDIR}/api/inmunizaciones/guardar-vacunas`;
export const antecedentesNoPatologicos = () => `${APIDIR}/api/antecedentes/no-patologicos`;
export const antecedentesPatologicos = () => `${APIDIR}/api/antecedentes/patologicos`;
export const saveProcedimientos = () => `${APIDIR}/api/antecedentes/procedimientos`;
export const diagnosticoPlan = () => `${APIDIR}/api/diagnostico-y-plan/plan`;
export const solicitudProcedimiento = () => `${APIDIR}/api/diagnostico-y-plan/solicitud-procedimiento`;
export const diagnosticPlanSaveLaboratory = () => `${APIDIR}/api/diagnostico-y-plan/guardar_laboratorio`;
export const saveLaboratory = () => `${APIDIR}/api/laboratorio/modificar-laboratorio`;
export const saveAgendaConfigurar = () => `${APIDIR}/api/agenda/configuracion`;
export const saveAgenda = () => `${APIDIR}/api/agenda/guarda-agenda`;
export const saveAgendaDelete = () => `${APIDIR}/api/agenda/deleteAgenda`;
export const deleteLaboratory = () => `${APIDIR}/api/laboratorio/eliminar`;
export const datosCheckout = () => `${APIDIR}/api/payment-method/payment`;
export const subscripcionPaymentMedico = (email: string) => `${APIDIR}/api/payment-method/subscripcion?email=${email}`;
export const transfusionGuardar = () => `${APIDIR}/api/transfusion/guardar-datos`;
export const pronosticoURL = () => `${APIDIR}/api/diagnostico-y-plan/pronostico`;
export const antecedentesLaboralesSinAntecedentesURL = () => `${APIDIR}/api/laborales/sin-antecedentes`;
export const antecedentesLaboralesEmpresaURL = () => `${APIDIR}/api/laborales/empresa`;
export const antecedentesLaboralesExposicionesNegadasURL = () => `${APIDIR}/api/laborales/exposicion-riesgo-negadas`;
export const antecedentesAccidenteLaboralRadioURL = () => `${APIDIR}/api/laborales/seleccion-accidente-laboral`;
export const antecedentesAccidenteObjLaboralURL = () => `${APIDIR}/api/laborales/guardar-accidente-laboral`;
export const antecedentesEliminarAccidenteLaboralURL = () => `${APIDIR}/api/laborales/eliminar-accidente-laboral`;
export const antecedentesEliminarEmpresaURL = () => `${APIDIR}/api/laborales/eliminar-antecedentes-empresa`;
export const antecedentesLaboralesExposicionListaURL = () => `${APIDIR}/api/laborales/lista-laboral-exposicion`;
export const antecedentesEliminarLaboralesExposicionListaURL = () => `${APIDIR}/api/laborales/eliminar-laboral-exposicion`;
export const incapacidadesGuardarURL = () => `${APIDIR}/api/incapacidades/guardar`;
export const incapacidadesEliminarURL = () => `${APIDIR}/api/incapacidades/eliminar`;
// PARA OBTENER
export const getAntecedentesLaboralesSinAntecedentesURL = (idPaciente: number, idMedico: number) => `${APIDIR}/api/laborales/sin-antecedentes?idPaciente=${idPaciente}&idMedico=${idMedico}`;
export const getListaAntecedentesLaboralesURL = (idPaciente: number, idMedico: number) => `${APIDIR}/api/laborales/lista-antecedentes-laborales?idPaciente=${idPaciente}&idMedico=${idMedico}`;
export const getMcpa = (
  idMedico: number,
  idPaciente: number,
  idConsulta: number,
  idConsultorio: number,
) => `${APIDIR}/api/mcpa/consultar-mcpa?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}&idConsulta=${idConsulta}`;
export const getPerfilPacienteDatos = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idConsulta: number,
) => `${APIDIR}/api/perfil-paciente/obtener-datos/${idMedico}/${idPaciente}/${idConsultorio}/${idConsulta}`;
export const getPerfilPacienteDatosUsuario = (
  idMedico: number,
  idPaciente: number,
  idUsuario: number,
  idConsultorio: number,
) => `${APIDIR}/api/perfil-paciente/obtener-datos-usuario/${idMedico}/${idPaciente}/${idUsuario}/${idConsultorio}`;
export const getPerfilUsuarioPacienteDatos = (idUsuario: number) => `${APIDIR}/api/perfil-paciente/obtener-datos-usuario/${idUsuario}`;
export const getPerfilPacienteDatosGenerales = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/perfil-paciente/datos-generales/${idMedico}/${idPaciente}/${idConsultorio}`;
export const getPerfilPacienteDatosUbicacion = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/perfil-paciente/datos-ubicacion/${idMedico}/${idPaciente}/${idConsultorio}`;
export const getPerfilPacienteDatosEmergencia = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/perfil-paciente/info-emergencia/${idMedico}/${idPaciente}/${idConsultorio}`;
export const getPerfilPacienteDatosSeguros = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/perfil-paciente/seguros-planes/${idMedico}/${idPaciente}/${idConsultorio}`;
export const getPerfilPacienteDiscapacidades = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/perfil-paciente/discapacidades/${idMedico}/${idPaciente}/${idConsultorio}`;
export const getPerfilPacienteNotasURL = (idMedico: number, idPaciente: number, idConsultorio: number) => `${APIDIR}/api/perfil-paciente/notas/${idMedico}/${idPaciente}/${idConsultorio}`;
export const getValueMedicinaPreventivaURL = (
  idPaciente: number,
  idConsulta: number,
  idMedico: number,
  idConsultorio: number,
) => `${APIDIR}/api/medicina-preventiva/cardiovascular?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsulta=${idConsulta}&idConsultorio=${idConsultorio}`;
export const getTableGloboriskURL = (
  sexo: number,
  diabetico: number,
  fumador: number,
  edad: number,
  conColesterol: boolean,
  idMedico: number,
  idPaciente: number,
  idConsultorio: number | null,
) => `${APIDIR}/api/medicina-preventiva/tabla-cardiovascular?sexo=${sexo}&diabetico=${diabetico}&fumador=${fumador}&edad=${edad}&conColesterol=${
  conColesterol ? 1 : 0
}&idMedico=${idMedico}&idPaciente=${idPaciente}${
  idConsultorio ? `&idConsultorio=${idConsultorio}` : ''
}`;
export const getTableGloboriskUsuarioURL = (
  sexo: number,
  fumador: number,
  edad: number,
  idPaciente: number,
) => `${APIDIR}/api/medicina-preventiva/tabla-cardiovascular-usuario?sexo=${sexo}&fumador=${fumador}&edad=${edad}&idPaciente=${idPaciente}`;
export const getRiesgoGloboriskURL = (
  sexo: number,
  diabetico: number,
  fumador: number,
  presion: number,
  colesterol: number,
  edad: number,
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => {
  let p: string = '';
  p = `sexo=${sexo}&diabetico=${diabetico}&fumador=${fumador}&presion=${presion}&colesterol=${colesterol}&edad=${edad}&idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
  return `${APIDIR}/api/medicina-preventiva/riesgo-cardiovascular?${p}`;
};
export const getRiesgoAAAURL = (idMedico: number, idPaciente: number, idConsultorio: number) => `${APIDIR}/api/medicina-preventiva/aneurisma-aorta-abdominal?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const getAudiometriaURL = (idMedico: number, idPaciente: number, idConsultorio: number) => `${APIDIR}/api/medicina-preventiva/audiometria?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const getExamenMedicoPeriodicoURL = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/medicina-preventiva/examen-medico-periodico?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const getValueMedicinaPreventivaUsuarioURL = (idUsuario: number) => `${APIDIR}/api/medicina-preventiva/cardiovascular-usuario?idUsuario=${idUsuario}`;
export const getPronosticosDiagnosticos = (
  idMedico: string,
  idPaciente: string,
  idDiagnostico: string,
) => `${APIDIR}/api/diagnostico-y-plan/pronostico/${idMedico}/${idPaciente}/${idDiagnostico}`;
export const getListaPronosticos = (
  idMedico: number,
  idPaciente: number,
  idConsulta: number,
  idConsultorio: number,
) => `${APIDIR}/api/diagnostico-y-plan/pronostico-lista?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}&idConsulta=${idConsulta}`;
export const getPronosticosEnfermedad = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idEnfermedad: string,
) => `${APIDIR}/api/diagnostico-y-plan/pronostico?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}&idEnfermedad=${idEnfermedad}`;
export const getPlanLaboratory = (
  idMedico: number,
  idConsulta: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/diagnostico-y-plan/solicitud-laboratorio?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}&idConsulta=${idConsulta}`;
export const getDatosGeneralesPaciente = (idPaciente: number) => `${APIDIR}/api/perfil-paciente/datos-generales/paciente/${idPaciente}`;
export const Cie9URL = (id: string, sexo: string) => `${APIDIR}/api/catalogos/cie-9/${id}/${sexo}`;
export const getLaboratoriosDocumentos = (
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
  pagina: number,
) => `${APIDIR}/api/laboratorio/documentos?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}&pagina=${pagina}`;
export const getLaboratoriosUsuario = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/laboratorio/totales?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const getRecomendacionReferencia = (id: string) => `${APIDIR}/api/catalogos/recomendacion-referencia/${id}`;
export const verificarLaboratorios = `${APIDIR}/api/laboratorio/seleccionado`;
export const getExcelLaboratorio = (idPaciente: string, idLaboratorio: string, txt: string) => `${APIDIR}/api/laboratorio/valor/${idPaciente}/${idLaboratorio}/${txt}`;
export const getPDFConsulta = (idConsulta: number, tipoSolicitud: number) => `${APIDIR}/api/consulta-medica-pdf/${idConsulta}/${tipoSolicitud}`;
export const enviaAdministradorBienvenidaURL = (
  emailAdministrador: string,
  emailSend: string,
  idOrganizacion: number,
) => `${APIDIR}/api/email-administrador-bienvenida/${emailAdministrador}/${emailSend}/${idOrganizacion}`;
export const getEditLaboratorio = (
  idPaciente: number,
  idLaboratorio: string,
  idMedico: number,
  idConsultorio: number,
) => `${APIDIR}/api/laboratorio/editar?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}&idLaboratorio=${idLaboratorio}`;
export const getProcedimientosData = (
  idMedico: number,
  idPaciente: number,
  idConsulta: number,
  idConsultorio: number,
  idProcedimiento: string,
) => `${APIDIR}/api/diagnostico-y-plan/consultar-plan?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsulta=${idConsulta}&idConsultorio=${idConsultorio}&idProcedimiento=${idProcedimiento}`;
export const getEstablecimientosSalud = (agee: string, agem: string) => `${APIDIR}/api/catalogos/establecimientos-salud/${agee}/${agem}`;
export const getAgendaConfiguracionURL = (idMedico: string) => `${APIDIR}/api/agenda/configuracion/${idMedico}`;
export const getAgendaURL = (idMedico: string, mes: string, anio: string) => `${APIDIR}/api/agenda/agenda/${idMedico}/${mes}/${anio}`;
export const getDetectionLungCancerURL = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${APIDIR}/api/detection-cancer/lung-cancer?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const getDetectionLungCancerUsuarioURL = (idPaciente: number, idUsuario: number) => `${APIDIR}/api/detection-cancer/lung-cancer-paciente?idPaciente=${idPaciente}&idUsuario=${idUsuario}`;
// Componentes

// BUILDER

// MCPA
export const MCPAURLBuscar = (
  tipoConsulta: number,
  categoria: number,
  sexo: number,
  rangoEdad: number,
  idMedico: number,
  idOrganizacion: number,
) => `${APIDIR}/api/mcpa/buscar?tipoConsulta=${tipoConsulta}&categoria=${categoria}&sexo=${sexo}&rangoEdad=${rangoEdad}&idMedico=${idMedico}&idOrganizacion=${idOrganizacion}`;
export const MCPAURL = () => `${APIDIR}/api/mcpa`;
// Exploracion fisica
export const exploracionFisica = `${APIDIR}/api/exploracion-fisica/guardar`;
export const exploracionFisicaUsuarioPaciente = `${APIDIR}/api/exploracion-fisica/guardar-usuario`;
// terapia farmacologica
export const Farmacologico = `${APIDIR}/api/farmacologico`;
export const FarmacologicoGuardarMedicamento = () => `${Farmacologico}/medicamento`;
export const FarmacologicoGuardarReceta = () => `${Farmacologico}/receta`;
export const FarmacologicoMedicamentosActuales = (
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
) => `${Farmacologico}/medicamentos-actuales?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const FarmacologicoMedicamentosAnteriores = (idPaciente: number) => `${Farmacologico}/medicamentos-anteriores/${idPaciente}`;
export const FarmacologicoMedicamentosLista = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${Farmacologico}/medicamentos-lista?idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
export const FarmacologicoRecetaMedicamento = (
  idReceta: number,
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
) => `${Farmacologico}/receta/${idReceta}/${idMedico}/${idPaciente}/${idConsultorio}`;
export const FarmacologicoRecetasMedicamentos = (
  idConsulta: number,
  idPaciente: number,
  idMedico: number,
  idConsultorio: number,
) => `${Farmacologico}/recetas?idConsulta=${idConsulta}&idPaciente=${idPaciente}&idMedico=${idMedico}&idConsultorio=${idConsultorio}`;
// Perfil Médico
export const perfilMedicoConsultoriosURL = () => `${APIDIR}/api/perfil-medico/consultorios`;
export const perfilMedicoOrganizacionesURL = () => `${APIDIR}/api/perfil-medico/organizaciones`;
export const perfilMedicoSeleccionarConsultorioURL = () => `${APIDIR}/api/perfil-medico/seleccionar-consultorio`;
export const perfilMedicoObtenerConsultorios = (idMedico: number, idOrganizacion: number) => `${APIDIR}/api/perfil-medico/consultorios/${idMedico}/${idOrganizacion}`;
export const perfilMedicoConsultoriosSimplificados = (idMedico: number, idOrganizacion: number) => `${APIDIR}/api/perfil-medico/consultorios-simple/${idMedico}/${idOrganizacion}`;
export const perfilMedicoObtenerConsultorio = (idMedico: number, idConsultorio: number) => `${APIDIR}/api/perfil-medico/consultorio/${idMedico}/${idConsultorio}`;
export const perfilMedicoObtenerMe = (email: string) => `${APIDIR}/api/perfil-medico/me/${email}`;
export const perfilMedicoDatosIdentificacion = (email: string) => `${APIDIR}/api/perfil-medico/datos-identificacion/${email}`;
export const perfilMedicoDatosCarrera = (id: string) => `${APIDIR}/api/perfil-medico/datos-carrera/${id}`;
export const perfilMedicoDatosEspecialidad = (id: string) => `${APIDIR}/api/perfil-medico/datos-especialidad/${id}`;
export const perfilMedicoDatosMaestria = (id: string) => `${APIDIR}/api/perfil-medico/datos-maestria/${id}`;
export const perfilMedicoDatosDoctorado = (id: string) => `${APIDIR}/api/perfil-medico/datos-doctorado/${id}`;
export const perfilMedicoDatosExperiencia = (id: string) => `${APIDIR}/api/perfil-medico/datos-experiencia/${id}`;
export const perfilMedicoObtenerOrganizacion = (idMedico: number, idOrganizacion: number) => `${APIDIR}/api/perfil-medico/organizacion/${idMedico}/${idOrganizacion}`;
export const perfilMedicoOrganizacionesSimple = (idMedico: number) => `${APIDIR}/api/perfil-medico/organizaciones-simple/${idMedico}`;
// PAGOS
export const crearTokenNuevo = () => `${APIDIR}/api/payment-method/token`;
export const crearOrdenCompleta = () => `${APIDIR}/api/payment-method/orden-completa`;
export const crearOrdenSubscripcion = () => `${APIDIR}/api/payment-method/orden-subscription`;
export const buscarVerificarSubscripcion = (email: string, customer: string) => `${APIDIR}/api/payment-method/verificar-subscription?email=${email}&customer=${customer}`;
export const getInformacionCustomerURL = (customer: string) => `${APIDIR}/api/payment-method/informacion-customer?customer=${customer}`;
export const getInformacionSubscriptionURL = (customer: string) => `${APIDIR}/api/payment-method/informacion-subscripcion?customer=${customer}`;
export const getInformacionMetodosPagoURL = (customer: string) => `${APIDIR}/api/payment-method/informacion-metodos-pago?customer=${customer}`;
export const tarjetaConektaURL = () => `${APIDIR}/api/payment-method/tarjeta`;
export const cancelarSubscripcion = () => `${APIDIR}/api/payment-method/cancelar-subscription`;
// Business Doctor
export const businessDoctorObtenerDatosURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
  tipo: string,
  agee: string,
  agem: string,
  periodoMax: boolean,
) => `${APIDIR}/api/bi-doctor/obtener-datos?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}&start=${start}&end=${end}&tipo=${tipo}&agee=${agee}&agem=${agem}&periodoMax=${periodoMax}`;
export const businessDoctorPacientesEdadSexoURL = (
  idMedico: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
  tipo: string,
) => `${APIDIR}/api/bi-doctor/paciente-edad-sexo?idMedico=${idMedico}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}&start=${start}&end=${end}&tipo=${tipo}`;
export const businessDoctorPacientesDistribucionGeograficaURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
  agee: string,
  agem: string,
) => `${APIDIR}/api/bi-doctor/paciente-distribucion-geografica?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}&start=${start}&end=${end}&agee=${agee}&agem=${agem}`;
export const getXlsPacientesDiaURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
) => `${APIDIR}/api/bi-doctor/paciente-dia/xls?idMedico=${idMedico}&idConsultorio=${idConsultorio}&idOrganizacion=${idOrganizacion}&tipoPacientes=${tipoPacientes}&start=${start}&end=${end}`;
export const getXlsPacientesEdadSexoURL = (
  idMedico: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
  tipo: string,
) => `${APIDIR}/api/bi-doctor/paciente-edad-sexo/xls?idMedico=${idMedico}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}&start=${start}&end=${end}&tipo=${tipo}`;
export const getXlsPacientesDiagnosticosSexoURL = (
  idMedico: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
) => `${APIDIR}/api/bi-doctor/paciente-diagnostico-sexo/xls?idMedico=${idMedico}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}&start=${start}&end=${end}`;
export const getXlsPacientesDistribucionGeograficaURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
  agee: string,
  agem: string,
) => `${APIDIR}/api/bi-doctor/paciente-distribucion-geografica/xls?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}&start=${start}&end=${end}&agee=${agee}&agem=${agem}`;
export const businessDoctorReporteEpidemiologiaURL = (
  idMedico: number,
  idOrganizacion: number,
  idConsultorio: number,
  tipoPacientes: string,
  fecha: string,
  edad: string,
  sexo: string,
  // agees: string,
  // agems: string,
  diagnosticos: string,
  ocupaciones: string,
) => `${APIDIR}/api/bi-doctor/reporte-epidemiologia?idMedico=${idMedico}&idOrganizacion=${idOrganizacion}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}&fecha=${fecha}&edad=${edad}&sexo=${sexo}&diagnosticos=${diagnosticos}&ocupaciones=${ocupaciones}`;
export const getXlsReporteEpidemiologiaURL = (
  idMedico: number,
  idConsultorio: number,
  tipoPacientes: string,
  start: string,
  end: string,
) => `${APIDIR}/api/bi-doctor/reporte-epidemiologia/xls?idMedico=${idMedico}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}&start=${start}&end=${end}`;
export const operationsDoctorPacientesCumpleURL = (
  idMedico: number,
  idConsultorio: number,
  tipoPacientes: string,
) => `${APIDIR}/api/operations-doctor/pacientes-cumple?idMedico=${idMedico}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}`;
export const operationsDoctorObtenerDatosURL = (
  idMedico: number,
  idConsultorio: number,
  tipoPacientes: string,
) => `${APIDIR}/api/operations-doctor/obtener-datos?idMedico=${idMedico}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}`;
export const dictamenesObtenerDatosURL = (
  idMedico: number,
  idConsultorio: number,
  tipoPacientes: string,
) => `${APIDIR}/api/dictamenes-medicos-laborales/reporte-dictamenes?idMedico=${idMedico}&idConsultorio=${idConsultorio}&tipoPacientes=${tipoPacientes}`;
// TABLERO PACIENTE
export const getTableroPacienteEvaluacionMedidasURL = (
  idMedico: number,
  idPaciente: number,
  idUsuario: number,
  idConsultorio: number,
) => `${APIDIR}/api/tablero-paciente/evaluacion-medidas?idMedico=${idMedico}&idPaciente=${idPaciente}&idUsuario=${idUsuario}&idConsultorio=${idConsultorio}`;
export const getTableroPacienteEvaluacionMedidasUsuarioURL = (
  idPaciente: number,
  idUsuario: number,
) => `${APIDIR}/api/tablero-paciente/evaluacion-medidas-usuario?idPaciente=${idPaciente}&idUsuario=${idUsuario}`;
export const getColesterolPacienteURL = (idMedico: number, idPaciente: number, idConsultorio: number) => `${APIDIR}/api/tablero-paciente/colesterol?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const getColesterolUsuarioPacienteURL = (idPaciente: number) => `${APIDIR}/api/tablero-paciente/colesterol-usuario?idPaciente=${idPaciente}`;
export const getDiagnosticosTableroPacienteURL = (idMedico: number, idPaciente: number, idConsultorio: number) => `${APIDIR}/api/tablero-paciente/tabla-diagnosticos?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const getDiagnosticosTableroUsuarioPacienteURL = (idPaciente: number, idUsuario: number) => `${APIDIR}/api/tablero-paciente/tabla-diagnosticos-usuario?idPaciente=${idPaciente}&idUsuario=${idUsuario}`;
export const getMedicinaPreventivaTableroPacienteURL = (
  idMedico: number,
  idPaciente: number,
  idUsuario: number,
  idConsultorio: number,
) => `${APIDIR}/api/tablero-paciente/tabla-medicina-preventiva?idMedico=${idMedico}&idPaciente=${idPaciente}&idUsuario=${idUsuario}&idConsultorio=${idConsultorio}`;
export const getMedicinaPreventivaTableroUsuarioPacienteURL = (
  idPaciente: number,
  idUsuario: number,
) => `${APIDIR}/api/tablero-paciente/tabla-medicina-preventiva-usuario?idPaciente=${idPaciente}&idUsuario=${idUsuario}`;
export const getColesterolPacienteUsuarioURL = (idUsuario: number) => `${APIDIR}/api/tablero-paciente/colesterol-usuario?idUsuario=${idUsuario}`;
export const getDiagnosticosTableroPacienteUsuarioURL = (idUsuario: number) => `${APIDIR}/api/tablero-paciente/tabla-diagnosticos-usuario?idUsuario=${idUsuario}`;
export const getMedicinaPreventivaTableroPacienteUsuarioURL = (idUsuario: number) => `${APIDIR}/api/tablero-paciente/tabla-medicina-preventiva-usuario?idUsuario=${idUsuario}`;
export const getAntecedentesLaboralesURL = (idMedico: number, idPaciente: number, idConsultorio: number) => `${APIDIR}/api/tablero-paciente/tabla-antecedentes-laborales?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const getAntecedentesLaboralesUsuarioPacienteURL = (idPaciente: number) => `${APIDIR}/api/tablero-paciente/tabla-antecedentes-laborales-usuario?idPaciente=${idPaciente}`;
export const getTratamientosTableroPacienteURL = (idMedico: number, idPaciente: number, idConsultorio: number) => `${APIDIR}/api/tablero-paciente/tabla-tratamientos?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}`;
export const getTratamientosTableroUsuarioPacienteURL = (idPaciente: number) => `${APIDIR}/api/tablero-paciente/tabla-tratamientos-usuario?idPaciente=${idPaciente}`;
export const getAntecedenteLaboralEmpresaURL = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idEmpresa: number,
) => `${APIDIR}/api/tablero-paciente/antecedente-laboral?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}&idEmpresa=${idEmpresa}`;
export const getAntecedenteLaboralEmpresaUsuarioPacienteURL = (
  idPaciente: number,
  idEmpresa: number,
) => `${APIDIR}/api/tablero-paciente/antecedente-laboral-usuario?idPaciente=${idPaciente}&idEmpresa=${idEmpresa}`;
export const getTratamientoTableroPacienteURL = (
  idMedico: number,
  idPaciente: number,
  idConsultorio: number,
  idMedicamento: number,
) => `${APIDIR}/api/tablero-paciente/tratamiento?idMedico=${idMedico}&idPaciente=${idPaciente}&idConsultorio=${idConsultorio}&idMedicamento=${idMedicamento}`;
export const getTratamientoTableroUsuarioPacienteURL = (
  idPaciente: number,
  idMedicamento: number,
) => `${APIDIR}/api/tablero-paciente/tratamiento-usuario?idPaciente=${idPaciente}&idMedicamento=${idMedicamento}`;
export const reestablecerEmail = () => `${APIDIR}/api/reestablecer/email`;
export const reestablecerToken = () => `${APIDIR}/api/reestablecer/token`;
export const reestablecerPassword = () => `${APIDIR}/api/reestablecer/password`;
