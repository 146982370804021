import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { RootState } from '../../../store';
import { setRequest } from '../../../actions/request/types';
import { sendEliminarIncapacidades } from '../../../utils/sendInfo';
import DeleteDialog from '../../../components/DeleteDialog';
import FormularioIncapacidades from './FormularioIncapacidades';
import Incapacidad from './Incapacidad';
import { IDatosIncapacidad, IIncapacidadesProps } from './types';

dayjs.locale('es');

const Incapacidades = (props: IIncapacidadesProps) => {
  const {
    listaIncapacidades,
    setListaIncapacidades,
    accidentesLaborales,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const [idEliminar, setIdEliminar] = useState<number>(-1);
  const [idActualizar, setIdActualizar] = useState<number>(-1);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);

  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };

  const handleEditIncapacidad = (id: number) => {
    setIdActualizar(id);
    window.scrollTo({ top: 180, left: 0, behavior: 'smooth' });
  };

  const handleDeleteIncapacidad = () => {
    dispatch(setRequest({
      type: 'send',
      requestFunction: sendEliminarIncapacidades({
        idMedico,
        idPaciente,
        idConsultorio,
        idIncapacidad: idEliminar,
      }),
      successFunction: () => {
        setListaIncapacidades((incapacidades: IDatosIncapacidad[]) => incapacidades.filter((incapacidad: IDatosIncapacidad) => incapacidad.id !== idEliminar));
      },
    }));
  };

  return (
    <>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('_seguro_que_deseas_eliminar_el_registro_')}
        callBackAceptar={handleDeleteIncapacidad}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_el_registro_se_borrara_el_que_estas_creando')}
        callBackAceptar={handleEditIncapacidad}
        callBackClose={handleEditAlertClose}
      />
      <div className="px-2 pb-0 bg-white container mx-auto rounded-t-md border-solid border border-gray-100 shadow">
        <h1 className="text-blue-800 font-normal m-6 text-center">{t('incapacidades')}</h1>
        <div className="mt-4 p-4 bg-white container mx-auto shadow rounded-b-md border-solid border border-gray-100">
          <FormularioIncapacidades
            listaIncapacidades={listaIncapacidades}
            setListaIncapacidades={setListaIncapacidades}
            idActualizar={idActualizar}
            setIdActualizar={setIdActualizar}
            accidentesLaborales={accidentesLaborales}
          />
        </div>
        <div>
          <h2 className="text-blue-800 font-normal mt-6">{t('incapacidades_lista')}</h2>
          {listaIncapacidades.map((incapacidad: IDatosIncapacidad) => (
            <Incapacidad
              incapacidad={incapacidad}
              handleEditIncapacidad={handleEditIncapacidad}
              setDeleteAlert={setDeleteAlert}
              setEditAlert={setEditAlert}
              setIdActualizar={setIdActualizar}
              setIdEliminar={setIdEliminar}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Incapacidades;
