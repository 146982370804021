import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useSelector, useDispatch } from 'react-redux';
import {
  ICatalogoAGEE,
  ICatalogoAGEM,
} from '../TabsPaciente/PerfilPaciente/DatosDeUbicacion/types';
import { RootState } from '../../store';
import {
  getBusinessDoctorObtenerDatos,
  getCatalogoTipoOcupacion,
  getFethCatalogoAgee,
  getCatalogoAgem,
  getOperationsDoctorObtenerDatos,
  getDictamenesObtenerDatos,
} from '../../utils/getCatalogos';
import { setSnackComplete } from '../../actions/snackbar/types';
import {
  IBusinessDoctor,
  IObjPacientesDia,
  IObjPacientesEdadSexo,
  IObjPacientesDiagnosticoSexo,
  IObjPacientesAccidentes,
  IObjPacientesDistribucionGeografica,
  // IObjPacientesCumple,
  IObjGloboriskProps,
  IObjCancerPulmonProps,
  IObjCancerCervicoProps,
  IObjAudiometriasProps,
  IObjEMPProps,
  IObjAAAProps,
  IObjReporteEpidemiologia,
  IFiltrosReporteEpidemiologia,
  IObjTableProps,
  IObjGraphCardiovascularProps,
  IObjHabitoProps,
  IObjTableHabitosProps,
  IObjAreasOportunidadProps,
  IObjPacientesIncapacidades,
  IObjCampania,
  IObjPacientesDictamenes,
} from './types';
import { ICatalogosCie10, ICatalogosEstandar } from '../../common/types';
import useStyles from './styles';
import { setRequest } from '../../actions/request/types';
import Operaciones from './operaciones';
import Inteligencia from './inteligencia';
import Epidemiologia from './epidemiologia';
import Gestion from './gestion';
import Dictamenes from './Dictamenes';

dayjs.locale('es');

const BusinessDoctor = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    idMedico, rol, carrera, secciones, enOrganizacion,
  } = useSelector((state: RootState) => state.Me);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { consultorios, consultorio } = useSelector((state: RootState) => state.Consultorios);
  const { agee, idConsultorio: idConsMaster } = consultorio;
  const [idConsultorio, setIdConsultorio] = useState<number>(idConsMaster);
  const [tipoPacientes, setTipoPacientes] = useState<'interno' | 'externo'>('interno');
  const [verTablaDetalle, setVerTablaDetalle] = useState<boolean>(false);
  const [graphType, setGraphType] = useState<'' | 'table' | 'chart'>('chart');
  const [seleccionPagina, setSeleccionPagina] = useState<
  'operaciones' | 'inteligencia' | 'epidemiologia' | 'gestion' | 'dictamenes'
  >('operaciones');
  const [seleccionOperacion, setSeleccionOperacion] = useState<
  'medicina_preventiva' | 'riesgos_laborales' | 'habitos' | 'otros_riesgos' | 'emo' // | 'nom-035'
  >('medicina_preventiva');
  const [plantilla, setPlantilla] = useState<number>(0);
  const [tablaCardiovascular, setTablaCardiovascular] = useState<IObjTableProps[]>([]);
  const [tablaAAA, setTablaAAA] = useState<IObjTableProps[]>([]);
  const [tablaCancerPulmon, setTablaCancerPulmon] = useState<IObjTableProps[]>([]);
  const [tablaCancerCervical, setTablaCancerCervical] = useState<IObjTableProps[]>([]);
  const [tablaHabitosRiesgo, setTablaHabitosRiesgo] = useState<IObjTableHabitosProps[]>([]);
  const [tablaHacinamiento, setTablaHacinamiento] = useState<IObjTableProps[]>([]);
  const [tablaAudiometrias, setTablaAudiometrias] = useState<IObjTableProps[]>([]);
  const [tablaEMP, setTablaEMP] = useState<IObjTableProps[]>([]);
  const [graficoCardiovascular, setGraficoCardiovascular] = useState<
  IObjGraphCardiovascularProps[]
  >([]);
  const [tablaCardiovascularDetalle, setTablaCardiovascularDetalle] = useState<
  IObjGloboriskProps[]
  >([]);
  const [tablaAAADetalle, setTablaAAADetalle] = useState<IObjAAAProps[]>([]);
  const [tablaCancerPulmonDetalle, setTablaCancerPulmonDetalle] = useState<IObjCancerPulmonProps[]>(
    [],
  );
  const [tablaCancerCervicoDetalle, setTablaCancerCervicoDetalle] = useState<
  IObjCancerCervicoProps[]
  >([]);
  const [tablaAudiometriasDetalle, setTablaAudiometriasDetalle] = useState<IObjAudiometriasProps[]>(
    [],
  );
  const [tablaEMPDetalle, setTablaEMPDetalle] = useState<IObjEMPProps[]>([]);
  const [tableGloborisk, setTableGloborisk] = useState<any[]>([]);
  const [tablaTabaquismoDetalle, setTablaTabaquismoDetalle] = useState<IObjHabitoProps[]>([]);
  const [tablaCafeinaDetalle, setTablaCafeinaDetalle] = useState<IObjHabitoProps[]>([]);
  const [tablaAlcoholTrastornoDetalle, setTablaAlcoholTrastornoDetalle] = useState<
  IObjHabitoProps[]
  >([]);
  const [tablaAlcoholIntensivoDetalle, setTablaAlcoholIntensivoDetalle] = useState<
  IObjHabitoProps[]
  >([]);
  const [tablaOtrasDrogasDetalle, setTablaOtrasDrogasDetalle] = useState<IObjHabitoProps[]>([]);
  const [tablaHacinamientoDetalle, setTablaHacinamientoDetalle] = useState<IObjHabitoProps[]>([]);
  const [tablaActividadFisicaDetalle, setTablaActividadFisicaDetalle] = useState<IObjHabitoProps[]>(
    [],
  );
  const [tablaRefrescosDetalle, setTablaRefrescosDetalle] = useState<IObjHabitoProps[]>([]);
  // const [arrayCumple, setArrayCumple] = useState<IObjPacientesCumple[]>([]);
  const [fechaSeleccionada, setFechaSeleccionada] = useState<boolean>(false);
  const [fechaSeleccionadaMayor, setFechaSeleccionadaMayor] = useState<boolean>(false);
  const [filtroEdadSexo, setFiltroEdadSexo] = useState<string>('year');
  const [selectorTabla, setSelectorTabla] = useState<
  | 'riesgo_cardiovascular'
  | 'aaa'
  | 'cancer_pulmon'
  | 'cancer_cervical'
  | 'audiometria'
  | 'emp'
  | 'habitos'
  | 'otros_riesgos'
  // | 'nom_035_riesgo_general'
  // | 'nom_035_ambiente_trabajo'
  // | 'nom_035_factores_actividad'
  // | 'nom_035_tiempo_trabajo'
  // | 'nom_035_liderazgo_relaciones'
  // | 'nom_035_entorno_organizacional'
  >('riesgo_cardiovascular');
  const [datoPeticionGeneral, setDatoPeticionGeneral] = useState<IBusinessDoctor>({
    start: dayjs(new Date()).subtract(1, 'month'),
    end: new Date(),
  });
  const [datosPacienteDia, setDatosPacienteDia] = useState<IObjPacientesDia[]>([]);
  const [datosPacienteEdadSexo, setDatosPacienteEdadSexo] = useState<IObjPacientesEdadSexo[]>([]);
  const [datosPacienteDiagnosticoSexo, setDatosPacienteDiagnosticoSexo] = useState<
  IObjPacientesDiagnosticoSexo[]
  >([]);
  const [datosPacienteAccidentes, setDatosPacienteAccidentes] = useState<IObjPacientesAccidentes[]>([]);
  const [datosPacienteIncapacidades, setDatosPacienteIncapacidades] = useState<IObjPacientesIncapacidades[]>([]);
  const [datosPacienteDistribucionGeografica, setDatosPacienteDistribucionGeografica] = useState<
  IObjPacientesDistribucionGeografica[]
  >([]);
  const [catAGEE, setCatAGEE] = useState<ICatalogoAGEE[]>([]);
  const [catAGEM, setCatAGEM] = useState<ICatalogoAGEM[]>([]);
  const [selAGEE, setSelAGEE] = useState<ICatalogoAGEE | null>(null);
  const [selAGEM, setSelAGEM] = useState<ICatalogoAGEM | null>(null);
  const [tablaAreasOportunidad, setTablaAreasOportunidad] = useState<IObjAreasOportunidadProps[]>(
    [],
  );
  // const [catAgemRep, setCatAgemRep] = useState<ICatalogoAGEM[]>([]);
  const [catTipoOcupacionRep, setCatTipoOcupacionRep] = useState<Array<ICatalogosEstandar>>([]);
  const [catCIE10, setCatCIE10] = useState<ICatalogosCie10[]>([]);
  // const [openCIE10, setOpenCIE10] = useState<boolean>(false);
  const [loadingCIE10, setLoadingCIE10] = useState<boolean>(false);
  const [inputCIE10, setInputCIE10] = useState<string>('');
  const [datosReporteEpidemiologia, setDatosReporteEpidemiologia] = useState<
  IObjReporteEpidemiologia[]
  >([]);
  const [filtrosReporteEpidemiologia, setFiltrosReporteEpidemiologia] = useState<IFiltrosReporteEpidemiologia>({
    fecha: {
      start: dayjs(new Date()).subtract(1, 'month'),
      end: new Date(),
    },
    consultorio: idConsMaster,
    // agees: [],
    // agems: [],
    edad: [0, 60],
    sexo: 'ambos',
    diagnosticos: [],
    ocupaciones: [],
  });
  const [resumenFiltros, setResumenFiltros] = useState<string>('');
  const [seleccionarTodo, setSeleccionarTodo] = useState<boolean>(false);
  const [listaCampanias, setListaCampanias] = useState<IObjCampania[]>([]);
  const [datosPacienteDictamenes, setDatosPacienteDictamenes] = useState<IObjPacientesDictamenes[]>([]);

  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);

  const getPestanasWith = () => {
    if (window.innerWidth > 800) {
      if (window.innerWidth > 1500) {
        return 'w-3/5';
      }
      // if (window.innerWidth > 1100) {
      //   return 'w-4/5';
      // }
      return 'w-4/5';
    }
    return '';
  };
  const getObtenerDatosInteligencia = (periodoMax: boolean) => getBusinessDoctorObtenerDatos(
    idMedico,
    idOrganizacion,
    idConsMaster,
    tipoPacientes,
    dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
    dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
    filtroEdadSexo,
    agee?.cve_agee || '',
    '',
    periodoMax,
  );
  // cambios del reporte de epidemiología
  // const handleChangeAgeeReporte = (event: any, newValue: ICatalogoAGEE[] | []) => {
  //   setFiltrosReporteEpidemiologia({
  //     ...filtrosReporteEpidemiologia,
  //     agee: newValue,
  //   });
  //   setSelAGEM(null);
  //   if (newValue.length === 1) {
  //     getCatalogoAgem(newValue[0].cve_agee).then((result: ICatalogoAGEM[]) => {
  //       setCatAgemRep(result);
  //     });
  //   }
  // };
  // const handleChangeAgemReporte = (event: any, newValue: ICatalogoAGEM[] | []) => {
  //   setFiltrosReporteEpidemiologia({
  //     ...filtrosReporteEpidemiologia,
  //     agem: newValue,
  //   });
  // };

  // para llenar el catálogo de agee y agem
  useEffect(() => {
    if (agee) {
      const fetchCatAgee = getFethCatalogoAgee(idMedico);
      const fetchCatAgem = getCatalogoAgem(agee.cve_agee);
      Promise.all([fetchCatAgee, fetchCatAgem])
        .then((responses) => {
          setCatAGEE(responses[0]);
          const est = responses[0].find((obj: ICatalogoAGEE) => obj.cve_agee === agee.cve_agee);
          setSelAGEE(est);
          // setFiltrosReporteEpidemiologia({
          //   ...filtrosReporteEpidemiologia,
          //   agee: [est],
          // });
          setCatAGEM(responses[1]);
          // setCatAgemRep(responses[1]);
        })
        .catch((err) => {
          if (err.toString() !== 'AbortError: The user aborted a request.') {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
          }
        });
    }
  }, [agee]);
  const consultarDatos = () => {
    dispatch(
      setRequest({
        type: 'get',
        multiple: true,
        requestFunctions: [
          // getOperationsDoctorPacientesCumple(idMedico, idConsMaster, tipoPacientes),
          getOperationsDoctorObtenerDatos(idMedico, idConsMaster, tipoPacientes),
          getObtenerDatosInteligencia(false),
          getDictamenesObtenerDatos(idMedico, idConsMaster, tipoPacientes),
        ],
        successFunctions: [
          // (result: any) => setArrayCumple(result.datos),
          (result: any) => {
            if (result.code === 200) {
              setPlantilla(result.plantilla || 0);
              setTablaCardiovascular(result.datos.cardiovascular);
              setTablaAAA(result.datos.aaa);
              setTablaCancerPulmon(result.datos.cancerPulmon);
              setTablaCancerCervical(result.datos.cancerCervical);
              setTablaHacinamiento(result.datos.hacinamiento);
              setTablaAudiometrias(result.datos.audiometrias);
              setTablaEMP(result.datos.emp);
              setGraficoCardiovascular(result.datos.graficosCardiovascular);
              setTablaHabitosRiesgo(result.datos.habitosRiesgo.map((habito: IObjTableHabitosProps) => {
                const newHabito = { ...habito };
                newHabito.titulo = t(newHabito.tipo);
                newHabito.verDetalle = newHabito.tipo;
                switch (newHabito.tipo) {
                  case 'alarma_tabaquismo':
                    newHabito.comentarios = `${t('cigarrillos_promedio_al_dia')}: ${newHabito.comentarios}`;
                    break;
                  default:
                    break;
                }
                return newHabito;
              }));
              setTablaCardiovascularDetalle(result.datos.cardiovascularDetalle);
              setTablaCancerPulmonDetalle(result.datos.cancerPulmonDetalle);
              setTablaAAADetalle(result.datos.aneurismaAortaAbdominalDetalle);
              setTablaCancerCervicoDetalle(result.datos.cancerCervicoDetalle);
              setTablaAudiometriasDetalle(result.datos.audiometriasDetalle);
              setTablaEMPDetalle(result.datos.EMPDetalle);
              setTablaTabaquismoDetalle(result.datos.tabaquismoDetalle);
              setTablaCafeinaDetalle(result.datos.cafeinaDetalle);
              setTablaAlcoholTrastornoDetalle(result.datos.alcoholTrastornoDetalle);
              setTablaAlcoholIntensivoDetalle(result.datos.alcoholIntensivoDetalle);
              setTablaOtrasDrogasDetalle(result.datos.otrasDrogasDetalle);
              setTablaHacinamientoDetalle(result.datos.hacinamientoDetalle);
              setTablaActividadFisicaDetalle(result.datos.actividadFisicaDetalle);
              setTablaRefrescosDetalle(result.datos.refrescosDetalle);
              setTablaAreasOportunidad(result.datos.areasOportunidad.map((row: any) => ({ ...row, titulo: t(row.tipo) })));
            }
          },
          (result: any) => {
            setDatosPacienteDia(
              result.datos.pacienteDia.map((row: IObjPacientesDia) => ({
                ...row,
                fecha: dayjs(row.fecha).format('YYYY-MMM-DD'),
              })),
            );
            setDatosPacienteEdadSexo(result.datos.pacienteEdadSexo);
            setDatosPacienteDiagnosticoSexo(result.datos.diagnosticosSexo);
            setDatosPacienteAccidentes(result.datos.accidentes.map((accidente: any) => ({
              ...accidente,
              fecha: accidente.fecha.endsWith('00')
                ? dayjs(accidente.fecha).format('YYYY-MMM')
                : dayjs(accidente.fecha).format('YYYY-MMM-DD'),
              causaAccidente: accidente.causaAccidenteNombre || accidente.causaAccidenteLibre,
              naturalezaLesion: accidente.naturalezaLesionNombre || accidente.naturalezaLesionLibre,
              incapacidad: t(`incapacidades_tipo_${accidente.incapacidadNombre}`),
              area: 'Operaciones',
              departamento: '3',
              tipoIncidencia: t(accidente.tipoIncidenciaNombre),
            })));
            setDatosPacienteIncapacidades(result.datos.incapacidades);
            setDatosPacienteDistribucionGeografica(result.datos.distribucionGeografica);
          },
          (result: any) => {
            setDatosPacienteDictamenes(result.datos);
          },
        ],
      }),
    );
    setListaCampanias([
      {
        campaniaId: 1,
        campaniaNombre: 'Diabetes 1',
        correoAsunto: 'Control de diabetes',
        correoCuerpo: { entityMap: {}, blocks: [] },
        documento: 'control_diabetes.pdf',
      },
      {
        campaniaId: 2,
        campaniaNombre: 'Hipertensión',
        correoAsunto: 'Control de hipertensión',
        correoCuerpo: { entityMap: {}, blocks: [] },
        documento: 'control_hipertensión.pdf',
      },
    ]);
    setFechaSeleccionadaMayor(false);
    setFechaSeleccionada(true);
  };
  useEffect(() => {
    if (idConsMaster > 0) {
      consultarDatos();
      setIdConsultorio(idConsMaster);
    }
  }, [idConsMaster, tipoPacientes]);
  useEffect(() => {
    getCatalogoTipoOcupacion().then((result: ICatalogosEstandar[]) => {
      setCatTipoOcupacionRep(result);
    });
  }, []);
  useEffect(() => {
    if (seleccionPagina === 'operaciones') {
      setSeleccionOperacion('medicina_preventiva');
      setSelectorTabla('riesgo_cardiovascular');
      setGraphType('chart');
      setVerTablaDetalle(true);
    }
  }, [seleccionPagina]);

  const paginas: {
    tipo: 'operaciones' | 'inteligencia' | 'epidemiologia' | 'gestion' | 'dictamenes';
    titulo: string;
    seccion: number;
  }[] = [
    { tipo: 'operaciones', titulo: 'business_operaciones', seccion: 72 },
    { tipo: 'inteligencia', titulo: 'business_inteligencia_negocio', seccion: 70 },
    { tipo: 'epidemiologia', titulo: 'business_reportes_mis_reportes', seccion: 75 },
    { tipo: 'gestion', titulo: 'business_gestion', seccion: 78 },
    { tipo: 'dictamenes', titulo: 'dictamenes', seccion: 79 },
  ];

  return (
    <div className="p-4 bg-white w-full">
      {rol.grupo === 'administrativo'
      || (carrera.cedula
      && carrera.escuela
      && carrera.titulo
      && consultorios.length
      && consultorio.idConsultorio > 0
      && consultorio.nombre) ? (
        <>
          {/* <div className="text-center p-4 bg-blue-300">
            <h1 className="text-blue-800 font-normal m-0 text-center">
              {`${t('bienvenido')}, ${nombre || ''} ${primerApellido || ''}`}
            </h1>
          </div> */}
          {enOrganizacion && (
            <div className="float-right p-0">
              <div className="grid grid-cols-5 items-center">
                <div className="col-span-2">
                  <h4 className="text-gray-500 font-normal m-0">{`${t('business_tipo_pacientes')}:`}</h4>
                </div>
                <div className="col-span-3">
                  <FormControl variant="outlined" fullWidth>
                    <RadioGroup
                      row
                      aria-label="filtroTipoPacientes"
                      name="filtroTipoPacientes"
                      value={tipoPacientes}
                      onChange={(event) => setTipoPacientes(event.target.value as 'interno' | 'externo')}
                    >
                      <FormControlLabel
                        value="interno"
                        control={<Radio color="primary" />}
                        label={t('business_pacientes_internos')}
                      />
                      <FormControlLabel
                        value="externo"
                        control={<Radio color="primary" />}
                        label={t('business_pacientes_externos')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          )}
          <div
            className={`${
              window.innerWidth > 800 ? 'flex' : ''
            } justify-between items-end w-full`}
          >
            {/* esta es la parte de las pestañas */}
            <div className={`${getPestanasWith()} flex flex-row items-start`}>
              {paginas.map((pagina) => (secciones.length > 0
                && secciones.find((valor) => valor.idSeccion === pagina.seccion) ? (
                  <Button
                    fullWidth
                    className={
                      seleccionPagina === pagina.tipo
                        ? classes.buttonSelected
                        : classes.buttonRoot
                    }
                    onClick={() => setSeleccionPagina(pagina.tipo)}
                  >
                    {t(pagina.titulo)}
                  </Button>
                ) : null))}
            </div>
          </div>
          <hr className="m-0" />
          <div className="py-2" />
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 72)
            && seleccionPagina === 'operaciones' && (
              <Operaciones
                seleccionOperacion={seleccionOperacion}
                setSeleccionOperacion={setSeleccionOperacion}
                selectorTabla={selectorTabla}
                setSelectorTabla={setSelectorTabla}
                graphType={graphType}
                setGraphType={setGraphType}
                plantilla={plantilla}
                verTablaDetalle={verTablaDetalle}
                setVerTablaDetalle={setVerTablaDetalle}
                tablaCardiovascular={tablaCardiovascular}
                tablaAAA={tablaAAA}
                tablaCancerPulmon={tablaCancerPulmon}
                tablaCancerCervical={tablaCancerCervical}
                tablaHabitosRiesgo={tablaHabitosRiesgo}
                tablaHacinamiento={tablaHacinamiento}
                tablaAudiometrias={tablaAudiometrias}
                tablaEMP={tablaEMP}
                graficoCardiovascular={graficoCardiovascular}
                tablaCardiovascularDetalle={tablaCardiovascularDetalle}
                tablaAAADetalle={tablaAAADetalle}
                tablaCancerPulmonDetalle={tablaCancerPulmonDetalle}
                tablaCancerCervicoDetalle={tablaCancerCervicoDetalle}
                tablaAudiometriasDetalle={tablaAudiometriasDetalle}
                tablaEMPDetalle={tablaEMPDetalle}
                tableGloborisk={tableGloborisk}
                setTableGloborisk={setTableGloborisk}
                tablaTabaquismoDetalle={tablaTabaquismoDetalle}
                tablaCafeinaDetalle={tablaCafeinaDetalle}
                tablaAlcoholTrastornoDetalle={tablaAlcoholTrastornoDetalle}
                tablaAlcoholIntensivoDetalle={tablaAlcoholIntensivoDetalle}
                tablaOtrasDrogasDetalle={tablaOtrasDrogasDetalle}
                tablaHacinamientoDetalle={tablaHacinamientoDetalle}
                tablaActividadFisicaDetalle={tablaActividadFisicaDetalle}
                tablaRefrescosDetalle={tablaRefrescosDetalle}
                // arrayCumple={arrayCumple}
                // setArrayCumple={setArrayCumple}
              />
          )}
          {/* esta es la parte de inteligencia de negocio */}
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 70)
            && seleccionPagina === 'inteligencia' && (
              <Inteligencia
                idConsultorio={idConsultorio}
                setIdConsultorio={setIdConsultorio}
                tipoPacientes={tipoPacientes}
                fechaSeleccionada={fechaSeleccionada}
                setFechaSeleccionada={setFechaSeleccionada}
                fechaSeleccionadaMayor={fechaSeleccionadaMayor}
                setFechaSeleccionadaMayor={setFechaSeleccionadaMayor}
                filtroEdadSexo={filtroEdadSexo}
                setFiltroEdadSexo={setFiltroEdadSexo}
                datoPeticionGeneral={datoPeticionGeneral}
                setDatoPeticionGeneral={setDatoPeticionGeneral}
                datosPacienteDia={datosPacienteDia}
                setDatosPacienteDia={setDatosPacienteDia}
                datosPacienteEdadSexo={datosPacienteEdadSexo}
                setDatosPacienteEdadSexo={setDatosPacienteEdadSexo}
                datosPacienteDiagnosticoSexo={datosPacienteDiagnosticoSexo}
                setDatosPacienteDiagnosticoSexo={setDatosPacienteDiagnosticoSexo}
                datosPacienteAccidentes={datosPacienteAccidentes}
                setDatosPacienteAccidentes={setDatosPacienteAccidentes}
                datosPacienteIncapacidades={datosPacienteIncapacidades}
                setDatosPacienteIncapacidades={setDatosPacienteIncapacidades}
                datosPacienteDistribucionGeografica={datosPacienteDistribucionGeografica}
                setDatosPacienteDistribucionGeografica={setDatosPacienteDistribucionGeografica}
                catAGEE={catAGEE}
                catAGEM={catAGEM}
                setCatAGEM={setCatAGEM}
                selAGEE={selAGEE}
                setSelAGEE={setSelAGEE}
                selAGEM={selAGEM}
                setSelAGEM={setSelAGEM}
                tablaAreasOportunidad={tablaAreasOportunidad}
                getObtenerDatosInteligencia={getObtenerDatosInteligencia}
              />
          )}
          {/* esta es la parte del reporte */}
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 75)
            && seleccionPagina === 'epidemiologia' && (
              <Epidemiologia
                tipoPacientes={tipoPacientes}
                datoPeticionGeneral={datoPeticionGeneral}
                // catAgemRep={catAgemRep}
                // setCatAgemRep={setCatAgemRep}
                catTipoOcupacionRep={catTipoOcupacionRep}
                catCIE10={catCIE10}
                setCatCIE10={setCatCIE10}
                // openCIE10={openCIE10}
                // setOpenCIE10={setOpenCIE10}
                loadingCIE10={loadingCIE10}
                setLoadingCIE10={setLoadingCIE10}
                inputCIE10={inputCIE10}
                setInputCIE10={setInputCIE10}
                datosReporteEpidemiologia={datosReporteEpidemiologia}
                setDatosReporteEpidemiologia={setDatosReporteEpidemiologia}
                filtrosReporteEpidemiologia={filtrosReporteEpidemiologia}
                setFiltrosReporteEpidemiologia={setFiltrosReporteEpidemiologia}
                resumenFiltros={resumenFiltros}
                setResumenFiltros={setResumenFiltros}
                seleccionarTodo={seleccionarTodo}
                setSeleccionarTodo={setSeleccionarTodo}
                listaCampanias={listaCampanias}
              />
          )}
          {/* esta es la parte de gestion */}
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 78)
            && seleccionPagina === 'gestion' && (
              <Gestion
                tipoPacientes={tipoPacientes}
                listaCampanias={listaCampanias}
                setListaCampanias={setListaCampanias}
              />
          )}
          {secciones.length > 0
            && secciones.find((valor) => valor.idSeccion === 79)
            && seleccionPagina === 'dictamenes' && (
              <Dictamenes
                datosPacienteDictamenes={datosPacienteDictamenes}
              />
          )}
        </>
        ) : (
          <>
            <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
              <div className="border-0 p-4 pr-5 w-full">
                <h3 className="m-0 text-center text-blue-500 font-normal">{t('bienvenido')}</h3>
                <div className="text-center my-4">
                  <WarningIcon className="text-red-500" color="inherit" fontSize="large" />
                </div>
                <p className="text-gray-500 text-center">
                  {t(
                    'si_no_llenas_al_menos_tus_datos_de_identificacion_no_podras_acceder_a_las_otras_partes_del_expediente',
                  )}
                </p>
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 1) && (
                <div className="flex justify-center w-full">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push('/perfil-medico');
                    }}
                  >
                    {`${t('ir_a_perfil_medico')}`}
                  </Button>
                </div>
                )}
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default BusinessDoctor;
